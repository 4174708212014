import React from 'react';
import './FileInput.css';

interface FileInputProps {
  value: File | null | undefined;
  type?: 'primary' | 'secondary' | 'white' | 'default';
  name: string;
  label: string;
  accept?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function FileInput({
  value,
  name,
  label,
  accept,
  type,
  onChange,
}: FileInputProps): JSX.Element {
  return (
    <label className="file-input" htmlFor={name}>
      {value ? (
        <span className="file-input-btn btn btn-default">
          <i
            className="fas fa-upload"
            style={{ marginRight: value.name ? '10px' : '' }}
          />{' '}
          {value.name}
        </span>
      ) : (
        <span className={`file-input-btn btn btn-${type}`}>
          <i
            className="fas fa-upload"
            style={{ marginRight: label ? '10px' : '' }}
          />
          {label}
        </span>
      )}
      <input
        type="file"
        id={name}
        name={name}
        accept={accept}
        onChange={onChange}
      />
    </label>
  );
}

FileInput.defaultProps = {
  type: 'default',
  accept: '.*',
};

export default FileInput;
