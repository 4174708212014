import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import './RequestsSidebar.css';

import { Box } from '@mui/material';
import {
  TabPanel,
  Tab,
  Tabs,
  BaseButton,
  BaseModal,
  ScrollTrigger,
  BottomSheet,
} from 'components';

import { getUserId } from 'services/auth';
import RequestItem, { MapRequest } from './RequestItem';

interface RequestsSidebarProps {
  className?: string;
  farmId: number;
  areaId: number;
  requests: {
    desiccation: MapRequest[];
    soil_sampling?: MapRequest[];
    selective_spraying?: MapRequest[];
  };
  tabsProps?: {
    isLoading: boolean;
    lastPage: boolean;
  }[];
  type?: 'desktop' | 'mobile';
  onSelectRequest?: (
    request_id: number,
    requestType: 'desiccation' | 'soil_sampling' | 'selective_spraying',
  ) => void;
  onDeleteRequest?: (
    request_id: number,
    requestType: 'desiccation' | 'soil_sampling' | 'selective_spraying',
    callback: () => void,
  ) => void;
  onChangeTab?: (currentTab: 0 | 1 | 2) => void;
  onPaginate?: (
    type: 'desiccation' | 'soil_sampling' | 'selective_spraying',
  ) => void;
}

function RequestsSidebar({
  className,
  farmId,
  areaId,
  requests,
  tabsProps,
  type,
  onSelectRequest,
  onDeleteRequest,
  onChangeTab,
  onPaginate,
}: RequestsSidebarProps): JSX.Element {
  const navigate = useNavigate();
  const userId = getUserId();

  const {
    desiccation: desiccationRequests,
    soil_sampling: soilSamplingRequests,
    selective_spraying: selectiveSprayingRequests,
  } = requests;

  const tabRoutes = {
    0: 'desiccation-requests',
    1: 'soil-sampling-requests',
    2: 'selective-spraying-requests',
  };

  const tabTitles = ['DP', 'AP', 'PS'];
  const tabSubtitles = [
    'Dessecação pré-colheita',
    'Agricultura preditiva',
    'Pulverização seletiva',
  ];

  const [tabsPagination] = useState({
    desiccation: {
      tabBodyRef: useRef<HTMLTableSectionElement>(null),
    },
    soil_sampling: {
      tabBodyRef: useRef<HTMLTableSectionElement>(null),
    },
    selective_spraying: {
      tabBodyRef: useRef<HTMLTableSectionElement>(null),
    },
  });

  const [bsOpen, setBsOpen] = useState(true);
  const [currentTab, setCurrentTab] = useState<0 | 1 | 2>(0);
  const [deleteRequestModal, setDeleteRequestModal] = useState<{
    request_id: number;
    type: 'desiccation' | 'soil_sampling' | 'selective_spraying';
    open: boolean;
  }>({
    request_id: 0,
    type: 'desiccation',
    open: false,
  });

  const handleCloseDeleteRequestModal = (): void => {
    setDeleteRequestModal({ request_id: 0, open: false, type: 'desiccation' });
  };

  const userAuth = (): boolean => {
    if (tabRoutes[currentTab] === 'desiccation-requests') return true;
    if (userId === 3 || userId === 2) return true;

    return false;
  };

  const getSidebarContent = (): React.ReactNode => {
    return (
      <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <Tabs
            value={currentTab}
            onChange={(event, newValue) => {
              setCurrentTab(newValue);
              if (onChangeTab) onChangeTab(newValue);
            }}
            variant="fullWidth"
          >
            <Tab label="DP" id="simple-tab-1" />
            {soilSamplingRequests && <Tab label="AP" id="simple-tab-2" />}
            {selectiveSprayingRequests && <Tab label="PS" id="simple-tab-3" />}
          </Tabs>
        </Box>
        <div className="tab-subtitle text-muted">
          {tabSubtitles[currentTab]}
        </div>
        <div className="req-dialog-container-body p-0">
          <ScrollTrigger
            forwardedRef={tabsPagination.desiccation.tabBodyRef}
            onScrollEnd={() => {
              if (onPaginate) onPaginate('desiccation');
            }}
          >
            <TabPanel
              forwardedRef={tabsPagination.desiccation.tabBodyRef}
              className="w-100 p-4"
              value={currentTab}
              index={0}
            >
              {desiccationRequests.length ? (
                desiccationRequests.map((_request, index) => (
                  <div
                    className="w-100 h-auto mb-4"
                    key={`request-item_${_request.id}`}
                  >
                    <RequestItem
                      index={index + 1}
                      key={_request.id}
                      requestData={_request}
                      onClick={() => {
                        if (onSelectRequest) {
                          onSelectRequest(_request.id, 'desiccation');
                          setBsOpen(false);
                        }
                      }}
                      onDelete={() => {
                        setDeleteRequestModal({
                          request_id: _request.id,
                          type: 'desiccation',
                          open: true,
                        });
                        setBsOpen(false);
                      }}
                    />
                  </div>
                ))
              ) : (
                <div className="d-flex w-100 h-auto text-center flex-column">
                  <span className="no-requests-text text-muted">
                    Nenhuma solicitação feita.
                  </span>
                  <span className="no-requests-text text-muted">
                    Solicite um novo <b>Mapa de dessecação</b> clicando em{' '}
                    <i className="fas fa-plus" />
                  </span>
                </div>
              )}
              {tabsProps?.[0].isLoading && (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando...</span>
                  </div>
                </div>
              )}
              {!tabsProps?.[0].isLoading && !tabsProps?.[0].lastPage && (
                <div className="d-flex justify-content-center">
                  <button
                    aria-label="Carregar mais solicitações"
                    type="button"
                    className="btn btn-link"
                    style={{
                      textDecoration: 'none',
                      fontWeight: 'bold',
                    }}
                    onClick={() => {
                      if (onPaginate) onPaginate('desiccation');
                    }}
                  >
                    Carregar mais...
                  </button>
                </div>
              )}
            </TabPanel>
          </ScrollTrigger>
          {soilSamplingRequests && (
            <ScrollTrigger
              forwardedRef={tabsPagination.soil_sampling.tabBodyRef}
              onScrollEnd={() => {
                if (onPaginate) onPaginate('soil_sampling');
              }}
            >
              <TabPanel
                forwardedRef={tabsPagination.soil_sampling.tabBodyRef}
                className="w-100 p-4"
                value={currentTab}
                index={1}
              >
                {soilSamplingRequests.length ? (
                  soilSamplingRequests.map((_request, index) => (
                    <div
                      className="w-100 h-auto mb-4"
                      key={`request-item_${_request.id}`}
                    >
                      <RequestItem
                        index={index + 1}
                        key={_request.id}
                        requestData={_request}
                        onClick={() => {
                          if (onSelectRequest) {
                            onSelectRequest(_request.id, 'soil_sampling');
                            setBsOpen(false);
                          }
                        }}
                        onDelete={() => {
                          setDeleteRequestModal({
                            request_id: _request.id,
                            type: 'soil_sampling',
                            open: true,
                          });
                          setBsOpen(false);
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <div className="d-flex w-100 h-auto text-center flex-column">
                    <span className="no-requests-text text-muted">
                      Nenhuma solicitação feita.
                    </span>
                    <span className="no-requests-text text-muted">
                      Solicite um novo <b>Mapa de amostragem de solo</b>{' '}
                      clicando em <i className="fas fa-plus" />
                    </span>
                  </div>
                )}
                {tabsProps?.[1].isLoading && (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Carregando...</span>
                    </div>
                  </div>
                )}
                {!tabsProps?.[1].isLoading && !tabsProps?.[1].lastPage && (
                  <div className="d-flex justify-content-center">
                    <button
                      aria-label="Carregar mais solicitações"
                      type="button"
                      className="btn btn-link"
                      style={{
                        textDecoration: 'none',
                        fontWeight: 'bold',
                      }}
                      onClick={() => {
                        if (onPaginate) onPaginate('soil_sampling');
                      }}
                    >
                      Carregar mais...
                    </button>
                  </div>
                )}
              </TabPanel>
            </ScrollTrigger>
          )}
          {selectiveSprayingRequests && (
            <ScrollTrigger
              forwardedRef={tabsPagination.selective_spraying.tabBodyRef}
              onScrollEnd={() => {
                if (onPaginate) onPaginate('selective_spraying');
              }}
            >
              <TabPanel
                forwardedRef={tabsPagination.selective_spraying.tabBodyRef}
                className="w-100 p-4"
                value={currentTab}
                index={2}
              >
                {selectiveSprayingRequests.length ? (
                  selectiveSprayingRequests.map((_request, index) => (
                    <div
                      className="w-100 h-auto mb-4"
                      key={`request-item_${_request.id}`}
                    >
                      <RequestItem
                        index={index + 1}
                        key={_request.id}
                        requestData={_request}
                        onClick={() => {
                          if (onSelectRequest) {
                            onSelectRequest(_request.id, 'selective_spraying');
                            setBsOpen(false);
                          }
                        }}
                        onDelete={() => {
                          setDeleteRequestModal({
                            request_id: _request.id,
                            type: 'selective_spraying',
                            open: true,
                          });
                          setBsOpen(false);
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <div className="d-flex w-100 h-auto text-center flex-column">
                    <span className="no-requests-text text-muted">
                      Nenhuma solicitação feita.
                    </span>
                    <span className="no-requests-text text-muted">
                      Solicite um novo <b>Mapa de pulverização seletiva</b>{' '}
                      clicando em <i className="fas fa-plus" />
                    </span>
                  </div>
                )}
                {tabsProps?.[2].isLoading && (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Carregando...</span>
                    </div>
                  </div>
                )}
                {!tabsProps?.[2].isLoading && !tabsProps?.[2].lastPage && (
                  <div className="d-flex justify-content-center">
                    <button
                      aria-label="Carregar mais solicitações"
                      type="button"
                      className="btn btn-link"
                      style={{
                        textDecoration: 'none',
                        fontWeight: 'bold',
                      }}
                      onClick={() => {
                        if (onPaginate) onPaginate('selective_spraying');
                      }}
                    >
                      Carregar mais...
                    </button>
                  </div>
                )}
              </TabPanel>
            </ScrollTrigger>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="container-fluid sidebar-wrapper p-0">
      {type === 'desktop' ? (
        <div className="dialog-container">
          {getSidebarContent()}
          {userAuth() ? (
            <div
              id="new-request-button"
              aria-label="Nova solicitação de mapa"
              className="dialog-container-footer"
              role="button"
              tabIndex={0}
              onKeyUp={() => {}}
              onClick={() => {
                navigate(
                  `/customer/farms/${farmId}/areas/${areaId}/${tabRoutes[currentTab]}/new`,
                );
              }}
            >
              <i className="fas fa-plus" />
            </div>
          ) : (
            <>
              <div
                id="new-request-button"
                aria-label="Nova solicitação de mapa"
                className="dialog-container-footer"
                role="button"
                tabIndex={0}
                onKeyUp={() => {}}
                onClick={() => {}}
              >
                <i className="fas fa-plus" />
              </div>
              <Tooltip anchorSelect="#new-request-button" place="top">
                Disponível em breve
              </Tooltip>
            </>
          )}
        </div>
      ) : (
        <BottomSheet
          className={className}
          title="Solicitações"
          open={bsOpen}
          initialState="middle-opened"
          controlBtn={
            <BaseButton
              size="xs"
              disabled={!userAuth()}
              onClick={() =>
                navigate(
                  `/customer/farms/${farmId}/areas/${areaId}/${tabRoutes[currentTab]}/new`,
                )
              }
            >
              <i className="fas fa-plus" /> Nova {tabTitles[currentTab]}
            </BaseButton>
          }
          onChange={open => setBsOpen(open)}
        >
          {getSidebarContent()}
        </BottomSheet>
      )}
      <BaseModal
        size="sm"
        open={deleteRequestModal.open}
        onClose={handleCloseDeleteRequestModal}
      >
        <div className="modal-body p-3">
          <div className="row">
            <div className="d-flex col-12 text-center flex-column">
              <span className="text-danger">
                <i className="fas fa-trash fa-4x" />
              </span>
              <b className="text-danger mt-2 mb-4">ATENÇÃO!</b>
            </div>
            <div className="col-12 text-center">
              <span className="text-muted">
                Deseja realmente excluir este mapa? Esta ação não poderá ser
                desfeita.
              </span>
            </div>
          </div>
        </div>
        <div
          className="modal-footer w-100"
          style={{ position: 'absolute', bottom: 0 }}
        >
          <div className="row">
            <div className="d-flex col-12 justify-content-end align-items-center">
              <span
                className="exit-modal-btn"
                tabIndex={0}
                role="button"
                onKeyUp={() => {}}
                onClick={handleCloseDeleteRequestModal}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </span>
              <BaseButton
                onClick={() => {
                  if (onDeleteRequest)
                    onDeleteRequest(
                      deleteRequestModal.request_id,
                      deleteRequestModal.type,
                      handleCloseDeleteRequestModal,
                    );
                }}
              >
                Exluir Mapa
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>
    </div>
  );
}

RequestsSidebar.defaultProps = {
  type: 'desktop',
  className: '',
  tabsProps: [
    {
      isLoading: false,
      lastPage: 1,
    },
    {
      isLoading: false,
      lastPage: 1,
    },
    {
      isLoading: false,
      lastPage: 1,
    },
  ],
  onSelectRequest: () => {},
  onDeleteRequest: () => {},
  onChangeTab: () => {},
  onPaginate: () => {},
};

export default RequestsSidebar;
