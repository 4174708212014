import React, { useEffect } from 'react';
import { handleEndScroll } from 'helpers/refHelper';

interface ScrollTriggerProps {
  onScrollEnd?: () => void;
  forwardedRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}

function ScrollTrigger({
  onScrollEnd,
  forwardedRef,
  children,
}: ScrollTriggerProps): JSX.Element {
  useEffect(() => {
    const eventHandler = (): void => {
      handleEndScroll(forwardedRef, () => {
        if (onScrollEnd) {
          onScrollEnd();
        }
      });
    };
    forwardedRef.current?.addEventListener('scroll', eventHandler);

    return () => {
      forwardedRef.current?.removeEventListener('scroll', eventHandler);
    };
  }, [forwardedRef]);

  return <>{React.Children.only(children)}</>;
}

ScrollTrigger.defaultProps = {
  onScrollEnd: () => {},
};

export default ScrollTrigger;
