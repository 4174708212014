import React from 'react';
import './Teste.css';

import { BottomSheet, InteractiveMap } from 'components';

function Teste(): JSX.Element {
  const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div className="container-fluid h-100 p-0">
      <div className="teste-map">
        <InteractiveMap zoom={17} showMarker />
      </div>
      <BottomSheet>
        <div className="teste-content">
          {numbers.map(number => (
            <div key={number} className="teste-item">
              Fazenda {number}
            </div>
          ))}
        </div>
      </BottomSheet>
    </div>
  );
}

export default Teste;
