import React, { useEffect } from 'react';
import './InteractiveMap.css';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L, { LatLngExpression, LeafletMouseEvent } from 'leaflet';

// eslint-disable-next-line import/no-unresolved
import { FeatureCollection, GeoJsonObject } from 'geojson';

const {
  REACT_APP_MAPBOX_USERNAME,
  REACT_APP_MAPBOX_STYLE_ID,
  REACT_APP_MAPBOX_ACCESS_TOKEN,
} = process.env;

interface MapEventsProps {
  center: [number, number];
  zoom: number;
}

function MapEvents({ center, zoom }: MapEventsProps): JSX.Element | null {
  const map = useMap();

  useEffect(() => {
    if (center) {
      map.setView(center, zoom);
    }
  }, [center, map, zoom]);

  return null;
}

interface MapClickEventsProps {
  onClick: (e: LeafletMouseEvent) => void;
}

interface GeoJSONLayerProps {
  geoJson: FeatureCollection;
  uniqueKey?: string;
}

interface InteractiveMapProps {
  initialPosition?: [number, number];
  center?: [number, number];
  zoom?: number;
  scrollWheelZoom?: boolean;
  markerPosition?: LatLngExpression;
  popupMessage?: string;
  geoJson?: GeoJsonObject | null;
  showMarker?: boolean;
  onClick?: (e: LeafletMouseEvent) => void;
}

function MapClickEvents({ onClick }: MapClickEventsProps): JSX.Element | null {
  const map = useMap();

  useEffect(() => {
    map.on('click', e => {
      onClick(e);
    });
  }, [map, onClick]);

  return null;
}
function GeoJSONLayer({
  geoJson,
  uniqueKey,
}: GeoJSONLayerProps): JSX.Element | null {
  const map = useMap();

  React.useEffect(() => {
    const geoJsonLayer = L.geoJSON(geoJson as FeatureCollection, {
      style: feature => {
        if (feature) {
          return {
            fillColor: feature.properties?.fill || '#3388FF',
            fillOpacity: feature.properties['fill-opacity'] || 0.3,
            color: feature.properties?.stroke || '#3388FF',
            opacity: feature.properties['stroke-opacity'] || 0.8,
          };
        }

        return { color: 'defaultColor' };
      },
      pointToLayer: (feature, latlng) => {
        const marker = L.marker(latlng);
        if (feature.properties && feature.properties.name) {
          marker.bindTooltip(feature.properties.name, {
            permanent: true,
            direction: 'center',
            className: 'marker-label',
          });
        }
        return marker;
      },
    }).addTo(map);

    return () => {
      map.removeLayer(geoJsonLayer);
    };
  }, [geoJson, uniqueKey, map]);

  return null;
}

function InteractiveMap({
  initialPosition,
  center,
  zoom,
  scrollWheelZoom,
  showMarker,
  markerPosition,
  popupMessage,
  geoJson,
  onClick,
}: InteractiveMapProps): JSX.Element {
  return (
    <div className="interactive-map">
      <MapContainer
        center={initialPosition}
        zoom={zoom}
        scrollWheelZoom={scrollWheelZoom}
      >
        <TileLayer
          attribution="Mapbox Satellite"
          url={`https://api.mapbox.com/styles/v1/${REACT_APP_MAPBOX_USERNAME}/${REACT_APP_MAPBOX_STYLE_ID}/tiles/256/{z}/{x}/{y}/?access_token=${REACT_APP_MAPBOX_ACCESS_TOKEN}`}
        />
        {showMarker && markerPosition && (
          <Marker position={markerPosition}>
            {popupMessage && <Popup>{popupMessage}</Popup>}
          </Marker>
        )}
        {center && <MapEvents center={center} zoom={zoom || 13} />}
        {geoJson && (
          <GeoJSONLayer
            geoJson={geoJson as FeatureCollection}
            key={JSON.stringify(geoJson)}
          />
        )}
        <MapClickEvents
          onClick={e => {
            if (onClick) onClick(e);
          }}
        />
      </MapContainer>
    </div>
  );
}

GeoJSONLayer.defaultProps = {
  uniqueKey: '',
};

InteractiveMap.defaultProps = {
  initialPosition: [-29.711025188644363, -53.71634781360627],
  center: [-29.711025188644363, -53.71634781360627],
  zoom: 13,
  scrollWheelZoom: true,
  markerPosition: null,
  popupMessage: '',
  showMarker: false,
  geoJson: null,
  onClick: () => {},
};

export default InteractiveMap;
