import React from 'react';
import './AreaItem.css';
import { BaseButton } from 'components';
import { Tooltip } from 'react-tooltip';

export interface Area {
  id: number;
  name: string;
  file_id: number;
  farm_id: number;
  total_area_m2: number;
  center_coordinates: string;
  geojson: string;
}

interface AreaItemProps {
  areaData: Area;
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
  onClick: () => void;
  onIconClick?: () => void;
}

function AreaItem({
  areaData,
  onEdit,
  onDelete,
  onClick,
  onIconClick,
}: AreaItemProps): JSX.Element {
  const formatAreaToHectare = (area: number): string => {
    return `${(area / 10000).toFixed(2)} ha`;
  };

  return (
    <div
      aria-label="Área"
      className="container-fluid area-item"
      onClick={onClick}
      tabIndex={0}
      role="button"
      onKeyUp={() => {}}
    >
      <div className="row h-100">
        <div className="col-3">
          <span
            id={`area-item-icon-${areaData.id}`}
            aria-label="Centralizar Área no mapa"
            className="area-item-icon text-center default-green"
            onClick={e => {
              if (onIconClick) onIconClick();
              e.stopPropagation();
            }}
            tabIndex={0}
            role="button"
            onKeyUp={() => {}}
          >
            <i className="fa-solid fa-map-location-dot" />
          </span>
          <Tooltip anchorSelect={`#area-item-icon-${areaData.id}`} place="top">
            Centralizar
          </Tooltip>
        </div>
        <div className="col-7 item-info">
          <div className="row h-100">
            <div className="d-flex col-12 flex-column">
              <h5>{areaData.name}</h5>
              <span>
                Área total: {formatAreaToHectare(areaData.total_area_m2)}
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex col-2 flex-column justify-content-center align-items-end p-0">
          <div className="item-button mb-2">
            <BaseButton
              iconOnly
              size="sm"
              onClick={e => {
                onEdit(areaData.id);
                e.stopPropagation();
              }}
              onKeyUp={() => {}}
            >
              <i className="fas fa-edit text-black" />
            </BaseButton>
          </div>
          <div className="item-button">
            <BaseButton
              iconOnly
              size="sm"
              onClick={e => {
                onDelete(areaData.id);
                e.stopPropagation();
              }}
              onKeyUp={() => {}}
            >
              <i className="fas fa-trash text-black" />
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  );
}

AreaItem.defaultProps = {
  onIconClick: () => {},
};

export default AreaItem;
