import React from 'react';
import './StatsCard.css';

interface StatsCardFooterProps {
  children: React.ReactNode;
  className?: string;
}

interface StatsCardProps {
  title?: string;
  titleType?: 'smallBold' | 'largeMuted';
  subTitle?: string;
  value: string;
  children?: React.ReactElement<StatsCardProps>;
  description?: string;
  icon?: string;
  spacing?: 'sm' | 'md' | 'lg' | 'xl';
  iconDisabled?: boolean;
  valueMuted?: boolean;
  fullHeight?: boolean;
}

export function StatsCardFooter({
  children,
  className,
}: StatsCardFooterProps): JSX.Element {
  return <div className={`stats-card-footer ${className} `}>{children}</div>;
}

function StatsCard({
  title,
  titleType,
  subTitle,
  value,
  children,
  description,
  icon,
  spacing,
  iconDisabled,
  valueMuted,
  fullHeight,
}: StatsCardProps): JSX.Element {
  const hasHeader = (): boolean => {
    return !!title || !iconDisabled;
  };

  const toTitleClass = (type: string): string => {
    switch (type) {
      case 'smallBold':
        return 'small-bold';
      case 'largeMuted':
        return 'large-muted';
      default:
        return '';
    }
  };
  return (
    <div className={`stats-card ${fullHeight ? 'h-100' : ''}`}>
      {hasHeader() && (
        <div className="stats-card-header">
          <span className={`stats-card-title ${toTitleClass(titleType || '')}`}>
            {title}
          </span>
          <span className="stats-card-icon default-green">
            {!iconDisabled && icon?.startsWith('fas fa-') ? (
              <i className={`${icon}`} />
            ) : (
              icon
            )}
          </span>
        </div>
      )}
      <div className={`stats-card-body spacing-${spacing}`}>
        {subTitle && <div className="stats-card-subtitle">{subTitle}</div>}
        <div className={`stats-card-value ${valueMuted ? 'text-muted' : ''} `}>
          {value}
        </div>
        {description && (
          <div className="stats-card-description text-muted">{description}</div>
        )}
        {children || null}
      </div>
    </div>
  );
}

StatsCardFooter.defaultProps = {
  className: '',
};

StatsCard.defaultProps = {
  children: undefined,
  spacing: 'md',
  title: '',
  titleType: 'largeMuted',
  subTitle: '',
  description: '',
  icon: '',
  iconDisabled: false,
  valueMuted: false,
  fullHeight: false,
};

export default StatsCard;
