import React from 'react';

interface MaintenanceProps {
  maintenanceObj: string;
}

function Maintenance({ maintenanceObj }: MaintenanceProps): JSX.Element {
  return (
    <div className="d-flex container-fluid justify-content-center align-items-center flex-column gap-4">
      <i className="fas fa-tools fa-5x text-muted" />
      <h1
        className="text-center text-muted"
        style={{
          fontSize: 'calc(1rem + 1.5vw)',
        }}
      >
        Estamos em manutenção. <br /> {maintenanceObj} indisponível
        temporariamente.
      </h1>
    </div>
  );
}

export default Maintenance;
