import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './FarmSidebar.css';

import { BaseModal, BaseButton, ScrollTrigger, BottomSheet } from 'components';

import { toast } from 'react-toastify';
import api from 'services/api';
import { getCatchedErrors, ErrorObject } from 'helpers/apiHelper';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

import FarmItem, { Farm } from './FarmItem';

interface FarmSidebarProps {
  className?: string;
  farms: Farm[];
  loaderInfo?: {
    isLoading: boolean;
    lastPage: boolean;
  };
  type?: 'desktop' | 'mobile';
  setFarms: React.Dispatch<React.SetStateAction<Farm[]>>;
  onSelectFarm: (farm_id: number) => void;
  onCenterFarm: (farm_id: number) => void;
  onPaginate?: () => void;
}

function FarmSidebar({
  className,
  farms,
  loaderInfo,
  type,
  setFarms,
  onSelectFarm,
  onCenterFarm,
  onPaginate,
}: FarmSidebarProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sbBodyRef = useRef<HTMLDivElement>(null);
  const bsBodyRef = useRef<HTMLDivElement>(null);

  const [bsOpen, setBsOpen] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    owner: '',
    lat: 0,
    lng: 0,
    id: 0,
  });
  const [modals, setModals] = useState({
    editFarm: false,
    storeFarm: false,
    deleteFarmConfirmation: false,
  });

  const handleOpenDeleteFarmModal = (id: number): void => {
    setBsOpen(false);
    const farmToDelete = farms.find(_farm => _farm.id === id);
    if (farmToDelete) {
      setFormData(farmToDelete);
      setModals({
        ...modals,
        deleteFarmConfirmation: true,
      });
    }
  };

  const handleCloseDeleteFarmModal = (): void => {
    setModals({
      ...modals,
      deleteFarmConfirmation: false,
    });

    setTimeout(() => {
      setFormData({
        name: '',
        owner: '',
        lat: 0,
        lng: 0,
        id: 0,
      });
    }, 500);
  };

  const handleDeleteFarm = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      await api.delete(`customer/farms/${formData.id}`);
      toast.success('Fazenda excluída com sucesso!', {
        position: 'bottom-center',
      });
      setFarms(farms.filter(_farm => _farm.id !== formData.id));
      handleCloseDeleteFarmModal();
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao excluir a fazenda. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const getSidebarContent = (): React.ReactNode => {
    return (
      <>
        {farms.length ? (
          farms.map(_farm => (
            <div className="w-100 h-auto mb-4" key={`farm-item_${_farm.id}`}>
              <FarmItem
                key={_farm.id}
                farmData={_farm}
                onClick={() => onSelectFarm(_farm.id)}
                onIconClick={() => {
                  onCenterFarm(_farm.id);
                  setBsOpen(false);
                }}
                onDelete={handleOpenDeleteFarmModal}
                onEdit={() => {
                  navigate(`/customer/farms/${_farm.id}/edit`);
                }}
              />
            </div>
          ))
        ) : (
          <div className="d-flex w-100 h-auto text-center flex-column">
            <span className="no-farms-text text-muted">
              Nenhuma fazenda cadastrada
            </span>
            <span className="no-farms-text text-muted">
              Cadastre uma nova fazenda clicando em{' '}
              <i className="fas fa-plus" />
            </span>
          </div>
        )}
        {loaderInfo?.isLoading && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Carregando...</span>
            </div>
          </div>
        )}
        {!loaderInfo?.isLoading && !loaderInfo?.lastPage && (
          <div className="d-flex justify-content-center">
            <button
              aria-label="Carregar mais fazendas"
              type="button"
              className="btn btn-link"
              style={{
                textDecoration: 'none',
                fontWeight: 'bold',
              }}
              onClick={() => {
                if (onPaginate) onPaginate();
              }}
            >
              Carregar mais...
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="container-fluid sidebar-wrapper p-0">
      {type === 'desktop' ? (
        <div className="dialog-container">
          <ScrollTrigger
            forwardedRef={sbBodyRef}
            onScrollEnd={() => {
              if (onPaginate) onPaginate();
            }}
          >
            <div ref={sbBodyRef} className="dialog-container-body">
              {getSidebarContent()}
            </div>
          </ScrollTrigger>
          <div
            aria-label="Nova Fazenda"
            className="dialog-container-footer"
            role="button"
            tabIndex={0}
            onKeyUp={() => {}}
            onClick={() => {
              navigate('/customer/farms/new');
            }}
          >
            <i className="fas fa-plus" />
          </div>
        </div>
      ) : (
        <ScrollTrigger
          forwardedRef={bsBodyRef}
          onScrollEnd={() => {
            if (onPaginate) onPaginate();
          }}
        >
          <BottomSheet
            title="Fazendas"
            scrollRef={bsBodyRef}
            className={className}
            open={bsOpen}
            controlBtn={
              <BaseButton
                size="xs"
                onClick={() => navigate('/customer/farms/new')}
              >
                <i className="fas fa-plus" /> Nova
              </BaseButton>
            }
            initialState="middle-opened"
            onChange={open => setBsOpen(open)}
          >
            <div className="bottom-sheet-content">{getSidebarContent()}</div>
          </BottomSheet>
        </ScrollTrigger>
      )}
      <BaseModal
        size="sm"
        open={modals.deleteFarmConfirmation}
        onClose={() => {
          handleCloseDeleteFarmModal();
        }}
      >
        <div className="modal-body p-3">
          <div className="row">
            <div className="d-flex col-12 text-center flex-column">
              <span className="text-danger">
                <i className="fas fa-trash fa-4x" />
              </span>
              <b className="text-danger mt-2 mb-4">ATENÇÃO!</b>
            </div>
            <div className="col-12 text-center">
              <span className="text-muted">
                Deseja realmente excluir a fazenda <b>{formData.name}</b>? Todas
                suas áreas, e requisições serão{' '}
                <b className="text-danger">excluidas</b> também.
              </span>
            </div>
          </div>
        </div>
        <div
          className="modal-footer w-100"
          style={{ position: 'absolute', bottom: 0 }}
        >
          <div className="row">
            <div className="d-flex col-12 justify-content-end align-items-center">
              <span
                className="exit-modal-btn"
                tabIndex={0}
                role="button"
                onKeyUp={() => {}}
                onClick={handleCloseDeleteFarmModal}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </span>
              <BaseButton onClick={handleDeleteFarm}>
                <span className="d-sm-none">Confirmar</span>
                <span className="d-none d-sm-inline">Excluir Fazenda</span>
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>
    </div>
  );
}

FarmSidebar.defaultProps = {
  className: '',
  type: 'desktop',
  loaderInfo: {
    isLoading: false,
    lastPage: false,
  },
  onPaginate: () => {},
};

export default FarmSidebar;
