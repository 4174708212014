import React from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
  className?: string;
  forwardedRef?: React.RefObject<HTMLDivElement>;
}

function TabPanel({
  children,
  value,
  index,
  className,
  forwardedRef,
}: TabPanelProps): JSX.Element {
  return (
    <div
      className={`${className}`}
      ref={forwardedRef}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ padding: '20px', overflowY: 'auto', overflowX: 'hidden' }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span" variant="body2">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.defaultProps = {
  className: '',
  forwardedRef: null,
};

export { TabPanel, Tabs, Tab };
