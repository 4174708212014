import React from 'react';
import './FarmItem.css';
import { BaseButton } from 'components';
import { Tooltip } from 'react-tooltip';

export interface Farm {
  id: number;
  name: string;
  city: string;
  owner: string;
  lat: number;
  lng: number;
  customer_id: number;
}

interface FarmItemProps {
  farmData: Farm;
  onClick: () => void;
  onIconClick?: () => void;
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
}

function FarmItem({
  farmData,
  onClick,
  onIconClick,
  onEdit,
  onDelete,
}: FarmItemProps): JSX.Element {
  const formatCep = (cep: string): string => {
    if (cep.length === 8) {
      return `${cep.slice(0, 5)}-${cep.slice(5)}`;
    }
    return cep;
  };

  return (
    <div
      aria-label="Fazenda"
      className="container-fluid farm-item"
      onClick={onClick}
      tabIndex={0}
      role="button"
      onKeyUp={() => {}}
    >
      <div className="row h-100">
        <div className="col-3">
          <span
            id={`farm-item-icon-${farmData.id}`}
            aria-label="Centralizar Fazenda no mapa"
            className="farm-item-icon text-center default-green"
            onClick={e => {
              if (onIconClick) onIconClick();
              e.stopPropagation();
            }}
            tabIndex={0}
            role="button"
            onKeyUp={() => {}}
          >
            <i className="fas fa-building-wheat" />
          </span>
          <Tooltip anchorSelect={`#farm-item-icon-${farmData.id}`} place="top">
            Centralizar
          </Tooltip>
        </div>
        <div className="col-7 item-info">
          <div className="row h-100">
            <div className="d-flex col-12 flex-column">
              <h5>{farmData.name}</h5>
              <span>{farmData.city}</span>
              <span>Proprietário: {formatCep(farmData.owner)}</span>
            </div>
          </div>
        </div>
        <div className="d-flex col-2 flex-column justify-content-center align-items-end p-0">
          <div className="item-button mb-2">
            <BaseButton
              iconOnly
              size="sm"
              onClick={e => {
                onEdit(farmData.id);
                e.stopPropagation();
              }}
              onKeyUp={() => {}}
            >
              <i className="fas fa-edit text-black" />
            </BaseButton>
          </div>
          <div className="item-button">
            <BaseButton
              iconOnly
              size="sm"
              onClick={e => {
                onDelete(farmData.id);
                e.stopPropagation();
              }}
              onKeyUp={() => {}}
            >
              <i className="fas fa-trash text-black" />
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  );
}

FarmItem.defaultProps = {
  onIconClick: () => {},
};

export default FarmItem;
