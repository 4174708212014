import React, { useState, useEffect } from 'react';
import './PackagesView.css';

import api from 'services/api';
import { getCatchedErrors } from 'helpers/apiHelper';
import { ToastContainer, toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

import {
  StatsCard,
  StatsCardFooter,
  BaseButton,
  BaseModal,
  BaseInput,
} from 'components';

type Package = {
  id: number;
  name: string;
  description: string;
  brl_price_per_ha: number | string;
  ha_amount: number;
  is_active: boolean;
  promotion_start_date: Date;
  promotion_end_date: Date;
  quantity: number;
};

function PackagesView(): JSX.Element {
  const dispatch = useDispatch();

  const [packages, setPackages] = useState<Package[]>([]);
  const [editPackageModal, setEditPackageModal] = useState({
    open: false,
    pkg: {} as Package,
  });

  const loadPackages = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { data } = await api.get('admin/credit-packages');
      setPackages(data);
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao carregar os dados. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  useEffect(() => {
    loadPackages();
  }, []);

  const handleUpdatePackage = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      await api.put(`admin/credit-packages/${editPackageModal.pkg.id}`, {
        brl_price_per_ha: Number(editPackageModal.pkg.brl_price_per_ha),
      });
      toast.success('Pacote atualizado com sucesso!', {
        position: 'bottom-center',
      });
      setEditPackageModal({
        open: false,
        pkg: {} as Package,
      });
      await loadPackages();
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao atualizar o pacote. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  return (
    <div className="container-fluid p-4 overflow-auto">
      <div className="row">
        <div className="d-flex col-12 align-items-center">
          <h3 className="text-muted">Pacotes disponíveis para compra</h3>
        </div>
      </div>
      <div className="row gx-5">
        {packages.map((pkg, index) => (
          <div className="col-12 col-md-6 col-lg-4 mb-4" key={pkg.id}>
            <StatsCard
              title={pkg.name}
              titleType="smallBold"
              value={`R$: ${pkg.brl_price_per_ha.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} / ha`}
              description={pkg.description}
              spacing="lg"
              icon={(index + 1).toString()}
              valueMuted
            >
              <StatsCardFooter>
                <div className="d-flex w-100">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      width: '60%',
                      maxWidth: '250px',
                    }}
                  >
                    <BaseButton
                      size="sm"
                      onClick={() =>
                        setEditPackageModal({
                          open: true,
                          pkg,
                        })
                      }
                      fullWidth
                    >
                      Editar
                    </BaseButton>
                  </div>
                </div>
              </StatsCardFooter>
            </StatsCard>
          </div>
        ))}
      </div>
      <BaseModal
        size="sm"
        open={editPackageModal.open}
        onClose={() => {
          setEditPackageModal({
            open: false,
            pkg: {} as Package,
          });
        }}
      >
        <div className="modal-header">
          <span className="modal-title">
            <i className="fas fa-money-bill" /> Editar Pacote
          </span>
        </div>
        <div className="modal-body p-3">
          <div className="row">
            <div className="col-12 text-center">
              <span className="font-weight-bolder text-muted fs-4">
                {`R$: ${editPackageModal.pkg?.brl_price_per_ha?.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                )} / ha`}
              </span>
            </div>
            <div className="col-12 mb-4 text-center">
              <span className="text-muted" style={{ marginRight: '5px' }}>
                <i className="fas fa-exclamation-triangle" /> <b>Atenção!</b>
              </span>
              <span className="text-muted">
                Selecione o valor cuidadosamente. Assim que a ação for salva,
                qualquer nova compra será calculada sobre o novo preço.
              </span>
            </div>

            <div className="col-12 mb-4">
              <BaseInput
                name="value"
                label="Novo valor R$ / ha"
                type="number"
                value={editPackageModal.pkg.brl_price_per_ha}
                onChange={e => {
                  if (Number(e.target.value) < 0) return;
                  const newPackage = { ...editPackageModal.pkg };
                  newPackage.brl_price_per_ha = Number(e.target.value);
                  if (e.target.value === '') newPackage.brl_price_per_ha = '';

                  setEditPackageModal({
                    open: true,
                    pkg: newPackage,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="row">
            <div className="d-flex col-12 justify-content-end align-items-center">
              <button
                aria-label="Fechar modal"
                className="exit-modal-btn"
                type="button"
                onClick={() => {
                  setEditPackageModal({
                    open: false,
                    pkg: {} as Package,
                  });
                }}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </button>
              <BaseButton onClick={handleUpdatePackage}>
                <span className="px-3">Salvar</span>
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>
      <ToastContainer />
    </div>
  );
}

export default PackagesView;
