import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AvailablePackagesView.css';

import api from 'services/api';
import { getCatchedErrors } from 'helpers/apiHelper';
import { ToastContainer, toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

import {
  StatsCard,
  StatsCardFooter,
  BaseButton,
  CounterInput,
} from 'components';

type Package = {
  id: number;
  name: string;
  description: string;
  brl_price_per_ha: number;
  ha_amount: number;
  is_active: boolean;
  promotion_start_date: Date;
  promotion_end_date: Date;
  quantity: number;
};

function AvailablePackagesView(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [packages, setPackages] = useState<Package[]>([]);

  const loadPackages = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { data } = await api.get('customer/credit-packages');
      setPackages(
        data.map((pkg: Package) => {
          return {
            ...pkg,
            quantity: 1,
          };
        }),
      );
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao carregar os dados. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  useEffect(() => {
    loadPackages();
  }, []);

  return (
    <div className="container-fluid p-4 overflow-auto">
      <div className="row">
        <div className="d-flex col-12 align-items-center">
          <h3 className="text-muted">Pacotes disponíveis para compra</h3>
        </div>
      </div>
      <div className="row gx-5">
        {packages.map((pkg, index) => (
          <div className="col-12 col-md-6 col-lg-4 mb-4" key={pkg.id}>
            <StatsCard
              title={pkg.name}
              titleType="smallBold"
              value={`R$: ${pkg.brl_price_per_ha.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} / ha`}
              description={pkg.description}
              spacing="lg"
              icon={(index + 1).toString()}
              valueMuted
            >
              <StatsCardFooter>
                <div className="d-flex w-100">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      width: '60%',
                      maxWidth: '250px',
                    }}
                  >
                    <BaseButton
                      size="sm"
                      onClick={() =>
                        navigate(
                          `/customer/purchase-package/${pkg.id}?quantity=${pkg.quantity}`,
                        )
                      }
                      disabled={!pkg.quantity}
                      fullWidth
                    >
                      Comprar
                    </BaseButton>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      width: '40%',
                      marginLeft: '20px',
                    }}
                  >
                    <CounterInput
                      value={pkg.quantity}
                      minValue={0}
                      onChange={value => {
                        const newPackages = [...packages];
                        newPackages[index].quantity = value;
                        setPackages(newPackages);
                      }}
                    />
                  </div>
                </div>
              </StatsCardFooter>
            </StatsCard>
          </div>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
}

export default AvailablePackagesView;
