import React, { useState, useRef, ChangeEvent } from 'react';
import './AreaSidebar.css';

import {
  BaseModal,
  BaseInput,
  BaseButton,
  FileInput,
  ScrollTrigger,
  BottomSheet,
} from 'components';

import { toast } from 'react-toastify';
import api from 'services/api';
import { getCatchedErrors, ErrorObject } from 'helpers/apiHelper';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

import AreaItem, { Area } from './AreaItem';

interface AreaSidebarProps {
  className?: string;
  areas: Area[];
  farmId: number;
  type?: 'desktop' | 'mobile';
  loaderInfo?: {
    isLoading: boolean;
    lastPage: boolean;
  };
  setAreas: React.Dispatch<React.SetStateAction<Area[]>>;
  onSelectArea: (area_id: number) => void;
  onCenterArea: (area_id: number) => void;
  onStoreArea?: (area: Area) => void;
  onPaginate?: () => void;
}

function AreaSidebar({
  className,
  areas,
  farmId,
  type,
  loaderInfo,
  setAreas,
  onSelectArea,
  onCenterArea,
  onStoreArea,
  onPaginate,
}: AreaSidebarProps): JSX.Element {
  const dispatch = useDispatch();
  const sbBodyRef = useRef<HTMLDivElement>(null);
  const bsBodyRef = useRef<HTMLDivElement>(null);

  const [bsOpen, setBsOpen] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    id: 0,
  });
  const [file, setFile] = useState<File>();

  const [modals, setModals] = useState({
    editArea: false,
    storeArea: false,
    deleteAreaConfirmation: false,
  });

  const handleCloseAreaModal = (): void => {
    setModals({
      ...modals,
      editArea: false,
      storeArea: false,
    });

    setTimeout(() => {
      setFormData({
        name: '',
        id: 0,
      });
    }, 500);
  };

  const handleOpenEditAreaModal = (id: number): void => {
    setBsOpen(false);
    const areaToEdit = areas.find(_area => _area.id === id);
    if (areaToEdit) {
      setFormData(areaToEdit);
      setModals({
        ...modals,
        editArea: true,
      });
    }
  };

  const handleOpenDeleteAreaModal = (id: number): void => {
    setBsOpen(false);
    const areaToDelete = areas.find(_area => _area.id === id);
    if (areaToDelete) {
      setFormData(areaToDelete);
      setModals({
        ...modals,
        deleteAreaConfirmation: true,
      });
    }
  };

  const handleCloseDeleteAreaModal = (): void => {
    setModals({
      ...modals,
      deleteAreaConfirmation: false,
    });

    setTimeout(() => {
      setFormData({
        name: '',
        id: 0,
      });
    }, 500);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleStoreArea = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { name } = formData;
      const data = new FormData();
      data.append('name', name);
      if (file) {
        data.append('kml_file', file);
      }

      const { data: areaData } = await api.post(
        `customer/farms/${farmId}/areas`,
        data,
      );
      handleCloseAreaModal();

      setAreas([areaData, ...areas]);
      setFormData({
        name: '',
        id: 0,
      });
      setFile(undefined);

      if (onStoreArea) onStoreArea(areaData);
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao cadastrar a área. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleUpdateArea = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { id, name } = formData;
      const data = new FormData();
      data.append('name', name);
      if (file) {
        data.append('kml_file', file);
      }

      const { data: areaData } = await api.put(
        `customer/farms/${farmId}/areas/${id}`,
        data,
      );

      handleCloseAreaModal();
      setAreas(areas.map(_area => (_area.id === id ? areaData : _area)));
      setFormData({
        name: '',
        id: 0,
      });
      setFile(undefined);
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao atualizar a área. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleDeleteArea = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { id } = formData;
      await api.delete(`customer/farms/${farmId}/areas/${id}`);
      toast.success('Área excluída com sucesso!', {
        position: 'bottom-center',
      });
      setAreas(areas.filter(_area => _area.id !== id));
      handleCloseDeleteAreaModal();
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao excluir a área. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const getSidebarContent = (): React.ReactNode => {
    return (
      <>
        {areas.length ? (
          areas.map(_area => (
            <div className="w-100 h-auto mb-4" key={`area-item_${_area.id}`}>
              <AreaItem
                key={_area.id}
                areaData={_area}
                onDelete={handleOpenDeleteAreaModal}
                onEdit={handleOpenEditAreaModal}
                onClick={() => {
                  onSelectArea(_area.id);
                }}
                onIconClick={() => {
                  onCenterArea(_area.id);
                  setBsOpen(false);
                }}
              />
            </div>
          ))
        ) : (
          <div className="d-flex w-100 h-auto text-center flex-column">
            <span className="no-areas-text text-muted">
              Nenhuma área cadastrada
            </span>
            <span className="no-areas-text text-muted">
              Cadastre uma nova área clicando em <i className="fas fa-plus" />
            </span>
          </div>
        )}
        {loaderInfo?.isLoading && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Carregando...</span>
            </div>
          </div>
        )}
        {!loaderInfo?.isLoading && !loaderInfo?.lastPage && (
          <div className="d-flex justify-content-center">
            <button
              aria-label="Carregar mais áreas"
              type="button"
              className="btn btn-link"
              style={{
                textDecoration: 'none',
                fontWeight: 'bold',
              }}
              onClick={() => {
                if (onPaginate) onPaginate();
              }}
            >
              Carregar mais...
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="container-fluid sidebar-wrapper p-0">
      {type === 'desktop' ? (
        <div className="dialog-container">
          <ScrollTrigger
            forwardedRef={sbBodyRef}
            onScrollEnd={() => {
              if (onPaginate) onPaginate();
            }}
          >
            <div ref={sbBodyRef} className="dialog-container-body">
              {getSidebarContent()}
            </div>
          </ScrollTrigger>
          <div
            aria-label="Nova Área"
            className="dialog-container-footer"
            role="button"
            tabIndex={0}
            onKeyUp={() => {}}
            onClick={() => {
              setModals({
                ...modals,
                storeArea: true,
              });
            }}
          >
            <i className="fas fa-plus" />
          </div>
        </div>
      ) : (
        <ScrollTrigger
          forwardedRef={sbBodyRef}
          onScrollEnd={() => {
            if (onPaginate) onPaginate();
          }}
        >
          <BottomSheet
            className={className}
            title="Áreas"
            scrollRef={bsBodyRef}
            open={bsOpen}
            initialState="middle-opened"
            controlBtn={
              <BaseButton
                size="xs"
                onClick={() => {
                  setModals({
                    ...modals,
                    storeArea: true,
                  });
                  setBsOpen(false);
                }}
              >
                <i className="fas fa-plus" /> Nova
              </BaseButton>
            }
            onChange={open => setBsOpen(open)}
          >
            <div className="bottom-sheet-content">{getSidebarContent()}</div>
          </BottomSheet>
        </ScrollTrigger>
      )}
      <BaseModal
        size="sm"
        open={modals.editArea || modals.storeArea}
        onClose={() => {
          handleCloseAreaModal();
        }}
      >
        <div className="modal-header">
          <span className="modal-title">
            <i className="fas fa-plus" /> {modals.editArea ? 'Editar' : 'Nova'}{' '}
            Área
          </span>
        </div>
        <div className="modal-body p-3">
          <div className="row">
            {modals.editArea && (
              <div className="col-12 mb-4 text-center">
                <span className="text-muted" style={{ marginRight: '5px' }}>
                  <i className="fas fa-exclamation-triangle" /> <b>Atenção!</b>
                </span>
                <span className="text-muted">
                  Só é possível editar o nome da área.
                </span>
              </div>
            )}
            <div className="col-12 mb-4">
              <BaseInput
                name="name"
                label="Nome"
                placeholder="Nome da área"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            {modals.storeArea && (
              <div className="d-flex col-12 flex-column">
                <span className="mb-1">Arquivo KML da Área</span>
                <FileInput
                  value={file}
                  label="Selecione um Arquivo"
                  name="kml_file"
                  accept=".kml"
                  onChange={handleFileChange}
                />
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <div className="row">
            <div className="d-flex col-12 justify-content-end align-items-center">
              <span
                className="exit-modal-btn"
                tabIndex={0}
                role="button"
                onKeyUp={() => {}}
                onClick={() => {
                  handleCloseAreaModal();
                }}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </span>
              <BaseButton
                onClick={modals.editArea ? handleUpdateArea : handleStoreArea}
              >
                Salvar{' '}
                {modals.editArea ? (
                  <span className="d-none d-sm-inline">Alterações</span>
                ) : (
                  ''
                )}
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>
      <BaseModal
        size="sm"
        open={modals.deleteAreaConfirmation}
        onClose={() => {
          handleCloseDeleteAreaModal();
        }}
      >
        <div className="modal-body p-3">
          <div className="row">
            <div className="d-flex col-12 text-center flex-column">
              <span className="text-danger">
                <i className="fas fa-trash fa-4x" />
              </span>
              <b className="text-danger mt-2 mb-4">ATENÇÃO!</b>
            </div>
            <div className="col-12 text-center">
              <span className="text-muted">
                Deseja realmente excluir a área <b>{formData.name}</b>? Todas
                requisições serão <b className="text-danger">excluídas</b>{' '}
                também.
              </span>
            </div>
          </div>
        </div>
        <div
          className="modal-footer w-100"
          style={{ position: 'absolute', bottom: 0 }}
        >
          <div className="row">
            <div className="d-flex col-12 justify-content-end align-items-center">
              <span
                className="exit-modal-btn"
                tabIndex={0}
                role="button"
                onKeyUp={() => {}}
                onClick={handleCloseDeleteAreaModal}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </span>
              <BaseButton onClick={handleDeleteArea}>
                <span className="d-sm-none">Confirmar</span>
                <span className="d-none d-sm-inline">Excluir Área</span>
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>
    </div>
  );
}

AreaSidebar.defaultProps = {
  className: '',
  type: 'desktop',
  loaderInfo: {
    isLoading: false,
    lastPage: false,
  },
  onStoreArea: () => {},
  onPaginate: () => {},
};

export default AreaSidebar;
