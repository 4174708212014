import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './FarmList.css';

import { InteractiveMap, BaseModal, BaseButton } from 'components';

import api from 'services/api';
import { getCatchedErrors, ErrorObject } from 'helpers/apiHelper';
import { toast, ToastContainer } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import { setVerifyEmailModal, State } from 'store';

import FarmSidebar from './FarmSidebar/FarmSidebar';
import { Farm } from './FarmSidebar/FarmItem';

function FarmList(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const isEmailModalOpen = useSelector(
    (state: State) => state.verifyEmailModal,
  );

  const [farms, setFarms] = useState<Farm[]>([]);

  const [currPage, setCurrPage] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [loadingFarms, setLoadingFarms] = useState(false);

  const [markerMessage, setMarkerMessage] = useState<string>('Spray Solutions');
  const [mapCenter, setMapCenter] = useState<[number, number]>([
    -29.711025188644363, -53.71634781360627,
  ]);

  const loadFarms = async (page: number): Promise<void> => {
    const perPage = 15;
    setLoadingFarms(true);
    try {
      const { data } = await api.get(
        `customer/farms?page=${page}&perPage=${perPage}`,
      );

      setFarms(prev => (page === 1 ? data.farms : [...prev, ...data.farms]));
      setLastPage(data.lastPage);
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao carregar as fazendas. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
    setLoadingFarms(false);
  };

  useEffect(() => {
    if (!lastPage) loadFarms(currPage);
  }, [currPage]);

  useEffect(() => {
    const { state } = location;
    if (state && state.onFocus) {
      const farm = farms.find(_farm => _farm.id === state.onFocus);
      if (farm) {
        setMapCenter([farm.lat, farm.lng]);
        setMarkerMessage(farm.name);
      }
    }
  }, [location, farms]);

  const handleOpenAreas = (farmId: number): void => {
    navigate(`/customer/farms/${farmId}/areas`);
  };

  const handleCenterFarm = (farmId: number): void => {
    const farm = farms.find(_farm => _farm.id === farmId);
    if (farm) {
      setMapCenter([farm.lat, farm.lng]);
      setMarkerMessage(farm.name);
    }
  };

  return (
    <>
      <div className="d-flex container-fluid h-100 p-0 flex-column flex-lg-row">
        <div className="d-none d-lg-block farm-list-sidebar">
          <div className="sidebar-header">
            <span className="title">Fazendas</span>
          </div>
          <FarmSidebar
            type="desktop"
            farms={farms}
            setFarms={setFarms}
            loaderInfo={{ isLoading: loadingFarms, lastPage }}
            onSelectFarm={handleOpenAreas}
            onCenterFarm={handleCenterFarm}
            onPaginate={() => {
              if (!lastPage) setCurrPage(prev => prev + 1);
            }}
          />
        </div>
        <div className="farm-list-map-container">
          <InteractiveMap
            center={mapCenter}
            markerPosition={mapCenter}
            popupMessage={markerMessage}
            zoom={17}
            showMarker
          />
        </div>
        <div className="d-lg-none">
          <FarmSidebar
            className="d-block d-lg-none"
            type="mobile"
            farms={farms}
            setFarms={setFarms}
            loaderInfo={{ isLoading: loadingFarms, lastPage }}
            onSelectFarm={handleOpenAreas}
            onCenterFarm={handleCenterFarm}
            onPaginate={() => {
              if (!lastPage) setCurrPage(prev => prev + 1);
            }}
          />
        </div>
      </div>
      <ToastContainer />
      <BaseModal
        size="sm"
        open={isEmailModalOpen}
        onClose={() => {
          dispatch(setVerifyEmailModal(false));
        }}
      >
        <div className="modal-body p-3">
          <div className="row">
            <div className="d-flex col-12 text-center flex-column">
              <span className="text-success">
                <i className="fas fa-envelope fa-4x" />
              </span>
              <b className="text-muted mt-2 mb-4">Verifique seu email</b>
            </div>
            <div className="col-12 text-center">
              <span>
                Verifique seu email e ganhe{' '}
                <strong className="text-success">30ha</strong> de processamento!
              </span>
            </div>
          </div>
        </div>
        <div className="modal-footer w-100">
          <div className="row">
            <div className="d-flex col-12 justify-content-end align-items-center">
              <span
                className="exit-modal-btn"
                tabIndex={0}
                role="button"
                onKeyUp={() => {}}
                onClick={() => {
                  dispatch(setVerifyEmailModal(false));
                }}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </span>
              <BaseButton
                onClick={() => {
                  navigate('/customer/verify-email');
                  dispatch(setVerifyEmailModal(false));
                }}
              >
                <span>Verificar</span>
                <span className="d-none d-sm-inline"> Email</span>
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>
    </>
  );
}

export default FarmList;
