import React, { HtmlHTMLAttributes } from 'react';
import { IconButton } from '@mui/material';
import { Tooltip } from 'react-tooltip';
import './BaseButton.css';

interface BaseButtonProps extends HtmlHTMLAttributes<HTMLButtonElement> {
  iconOnly?: boolean;
  color?:
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'inherit';
  disabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  tooltip?: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  fullWidth?: boolean;
}

function BaseButton({
  id,
  className,
  color,
  disabled,
  children,
  size,
  iconOnly,
  tooltip,
  tooltipPosition,
  fullWidth,
  onClick,
  onKeyUp,
}: BaseButtonProps): JSX.Element {
  const sizeMap: {
    [key: string]: 'small' | 'medium' | 'large';
  } = {
    sm: 'small',
    md: 'medium',
    lg: 'large',
  };

  return iconOnly ? (
    <>
      <IconButton
        id={id}
        sx={{
          borderRadius: '0px',
          border: 'none',
          padding: '0.5em 1em',
          transition: 'all 0.3s ease',
          backgroundColor: '#028f56',
          '&:hover': {
            backgroundColor: '#01a664',
          },
        }}
        color={color}
        disabled={disabled}
        size={sizeMap[size || 'md']}
        onClick={onClick}
        onKeyUp={onKeyUp}
      >
        {children}
      </IconButton>
      {tooltip && (
        <Tooltip anchorSelect={`#${id}`} place={tooltipPosition}>
          {tooltip}
        </Tooltip>
      )}
    </>
  ) : (
    <>
      <button
        id={id}
        className={`${className} base-button ${fullWidth ? 'full-width' : ''} ${size}`}
        type="button"
        color={color}
        disabled={disabled}
        onClick={onClick}
        onKeyUp={onKeyUp}
      >
        {children}
      </button>
      {tooltip && (
        <Tooltip anchorSelect={`#${id}`} place={tooltipPosition}>
          {tooltip}
        </Tooltip>
      )}
    </>
  );
}

BaseButton.defaultProps = {
  disabled: false,
  color: 'primary',
  size: 'md',
  iconOnly: false,
  tooltip: '',
  tooltipPosition: 'top',
  fullWidth: false,
};

export default BaseButton;
