import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './BalanceView.css';

import api from 'services/api';
import { getCatchedErrors } from 'helpers/apiHelper';
import { ToastContainer, toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

import {
  StatsCard,
  BaseTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  BaseButton,
  BaseModal,
  BaseInput,
  ScrollTrigger,
} from 'components';

type Balance = {
  customer_id: number;
  customer_name: string;
  total_purchased: number;
  total_used: number;
  total_balance: number;
  total_bonus: number;
};

type BalanceStatistics = {
  total_purchased: number;
  total_used: number;
  total_balance: number;
  total_bonus: number;
};

function BalanceView(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tBodyRef = useRef<HTMLTableSectionElement>(null);

  const [currPage, setCurrPage] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [loadingBalances, setLoadingBalances] = useState(false);

  const [balances, setBalances] = useState<Balance[]>([]);
  const [statistics, setStatistics] = useState<BalanceStatistics>({
    total_purchased: 0,
    total_used: 0,
    total_balance: 0,
    total_bonus: 0,
  });

  const [bonusModal, setBonusModal] = useState({
    open: false,
    customer_id: 0,
    customer_name: '',
    bonus_amount: '',
  });

  const loadStatistics = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { data } = await api.get('admin/hectare-balances/statistics');
      setStatistics(data);
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao carregar as estatísticas. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const loadBalances = async (page: number): Promise<void> => {
    setLoadingBalances(true);
    try {
      const { data } = await api.get(`admin/hectare-balances?page=${currPage}`);
      setBalances(prev =>
        page === 1 ? data.balances : [...prev, ...data.balances],
      );
      setLastPage(data.lastPage);
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao carregar os dados. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    setLoadingBalances(false);
  };

  useEffect(() => {
    loadStatistics();
  }, []);

  useEffect(() => {
    if (!lastPage) loadBalances(currPage);
  }, [currPage]);

  const handleStoreBonusTransaction = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      await api.post(
        `admin/bonus-transactions/customer/${bonusModal.customer_id}`,
        {
          bonus_amount: Number(bonusModal.bonus_amount),
        },
      );
      toast.success('Bonificação realizada com sucesso.', {
        position: 'bottom-center',
      });
      setBonusModal({
        customer_id: 0,
        customer_name: '',
        open: false,
        bonus_amount: '',
      });
      setCurrPage(1);
      await loadStatistics();
      await loadBalances(1);
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao realizar a bonificação. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  return (
    <div className="container-fluid p-4 overflow-auto">
      <div className="row mb-4">
        <div className="col-12 col-lg-3 mb-2 mb-lg-0">
          <StatsCard
            title="Total Contratado"
            titleType="largeMuted"
            value={`${statistics.total_purchased.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ha`}
            description="Esse é o total de ha contratados"
          />
        </div>
        <div className="col-12 col-lg-3 mb-2 mb-lg-0">
          <StatsCard
            title="Total Bonificado"
            titleType="largeMuted"
            value={`${statistics.total_bonus.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ha`}
            description="Esse é o total de ha bonificados"
          />
        </div>
        <div className="col-12 col-lg-3 mb-2 mb-lg-0">
          <StatsCard
            title="Disponível"
            titleType="largeMuted"
            value={`${statistics.total_balance.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ha`}
            description="Esse é o valor de ha disponiveis até momento"
          />
        </div>
        <div className="col-12 col-lg-3">
          <StatsCard
            title="Consumido"
            titleType="largeMuted"
            value={`${statistics.total_used.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ha`}
            description="Esse é o valor de ha consumidos até o momento"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="d-flex card-header justify-content-between">
              <h5 className="card-title mt-1">Extrato de Utilização</h5>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <BaseTable
                  className="table m-0"
                  style={{
                    height: '100px',
                  }}
                >
                  <TableHead className="thead-light">
                    <TableRow>
                      <TableCell>Cliente</TableCell>
                      <TableCell>Total contratado (ha)</TableCell>
                      <TableCell>Total bonificado (ha)</TableCell>
                      <TableCell>Disponível (ha)</TableCell>
                      <TableCell>Consumido (ha)</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <ScrollTrigger
                    forwardedRef={tBodyRef}
                    onScrollEnd={() => {
                      if (!lastPage) setCurrPage(prev => prev + 1);
                    }}
                  >
                    <TableBody
                      ref={tBodyRef}
                      style={{
                        maxHeight: '50vh',
                        overflowY: 'auto',
                      }}
                    >
                      {balances.map((balance: Balance) => (
                        <TableRow key={balance.customer_id}>
                          <TableCell>
                            <button
                              aria-label="Ver detalhes do cliente"
                              type="button"
                              className="btn btn-link"
                              onClick={() =>
                                navigate(
                                  `/admin/customers/${balance.customer_id}`,
                                )
                              }
                            >
                              {balance.customer_name}
                            </button>
                          </TableCell>
                          <TableCell>
                            {balance.total_purchased.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                          <TableCell>
                            {balance.total_bonus.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                          <TableCell>
                            {balance.total_balance.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                          <TableCell>
                            {balance.total_used.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                          <TableCell>
                            <BaseButton
                              size="sm"
                              onClick={() => {
                                setBonusModal({
                                  customer_id: balance.customer_id,
                                  customer_name: balance.customer_name,
                                  open: true,
                                  bonus_amount: '',
                                });
                              }}
                            >
                              <span className="px-4">
                                <i className="fas fa-money-bill" />
                              </span>
                            </BaseButton>
                          </TableCell>
                        </TableRow>
                      ))}

                      {(loadingBalances || !lastPage) && (
                        <TableRow>
                          <TableCell>
                            {loadingBalances && (
                              <div className="d-flex justify-content-center">
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            )}
                            {!loadingBalances && !lastPage && (
                              <div className="d-flex justify-content-center">
                                <button
                                  aria-label="Carregar mais transações"
                                  type="button"
                                  className="btn btn-link"
                                  onClick={() => setCurrPage(prev => prev + 1)}
                                >
                                  Carregar mais...
                                </button>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </ScrollTrigger>
                </BaseTable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BaseModal
        size="sm"
        open={bonusModal.open}
        onClose={() => {
          setBonusModal({
            customer_id: 0,
            customer_name: '',
            open: false,
            bonus_amount: '',
          });
        }}
      >
        <div className="modal-header">
          <span className="modal-title">
            <i className="fas fa-money-bill" /> Nova Bonificação
          </span>
        </div>
        <div className="modal-body p-3">
          <div className="row">
            <div className="col-12 text-center">
              <span className="font-weight-bolder text-muted fs-4">
                {bonusModal.customer_name}
              </span>
            </div>
            <div className="col-12 mb-4 text-center">
              <span className="text-muted" style={{ marginRight: '5px' }}>
                <i className="fas fa-exclamation-triangle" /> <b>Atenção!</b>
              </span>
              <span className="text-muted">
                Selecione o valor cuidadosamente. Por enquanto esta ação não é
                facilmente reversível.
              </span>
            </div>

            <div className="col-12 mb-4">
              <BaseInput
                name="value"
                label="Valor a ser bonificado (ha)"
                type="number"
                value={bonusModal.bonus_amount}
                onChange={e => {
                  if (Number(e.target.value) < 0) return;
                  setBonusModal(prev => ({
                    ...prev,
                    bonus_amount: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="row">
            <div className="d-flex col-12 justify-content-end align-items-center">
              <button
                aria-label="Fechar modal"
                className="exit-modal-btn"
                type="button"
                onClick={() => {
                  setBonusModal({
                    customer_id: 0,
                    customer_name: '',
                    open: false,
                    bonus_amount: '',
                  });
                }}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </button>
              <BaseButton onClick={handleStoreBonusTransaction}>
                Confirmar
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>
      <ToastContainer />
    </div>
  );
}

export default BalanceView;
