import React, { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfileEdit.css';

import { TextField, BaseButton, BaseModal, ProfilePicture } from 'components';

import api from 'services/api';
import { getUserInfo, updateUserEmail } from 'services/auth';
import { getCatchedErrors } from 'helpers/apiHelper';
import { ToastContainer, toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

type Customer = {
  id: number;
  user_id: number;
  name: string;
  identification_number: string;
  type: 'natural' | 'legal';
  phone: string;
  cep: string;
  street: string;
  number: string;
  district: string;
  city: string;
  state: string;
  complement: string;
  country: string;
  email: string;
  profilePicture?: {
    url: string;
    name: string;
  };
};

function ProfileEdit(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = getUserInfo();

  const [formData, setFormData] = useState<Customer>({
    id: 0,
    user_id: 0,
    name: '',
    identification_number: '',
    type: 'natural',
    phone: '',
    cep: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    country: '',
    email: '',
  });
  const [updateProfileModal, setUpdateProfileModal] = useState(false);

  const handleLoadCustomer = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { data } = await api.get('/customer/self');

      setFormData(data);
      setFormData(prevState => {
        return {
          ...prevState,
          email: userInfo?.email || '',
        };
      });
    } catch (e) {
      const errors = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Erro ao carregar os dados. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  useEffect(() => {
    handleLoadCustomer();
  }, []);

  useEffect(() => {
    const cepRegex = /^\d{5}-\d{3}$/;
    const handleCep = async (): Promise<void> => {
      dispatch(setLoader(true));
      try {
        const { data } = await api.get(
          `https://viacep.com.br/ws/${formData.cep}/json/`,
        );
        if (data) {
          setFormData(prevFormData => ({
            ...prevFormData,
            country: 'Brasil',
            state: data.uf,
            city: data.localidade,
            street: data.logradouro,
            district: data.bairro,
          }));
        }
      } catch (e) {
        toast.error('CEP inválido', { position: 'bottom-center' });
      }
      dispatch(setLoader(false));
    };

    if (cepRegex.test(formData.cep) && formData.cep !== '') {
      handleCep();
    }
  }, [formData.cep]);

  const handleUpdateProfile = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      await api.put('/customer/self', formData);

      setUpdateProfileModal(false);
      updateUserEmail(formData.email);
      navigate('/customer/profile');

      toast.success('Perfil atualizado com sucesso!', {
        position: 'bottom-center',
      });
    } catch (e) {
      const errors = getCatchedErrors(e);
      setUpdateProfileModal(false);
      toast.error(
        errors[0].message ||
          'Erro ao atualizar o perfil. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleUpdateProfilePicture = async (
    profilePicture: File | null,
    closeModal: () => void,
  ): Promise<void> => {
    if (!profilePicture) return;

    dispatch(setLoader(true));
    try {
      const form = new FormData();
      form.append('profile_picture', profilePicture);

      const { data } = await api.put('/customer/self/profile-picture', form);

      setFormData(prevCustomerInfo => ({
        ...prevCustomerInfo!,
        profilePicture: data,
      }));

      toast.success('Foto de perfil atualizada com sucesso!', {
        position: 'bottom-center',
      });
      closeModal();
    } catch (e) {
      const errors = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Erro ao atualizar a foto de perfil. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleFormDataChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="container-fluid p-4 overflow-auto">
      <div className="page-header row mb-2">
        <div className="d-flex col-12 gap-3 align-items-md-center flex-column flex-md-row">
          <div
            className="icon-button"
            tabIndex={0}
            role="button"
            onKeyUp={() => {}}
            onClick={() => navigate('/customer/profile')}
          >
            <i className="fas fa-arrow-left" /> Voltar
          </div>
          <div className="d-flex align-items-center">
            <ProfilePicture
              src={formData?.profilePicture?.url}
              size="large"
              onUpdateProfilePic={handleUpdateProfilePicture}
              editable
            />
            <h3 className="text-muted">
              Aqui você pode editar seu edereço, <br />
              <span className="user-name">{userInfo?.name || 'Usuário'}</span>
            </h3>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title text-muted">Endereço</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <TextField
                    name="cep"
                    label="CEP"
                    value={formData?.cep || ''}
                    mask="99999-999"
                    onChange={handleFormDataChange}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <TextField
                    name="country"
                    label="País"
                    value={formData?.country || ''}
                    onChange={handleFormDataChange}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <TextField
                    name="state"
                    label="Estado"
                    value={formData?.state || ''}
                    onChange={handleFormDataChange}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <TextField
                    name="city"
                    label="Cidade"
                    value={formData?.city || ''}
                    onChange={handleFormDataChange}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <TextField
                    name="district"
                    label="Bairro"
                    value={formData?.district || ''}
                    onChange={handleFormDataChange}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <TextField
                    name="street"
                    label="Rua"
                    value={formData?.street || ''}
                    onChange={handleFormDataChange}
                  />
                </div>

                <div className="col-md-4 mb-4">
                  <TextField
                    name="number"
                    label="Número"
                    value={formData?.number || ''}
                    onChange={handleFormDataChange}
                  />
                </div>
                <div className="col-md-8 mb-4">
                  <TextField
                    name="complement"
                    label="Complemento"
                    value={formData?.complement || ''}
                    onChange={handleFormDataChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center justify-content-md-end">
          <BaseButton
            size="lg"
            onClick={() => {
              setUpdateProfileModal(true);
            }}
          >
            <i
              className="fas fa-save mr-2"
              style={{
                marginRight: '5px',
              }}
            />{' '}
            Salvar Alterações
          </BaseButton>
        </div>
      </div>
      <BaseModal
        size="sm"
        open={updateProfileModal}
        onClose={() => {
          setUpdateProfileModal(false);
        }}
      >
        <div className="modal-body p-3">
          <div className="row">
            <div className="d-flex col-12 text-center flex-column">
              <span className="text-success">
                <i className="fas fa-exclamation fa-4x" />
              </span>
              <b className="text-success mt-2 mb-4">ATENÇÃO!</b>
            </div>
            <div className="col-12 text-center">
              <span className="text-muted">
                Você confirma as alterações no seu perfil?
              </span>
            </div>
          </div>
        </div>
        <div className="modal-footer w-100">
          <div className="row">
            <div className="d-flex col-12 justify-content-end align-items-center">
              <span
                className="exit-modal-btn"
                tabIndex={0}
                role="button"
                onKeyUp={() => {}}
                onClick={() => {
                  setUpdateProfileModal(false);
                }}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </span>
              <BaseButton onClick={handleUpdateProfile}>
                Salvar Alterações
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>
      <ToastContainer />
    </div>
  );
}

export default ProfileEdit;
