import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';

import './PurchaseView.css';

import api from 'services/api';
import { getCatchedErrors } from 'helpers/apiHelper';
import { ToastContainer, toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

import { CounterInput, StatsCard, RadioInput, BaseButton } from 'components';

type Package = {
  id: number;
  name: string;
  description: string;
  brl_price_per_ha: number;
  ha_amount: number;
  is_active: boolean;
  promotion_start_date: Date;
  promotion_end_date: Date;
  quantity: number;
};

function PurchaseView(): JSX.Element {
  const { packageId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pkg, setPackage] = useState<Package>({
    id: 0,
    name: '',
    description: '',
    brl_price_per_ha: 0,
    ha_amount: 0,
    is_active: false,
    promotion_start_date: new Date(),
    promotion_end_date: new Date(),
    quantity: 0,
  });

  const [formData, setFormData] = useState({
    billing_type: 'PIX',
    due_date: DateTime.now().plus({ days: 5 }).toISODate(),
    description: '',
    callback: {
      success_url: 'https://aufarm.com.br/customer/available-packages',
      auto_redirect: true,
    },
    credit_package_id: Number(packageId),
    quantity: Number(searchParams.get('quantity')) || 1,
  });

  const loadPackage = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { data } = await api.get(`customer/credit-packages/${packageId}`);
      setPackage(data);
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao carregar os dados. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  useEffect(() => {
    loadPackage();
  }, [packageId]);

  const getPurchasePrice = (): string => {
    return (
      pkg.ha_amount *
      pkg.brl_price_per_ha *
      formData.quantity
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const getPurchaseDescription = (): string => {
    return `Adquirindo este pacote você receberá ${(pkg.ha_amount * formData.quantity).toLocaleString()} ha de processamento.`;
  };

  const handleStorePurchase = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { data } = await api.post(
        'customer/purchase-transactions/buy-package',
        {
          ...formData,
          description: getPurchaseDescription(),
        },
      );
      window.location.assign(data.invoice_url);
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao finalizar a compra. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  return (
    <div className="container-fluid p-4 overflow-auto">
      <div className="row mb-4">
        <div className="col-12 mb-4">
          <button
            className="icon-button"
            type="button"
            onClick={() => navigate('/customer/available-packages')}
          >
            <i className="fas fa-arrow-left" /> Voltar
          </button>
        </div>
        <div className="col-12">
          <h3 className="text-muted m-0">
            Preencha os a seguir dados e finalize sua compra
          </h3>{' '}
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12 col-md-8">
          <div className="card">
            <div className="d-flex card-header justify-content-between">
              <h4 className="card-title text-muted">Informações da Compra</h4>
            </div>
            <div className="card-body gap-3 flex-column">
              <div className="flex-column">
                <span
                  className="text-muted m-0"
                  style={{ fontWeight: '400', fontSize: '25px' }}
                >
                  Pacote selecionado:
                </span>
                <p className="fs-5">{pkg.name}</p>
              </div>
              <div className="flex-column">
                <span
                  className="text-muted m-0"
                  style={{ fontWeight: '400', fontSize: '25px' }}
                >
                  Preço por ha:
                </span>
                <p className="fs-5">
                  R${' '}
                  {pkg.brl_price_per_ha.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
              <div className="d-flex flex-column">
                <span
                  className="text-muted m-0 mb-2"
                  style={{ fontWeight: '400', fontSize: '25px' }}
                >
                  Quantidade:
                </span>
                <div>
                  <CounterInput
                    value={formData.quantity}
                    minValue={1}
                    onChange={value => {
                      setFormData({
                        ...formData,
                        quantity: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <StatsCard
            title="Total"
            titleType="smallBold"
            subTitle={`${(pkg.ha_amount * formData.quantity).toLocaleString()} ha`}
            value={`R$: ${getPurchasePrice()}`}
            description={getPurchaseDescription()}
            spacing="lg"
            icon="fas fa-brazilian-real-sign"
            valueMuted
            fullHeight
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="d-flex card-header justify-content-between">
              <h4 className="card-title text-muted">Pagamento</h4>
            </div>
            <div className="card-body gap-3 flex-column">
              <div className="flex-column">
                <span
                  className="text-muted m-0"
                  style={{ fontWeight: '400', fontSize: '25px' }}
                >
                  Pagar com:
                </span>
                <RadioInput
                  name="payment_method"
                  value={formData.billing_type}
                  onChange={e => {
                    setFormData({
                      ...formData,
                      billing_type: e.target.value,
                    });
                  }}
                >
                  <option value="PIX">Pix</option>
                  <option value="BOLETO">Boleto Bancário</option>
                </RadioInput>
              </div>
              <div className="flex-column mt-2 mb-1">
                <span
                  className="text-success m-0"
                  style={{ fontWeight: '600', fontSize: '25px' }}
                >
                  R$: {getPurchasePrice()}
                </span>
              </div>
              <div className="flex-column">
                <p>
                  Ao finalizar a compra com o método de pagamento selecionado,
                  você será direcionado para a página da fatura, onde poderá
                  finalizar o pagamento. O valor comprado será creditado no seu
                  saldo após a confirmação do pagamento.
                </p>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="d-flex col-12 justify-content-end">
                  <BaseButton onClick={handleStorePurchase}>
                    <span style={{ fontWeight: 'bold' }}>
                      Finalizar com {formData.billing_type}
                    </span>
                  </BaseButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default PurchaseView;
