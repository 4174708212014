import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './DesiccationRequestForm.css';

// eslint-disable-next-line import/no-unresolved
import { GeoJsonObject } from 'geojson';
import { InteractiveMap, BaseModal, BaseButton } from 'components';
import { Option } from 'components/SelectInput/SelectInput';

import api from 'services/api';
import { getCatchedErrors, ErrorObject } from 'helpers/apiHelper';
import { toast, ToastContainer } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

import FormSidebar, { MapRequest, DesiccationRequest } from '../FormSidebar';

function DesiccationRequestForm(): JSX.Element {
  const { farmId, areaId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [mapCenter, setMapCenter] = useState<[number, number]>([
    -29.711025188644363, -53.71634781360627,
  ]);
  const [mapGeoJsonLayer, setMapGeoJsonLayer] = useState<GeoJsonObject | null>(
    null,
  );

  const [areas, setAreas] = useState<Option[]>([]);
  const [balanceTotalUsages, setBalanceTotalUsages] = useState<number>(0);

  const [confirmStoreRequestModal, setConfirmStoreRequestModal] = useState({
    open: false,
    generalData: {} as MapRequest,
    specializedData: {} as DesiccationRequest,
    areasIds: [] as number[],
  });

  useEffect(() => {
    const handleLoadAreas = async (): Promise<void> => {
      dispatch(setLoader(true));
      try {
        const { data } = await api.get(`customer/farms/${farmId}/areas`);
        const { areas: areasData } = data;
        const newAreas = areasData.map(
          (area_: { id: number; name: string }) => ({
            label: area_.name,
            value: area_.id,
            isDisabled: false,
            isFixed: false,
          }),
        );
        setAreas(newAreas);
      } catch (e) {
        const errors: ErrorObject[] = getCatchedErrors(e);
        toast.error(
          errors[0].message ||
            'Ocorreu um erro ao carregar as áreas. Por favor tente novamente mais tarde',
          {
            position: 'bottom-center',
          },
        );
      }
      dispatch(setLoader(false));
    };

    const handleLoadArea = async (): Promise<void> => {
      dispatch(setLoader(true));
      try {
        const { data } = await api.get(
          `customer/farms/${farmId}/areas/${areaId}`,
        );

        const coordinates = JSON.parse(data.center_coordinates);
        setMapCenter([coordinates[1], coordinates[0]]);
        setMapGeoJsonLayer(JSON.parse(data.geojson));
      } catch (e) {
        const errors: ErrorObject[] = getCatchedErrors(e);
        toast.error(
          errors[0].message ||
            'Ocorreu um erro ao carregar a área. Por favor tente novamente mais tarde',
          {
            position: 'bottom-center',
          },
        );
      }
      dispatch(setLoader(false));
    };

    handleLoadAreas();
    handleLoadArea();
  }, [areaId, farmId]);

  const simulateBalanceTotalUsage = async (
    areasIds: number[],
  ): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { data } = await api.post(
        `customer/desiccation-requests/simulate-transaction`,
        {
          areasIdArray: areasIds,
        },
      );
      setBalanceTotalUsages(data.ha_total_usage);
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao calcular o valor utilizado. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleOpenConfirmStoreRequestModal = async (
    generalData: MapRequest,
    specializedData: DesiccationRequest,
    areasIds: number[],
  ): Promise<void> => {
    await simulateBalanceTotalUsage(areasIds);
    setConfirmStoreRequestModal({
      open: true,
      generalData,
      specializedData,
      areasIds,
    });
  };

  const handleCloseConfirmStoreRequestModal = (): void => {
    setConfirmStoreRequestModal({
      open: false,
      generalData: {} as MapRequest,
      specializedData: {} as DesiccationRequest,
      areasIds: [] as number[],
    });
  };

  const handleStoreRequest = async (): Promise<void> => {
    const { generalData, specializedData, areasIds } = confirmStoreRequestModal;

    dispatch(setLoader(true));
    try {
      const endpoint = 'desiccation-requests';
      const isMultipleAreas = areasIds.length > 1;

      await api.post(
        `customer/farms/${farmId}/areas/${areaId}/${endpoint}?multiple_areas=${isMultipleAreas}`,
        { ...generalData, ...specializedData, areasIdArray: areasIds },
      );

      toast.success('Solicitação enviada com sucesso!', {
        position: 'bottom-center',
      });

      navigate(`/customer/farms/${farmId}/areas/${areaId}/requests`);
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao finalizar a solicitação. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );

      handleCloseConfirmStoreRequestModal();
    }
    dispatch(setLoader(false));
  };

  return (
    <div className="d-flex container-fluid h-100 p-0 flex-row flex-column flex-lg-row">
      <div className="d-none d-lg-block request-form-sidebar">
        <FormSidebar
          type="desktop"
          formType="form"
          areas={areas}
          areaId={Number(areaId)}
          farmId={Number(farmId)}
          onSubmit={handleOpenConfirmStoreRequestModal}
        />
      </div>
      <div className="request-form-map-container">
        <div className="d-lg-none request-form-map-container__header">
          <div className="d-flex text-center justify-content-between">
            <div
              className="icon-button my-auto"
              tabIndex={0}
              role="button"
              onKeyUp={() => {}}
              onClick={() =>
                navigate(`/customer/farms/${farmId}/areas/${areaId}/requests`)
              }
            >
              <i className="fas fa-arrow-left" style={{ marginRight: '5px' }} />{' '}
              Voltar
            </div>
          </div>
        </div>
        <InteractiveMap
          center={mapCenter}
          markerPosition={mapCenter}
          geoJson={mapGeoJsonLayer}
          zoom={15}
        />
      </div>
      <div className="d-lg-none">
        <FormSidebar
          className="d-flex d-lg-none"
          type="mobile"
          formType="form"
          areas={areas}
          areaId={Number(areaId)}
          farmId={Number(farmId)}
          onSubmit={handleOpenConfirmStoreRequestModal}
        />
      </div>
      <BaseModal
        size="sm"
        open={confirmStoreRequestModal.open}
        onClose={handleCloseConfirmStoreRequestModal}
      >
        <div className="modal-body p-3">
          <div className="row">
            <div className="d-flex col-12 text-center flex-column">
              <span className="text-success">
                <i className="fas fa-save fa-4x" />
              </span>
              <b className="text-success mt-2 mb-4">Confirmar Solicitação</b>
            </div>
            <div className="col-12 text-center text-muted">
              <span className="text-muted">
                Você <strong className="text-success">confirma</strong> a
                solicitação de mapa de dessecação para{' '}
                {confirmStoreRequestModal.areasIds.length > 1 ? (
                  <span>
                    as{' '}
                    <strong className="text-success">
                      {confirmStoreRequestModal.areasIds.length} áreas
                    </strong>{' '}
                    selecionadas?{' '}
                  </span>
                ) : (
                  <span>a área selecionada?</span>
                )}
              </span>{' '}
              Esta transação consumirá{' '}
              <strong className="text-success">
                {balanceTotalUsages.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{' '}
                ha
              </strong>{' '}
              do seu saldo.
            </div>
          </div>
        </div>
        <div className="modal-footer w-100">
          <div className="row">
            <div className="d-flex col-12 justify-content-end align-items-center">
              <span
                className="exit-modal-btn"
                tabIndex={0}
                role="button"
                onKeyUp={() => {}}
                onClick={handleCloseConfirmStoreRequestModal}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </span>
              <BaseButton onClick={handleStoreRequest}>
                Confirmar{' '}
                <span className="d-none d-sm-inline">Solicitação</span>
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>

      <ToastContainer />
    </div>
  );
}

export default DesiccationRequestForm;
