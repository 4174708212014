import React from 'react';
import './NavBar.css';

function NavBar(): JSX.Element {
  return (
    <div className="navbar">
      <a href="/">
        <img className="logo" alt="Logo da Aufarm" src="/aufarm_logo.png" />
      </a>
    </div>
  );
}

export default NavBar;
