import React, { useEffect, useState, ChangeEvent } from 'react';
import './Dashboard.css';

import {
  BaseTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  BaseButton,
  BaseModal,
  FileInput,
  TextField,
} from 'components';

import api from 'services/api';
import { toast, ToastContainer } from 'react-toastify';
import { getCatchedErrors, ErrorObject } from 'helpers/apiHelper';
import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

type Request = {
  name: string;
  id: number;
  created_at: string;
  updated_at: string;
  status: 'pending' | 'processed' | 'canceled';
  file_id: number;
  type: 'desiccation' | 'soil_sampling';
  desiccationRequest?: {
    id: number;
    spray_volume: number;
    monitor: string;
  };
  area: {
    id: number;
    name: string;
    total_area_m2: number;
    file_id: number;
    farm: {
      id: number;
      name: string;
      customer: {
        id: number;
        name: string;
      };
    };
  };
};

type DesiccationRequestStatistics = {
  totalAreaHa: number;
  desiccationAreaHa: number;
  economyPercentage: number;
};

function Dashboard(): JSX.Element {
  const dispatch = useDispatch();

  const [file, setFile] = useState<File>();
  const [updateRequestModal, setUpdateRequestModal] = useState({
    open: false,
    request: {} as Request,
    requestStatistics: null as DesiccationRequestStatistics | null,
  });
  const [deleteMapModal, setDeleteMapModal] = useState({
    open: false,
    request: {} as Request,
  });

  const [concludedRequests, setConcludedRequests] = useState<Request[]>([]);
  const [filteredConcludedRequests, setFilteredConcludedRequests] = useState<
    Request[]
  >([]);

  const [pendingRequests, setPendingRequests] = useState<Request[]>([]);
  const [filteredPendingRequests, setFilteredPendingRequests] = useState<
    Request[]
  >([]);

  const typeMap = {
    desiccation: 'Dessecação',
    soil_sampling: 'Amostragem de Solo',
  };

  useEffect(() => {
    const loadRequests = async (): Promise<void> => {
      dispatch(setLoader(true));
      try {
        const { data } = await api.get('admin/map-requests');
        const concluded = data.filter(
          (request: Request) => request.status === 'processed',
        );
        const pending = data.filter(
          (request: Request) => request.status !== 'processed',
        );

        setConcludedRequests(concluded);
        setFilteredConcludedRequests(concluded);

        setPendingRequests(pending);
        setFilteredPendingRequests(pending);
      } catch (e) {
        const errors: ErrorObject[] = getCatchedErrors(e);
        toast.error(
          errors[0].message ||
            'Ocorreu um erro ao carregar as solicitações. Por favor tente novamente mais tarde',
          {
            position: 'bottom-center',
          },
        );
      }
      dispatch(setLoader(false));
    };
    loadRequests();
  }, []);

  useEffect(() => {
    const loadRequestStatistics = async (): Promise<void> => {
      const { request } = updateRequestModal;
      if (!request.desiccationRequest?.id) return;

      dispatch(setLoader(true));
      try {
        const data = new FormData();

        if (file) {
          data.append('zip_file', file);
        }
        data.append('status', 'processed');

        const { data: requestData } = await api.post(
          `admin/desiccation-requests/${request.desiccationRequest.id}/simulate-statistics`,
          data,
        );
        setUpdateRequestModal({
          ...updateRequestModal,
          requestStatistics: requestData,
        });
      } catch (e) {
        const errors: ErrorObject[] = getCatchedErrors(e);
        toast.error(
          errors[0].message ||
            'Ocorreu um erro ao carregar as estatísticas da solicitação. Por favor tente novamente mais tarde',
          {
            position: 'bottom-center',
          },
        );
      }
      dispatch(setLoader(false));
    };

    if (updateRequestModal.open) {
      loadRequestStatistics();
    }
  }, [file]);

  const handleDownloadFile = async (id: number): Promise<void> => {
    if (!id) return;
    dispatch(setLoader(true));

    try {
      const { data }: { data: APIFile } = await api.get(`admin/files/${id}`);
      if (!data.url) return;

      const link = document.createElement('a');
      link.href = data.url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao baixar o arquivo. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleUpdateRequest = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { request } = updateRequestModal;
      const data = new FormData();

      if (file) {
        data.append('zip_file', file);
      }
      data.append('status', 'processed');

      const { data: requestData } = await api.put(
        `admin/map-requests/${request.id}`,
        data,
      );

      request.status = 'processed';
      request.file_id = requestData.file_id;

      setPendingRequests(pendingRequests.filter(req => req.id !== request.id));
      setFilteredPendingRequests(
        filteredPendingRequests.filter(req => req.id !== request.id),
      );

      setConcludedRequests([request, ...concludedRequests]);
      setFilteredConcludedRequests([request, ...filteredConcludedRequests]);

      setUpdateRequestModal({
        open: false,
        request: {} as Request,
        requestStatistics: null,
      });
      setFile(undefined);
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao atualizar o mapa. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleDeleteMap = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { request } = deleteMapModal;

      await api.delete(`admin/map-requests/${request.id}/map-file`);

      setConcludedRequests(
        concludedRequests.filter(req => req.id !== request.id),
      );
      setFilteredConcludedRequests(
        filteredConcludedRequests.filter(req => req.id !== request.id),
      );

      request.status = 'pending';
      setPendingRequests([request, ...pendingRequests]);
      setFilteredPendingRequests([request, ...filteredPendingRequests]);

      setDeleteMapModal({ open: false, request: {} as Request });

      toast.success('Mapa excluído com sucesso!', {
        position: 'bottom-center',
      });
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao excluir o mapa. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleOpenModal = (request: Request): void => {
    if (!request) return;
    setUpdateRequestModal({ ...updateRequestModal, open: true, request });
  };

  const handleCloseModal = (): void => {
    setUpdateRequestModal({
      open: false,
      request: {} as Request,
      requestStatistics: null,
    });

    setFile(undefined);
  };

  const handleOpenDeleteMapModal = (request: Request): void => {
    if (!request) return;
    setDeleteMapModal({ open: true, request });
  };

  const handleCloseDeleteMapModal = (): void => {
    setDeleteMapModal({ open: false, request: {} as Request });
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleFilterPendingRequests = (name: string): void => {
    const value = name.toLowerCase();
    const filteredRequests = pendingRequests.filter(request =>
      request.area.farm.customer.name.toLowerCase().includes(value),
    );
    setFilteredPendingRequests(filteredRequests);
  };

  const handleFilterConcludedRequests = (name: string): void => {
    const value = name.toLowerCase();
    const filteredRequests = concludedRequests.filter(request =>
      request.area.farm.customer.name.toLowerCase().includes(value),
    );
    setFilteredConcludedRequests(filteredRequests);
  };

  return (
    <div className="container-fluid p-4 overflow-auto">
      <div className="row mb-2">
        <div className="col-12">
          <h3 className="text-muted">Solicitações de Mapas</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-4">
          <div className="card">
            <div className="d-flex card-header justify-content-between">
              <h5 className="card-title mt-1">
                Solicitações <span className="pending-status">Pendentes</span>
              </h5>
              <TextField
                icon="fas fa-search"
                placeholder="Filtrar por cliente..."
                size="small"
                onChange={e => {
                  handleFilterPendingRequests(e.target.value);
                }}
                autoComplete="off"
              />
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <BaseTable className="table m-0">
                  <TableHead className="thead-light">
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Cliente</TableCell>
                      <TableCell>Data de Solicitação</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Tipo</TableCell>
                      <TableCell>Fazenda</TableCell>
                      <TableCell>Nome da Área</TableCell>
                      <TableCell>Área (ha)</TableCell>
                      <TableCell>Volume de Calda</TableCell>
                      <TableCell>Monitor</TableCell>
                      <TableCell>KML</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredPendingRequests.map((request: Request, index) => (
                      <TableRow key={request.id}>
                        <TableCell>{index}</TableCell>
                        <TableCell>{request.area.farm.customer.name}</TableCell>
                        <TableCell>
                          {new Date(request.created_at).toLocaleDateString()}
                        </TableCell>
                        <TableCell>{request.name}</TableCell>
                        <TableCell>{typeMap[request.type]}</TableCell>
                        <TableCell>{request.area.farm.name}</TableCell>
                        <TableCell>{request.area.name}</TableCell>
                        <TableCell>
                          {(request.area.total_area_m2 / 10000).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {request.desiccationRequest?.spray_volume || 'N/A'}
                        </TableCell>
                        <TableCell>
                          {request.desiccationRequest?.monitor || 'N/A'}
                        </TableCell>
                        <TableCell>
                          <BaseButton
                            size="sm"
                            color="inherit"
                            onClick={(): void => {
                              handleDownloadFile(request.area.file_id);
                            }}
                            iconOnly
                          >
                            <i className="fas fa-download" />
                          </BaseButton>
                        </TableCell>
                        <TableCell>
                          <BaseButton
                            size="sm"
                            color="inherit"
                            onClick={(): void => {
                              handleOpenModal(request);
                            }}
                          >
                            <i
                              className="fas fa-plus"
                              style={{ marginRight: '10px' }}
                            />{' '}
                            Mapa
                          </BaseButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </BaseTable>
                {!filteredPendingRequests.length && (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: '50px' }}
                  >
                    <h4>
                      <small className="text-muted">
                        Nenhuma solicitação pendente{' '}
                        {pendingRequests.length > 0 && 'encontrada'}
                      </small>
                    </h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="card">
            <div className="d-flex card-header justify-content-between">
              <h5 className="card-title mt-1">
                Solicitações{' '}
                <span className="processed-status">Concluídas</span>
              </h5>
              <TextField
                icon="fas fa-search"
                placeholder="Filtrar por cliente..."
                size="small"
                onChange={e => {
                  handleFilterConcludedRequests(e.target.value);
                }}
                autoComplete="off"
              />
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <BaseTable className="table m-0" textAlign="center">
                  <TableHead className="thead-light">
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Cliente</TableCell>
                      <TableCell>Data de Conclusão</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Tipo</TableCell>
                      <TableCell>Fazenda</TableCell>
                      <TableCell>Nome da Área</TableCell>
                      <TableCell>Área (ha)</TableCell>
                      <TableCell>Volume de Calda</TableCell>
                      <TableCell>Monitor</TableCell>
                      <TableCell>Download KML</TableCell>
                      <TableCell>Download Mapa</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredConcludedRequests.map(
                      (request: Request, index) => (
                        <TableRow key={request.id}>
                          <TableCell>{index}</TableCell>
                          <TableCell>
                            {request.area.farm.customer.name}
                          </TableCell>
                          <TableCell>
                            {new Date(request.created_at).toLocaleDateString()}
                          </TableCell>
                          <TableCell>{request.name}</TableCell>
                          <TableCell>{typeMap[request.type]}</TableCell>
                          <TableCell>{request.area.farm.name}</TableCell>
                          <TableCell>{request.area.name}</TableCell>
                          <TableCell>
                            {(request.area.total_area_m2 / 10000).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {request.desiccationRequest?.spray_volume || 'N/A'}
                          </TableCell>
                          <TableCell>
                            {request.desiccationRequest?.monitor || 'N/A'}
                          </TableCell>
                          <TableCell>
                            <BaseButton
                              size="sm"
                              color="inherit"
                              disabled={request.status !== 'processed'}
                              onClick={(): void => {
                                handleDownloadFile(request.area.file_id);
                              }}
                              iconOnly
                            >
                              <i className="fas fa-download" />
                            </BaseButton>
                          </TableCell>
                          <TableCell>
                            <BaseButton
                              size="sm"
                              color="inherit"
                              disabled={request.status !== 'processed'}
                              onClick={(): void => {
                                handleDownloadFile(request.file_id);
                              }}
                              iconOnly
                            >
                              <i className="fas fa-download" />
                            </BaseButton>
                          </TableCell>
                          <TableCell>
                            <BaseButton
                              id={`undo-request-button-${request.id}`}
                              size="sm"
                              disabled={
                                request.status !== 'processed' ||
                                request.type === 'soil_sampling'
                              }
                              onClick={(): void => {
                                handleOpenDeleteMapModal(request);
                              }}
                              tooltip="Excluir Mapa"
                              iconOnly
                            >
                              <i className="fas fa-trash text-black" />
                            </BaseButton>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </BaseTable>
                {!filteredConcludedRequests.length && (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: '50px' }}
                  >
                    <h4>
                      <small className="text-muted">
                        Nenhuma solicitação concluída{' '}
                        {concludedRequests.length > 0 && 'encontrada'}
                      </small>
                    </h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BaseModal
        size="sm"
        open={updateRequestModal.open}
        onClose={() => {
          handleCloseModal();
        }}
      >
        <div className="modal-body p-3">
          <div className="row">
            <div className="d-flex col-12 text-center flex-column">
              <span className="text-success">
                <i className="fas fa-exclamation fa-4x" />
              </span>
              <b className="text-success mt-2 mb-4">ATENÇÃO!</b>
            </div>
            <div className="col-12 text-center">
              <span className="text-muted">
                Ao salvar o novo mapa, o status da solicitação será atualizado
                para concluído.
              </span>
            </div>
            <div className="d-flex col-12 justify-content-center mt-4">
              <FileInput
                name="zip_file"
                value={file}
                label="Selecione um Arquivo"
                onChange={handleFileChange}
                accept=".zip"
              />
            </div>
            {updateRequestModal.requestStatistics && (
              <div className="col-12 mt-4">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <h5 className="card-title">Estatísticas</h5>
                      </div>
                      <div className="col-12">
                        <div className="d-flex justify-content-between">
                          <span>Área Total: </span>
                          <span>
                            {updateRequestModal.requestStatistics.totalAreaHa.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}{' '}
                            ha
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex justify-content-between">
                          <span>Área de Dessecação:</span>
                          <span>
                            {updateRequestModal.requestStatistics.desiccationAreaHa.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}{' '}
                            ha
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex justify-content-between">
                          <span>Economia:</span>
                          <span>
                            {updateRequestModal.requestStatistics.economyPercentage.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}{' '}
                            %
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer w-100">
          <div className="row">
            <div className="d-flex col-12 justify-content-end align-items-center">
              <span
                className="exit-modal-btn"
                tabIndex={0}
                role="button"
                onKeyUp={() => {}}
                onClick={handleCloseModal}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </span>
              <BaseButton onClick={handleUpdateRequest}>Salvar Mapa</BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>
      <BaseModal
        size="sm"
        open={deleteMapModal.open}
        onClose={() => {
          handleCloseDeleteMapModal();
        }}
      >
        <div className="modal-body p-3">
          <div className="row">
            <div className="d-flex col-12 text-center flex-column">
              <span className="text-danger">
                <i className="fas fa-trash fa-4x" />
              </span>
              <b className="text-danger mt-2 mb-4">ATENÇÃO!</b>
            </div>
            <div className="col-12 text-center">
              <span className="text-muted">
                Ao excluir o mapa atribuído à solicitação, o status da
                solicitação será atualizado para{' '}
                <strong className="text-info">pendente</strong> e você precisará
                salvar um novo mapa.
              </span>
            </div>
          </div>
        </div>
        <div className="modal-footer w-100">
          <div className="row">
            <div className="d-flex col-12 justify-content-end align-items-center">
              <span
                className="exit-modal-btn"
                tabIndex={0}
                role="button"
                onKeyUp={() => {}}
                onClick={handleCloseDeleteMapModal}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </span>
              <BaseButton onClick={handleDeleteMap}>Excluir Mapa</BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>

      <ToastContainer />
    </div>
  );
}

export default Dashboard;
