import React, { InputHTMLAttributes, useState, useRef } from 'react';
import ReactInputMask from 'react-input-mask';

import {
  TextField as MuiField,
  Box,
  InputAdornment,
  IconButton,
} from '@mui/material';

interface TextFieldProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  label?: string;
  icon?: string;
  mask?: string;
  error?: boolean;
  helperText?: string;
  showPasswordToggle?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  size?: 'small' | 'medium';
}

function TextField({
  placeholder,
  name,
  value,
  label,
  icon,
  type,
  mask,
  error,
  helperText,
  disabled,
  required,
  showPasswordToggle,
  autoComplete,
  startAdornment,
  endAdornment,
  size,
  onChange,
  onKeyUp,
}: TextFieldProps): JSX.Element {
  const inputMaskRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);

  const getType = (): string | undefined => {
    if (type === 'password' && showPassword) {
      return 'text';
    }

    return type;
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <ReactInputMask
        mask={mask || ''}
        value={value}
        autoComplete={autoComplete}
        onChange={onChange}
        ref={inputMaskRef}
        disabled={disabled}
      >
        <MuiField
          name={name}
          className="text-field"
          style={{ width: '100%', borderRadius: '5px', margin: '0' }}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              fontWeight: 'bold',
            },
          }}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
          label={label}
          type={getType()}
          size={size}
          InputProps={{
            startAdornment: startAdornment ? (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : (
              <InputAdornment position="start">
                <i className={icon} />
              </InputAdornment>
            ),
            endAdornment: showPasswordToggle ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <i className="fas fa-eye" style={{ fontSize: '20px' }} />
                  ) : (
                    <i
                      className="fas fa-eye-slash"
                      style={{ fontSize: '20px' }}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ) : (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
          }}
          component={mask ? undefined : undefined}
          error={error}
          helperText={helperText}
          required={required}
        />
      </ReactInputMask>
    </Box>
  );
}

TextField.defaultProps = {
  label: '',
  icon: undefined,
  mask: '',
  error: false,
  helperText: '',
  showPasswordToggle: false,
  startAdornment: undefined,
  endAdornment: undefined,
  size: 'medium',
};

export default TextField;
