import React from 'react';
import './BaseBadge.css';

import { Tooltip } from 'react-tooltip';

interface BaseBadgeProps {
  children?: React.ReactNode;
  type?: 'success' | 'warning' | 'danger' | 'info';
  size?: 'sm' | 'md' | 'lg';
  tooltip?: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
}

function BaseBadge({
  type,
  size,
  children,
  tooltip,
  tooltipPosition,
}: BaseBadgeProps): JSX.Element {
  const id = Math.random().toString(36).substring(7);
  return (
    <span>
      <span id={id} className={`base-badge ${type} ${size}`}>
        {children}
      </span>
      {tooltip && (
        <Tooltip anchorSelect={`#${id}`} place={tooltipPosition}>
          {tooltip}
        </Tooltip>
      )}
    </span>
  );
}

BaseBadge.defaultProps = {
  size: 'md',
  type: 'info',
  children: [],
  tooltip: '',
  tooltipPosition: 'top',
};

export default BaseBadge;
