import React from 'react';
import './InfoPin.css';

import { Tooltip } from 'react-tooltip';

interface InfoPinProps {
  size?: 'small' | 'medium' | 'large';
  tooltip?: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
}

function InfoPin({
  size,
  tooltip,
  tooltipPosition,
}: InfoPinProps): JSX.Element {
  const infoPinId = `info-pin-${Math.random().toString(36).substring(7)}`;
  return (
    <div className="info-pin-container">
      <div id={infoPinId} className={`info-pin ${size}`}>
        <i className="fas fa-info" />
      </div>
      {tooltip && (
        <Tooltip anchorSelect={`#${infoPinId}`} place={tooltipPosition}>
          {tooltip}
        </Tooltip>
      )}
    </div>
  );
}

InfoPin.defaultProps = {
  size: 'medium',
  tooltip: '',
  tooltipPosition: 'top',
};

export default InfoPin;
