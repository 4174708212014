import React, { useState } from 'react';
import './ProfilePicture.css';

import { BaseModal, BaseButton, FileInput } from 'components';

interface ProfilePictureProps {
  src?: string;
  size?: 'small' | 'medium' | 'large';
  editable?: boolean;
  onUpdateProfilePic?: (
    profilePicture: File | null,
    closeModal: () => void,
  ) => void;
}

function ProfilePicture({
  src,
  size,
  editable,
  onUpdateProfilePic,
}: ProfilePictureProps): JSX.Element {
  const [image, setImage] = useState<File | null>(null);
  const [updatePicModal, setUpdatePicModal] = useState<boolean>(false);

  return (
    <div className={`profilepic-container ${size}`}>
      <div
        className={`profilepic ${editable ? 'editable' : ''}`}
        onClick={
          editable
            ? () => {
                setUpdatePicModal(true);
              }
            : undefined
        }
        tabIndex={0}
        role="button"
        onKeyUp={() => {}}
      >
        <img
          className={`profilepic-image ${size}`}
          src={src || '/blank-profile-picture.png'}
          alt="Foto de perfil (clique para editar)"
        />
        {editable && (
          <div className="profilepic-content">
            <span className="profilepic-icon">
              <i className="fas fa-camera" />
            </span>
            <span className="profilepic-text">Editar</span>
          </div>
        )}
      </div>
      <BaseModal
        size="sm"
        open={updatePicModal}
        onClose={() => {
          setUpdatePicModal(false);
          setImage(null);
        }}
      >
        <div className="modal-body p-3">
          <div className="row">
            <div className="d-flex col-12 text-center flex-column">
              <span className="text-success">
                <i className="fas fa-edit fa-4x" />
              </span>
              <b className="text-success mt-2 mb-4">Atualizar Foto de Perfil</b>
            </div>
            <div className="col-12 text-center">
              <FileInput
                value={image}
                label="Selecione uma imagem"
                name="image"
                accept=".jpg, .jpeg, .png"
                onChange={e => {
                  if (e.target.files) {
                    setImage(e.target.files[0]);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="row">
            <div className="d-flex col-12 justify-content-end align-items-center">
              <span
                className="exit-modal-btn"
                tabIndex={0}
                role="button"
                onKeyUp={() => {}}
                onClick={() => {
                  setUpdatePicModal(false);
                  setImage(null);
                }}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </span>
              <BaseButton
                onClick={() => {
                  if (onUpdateProfilePic)
                    onUpdateProfilePic(image, () => {
                      setUpdatePicModal(false);
                    });
                }}
              >
                Salvar Imagem
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>
    </div>
  );
}

ProfilePicture.defaultProps = {
  src: '',
  size: 'medium',
  editable: false,
  onUpdateProfilePic: () => {},
};

export default ProfilePicture;
