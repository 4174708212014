import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './RequestsList.css';

// eslint-disable-next-line import/no-unresolved
import { GeoJsonObject } from 'geojson';
import { InteractiveMap, FloatingDialog, BaseButton } from 'components';

import api from 'services/api';
import { getCatchedErrors, ErrorObject } from 'helpers/apiHelper';
import { toast, ToastContainer } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

import { Area } from 'views/Customer/Area/AreaList/AreaSidebar/AreaItem';

import RequestsSidebar from './RequestsSidebar/RequestsSidebar';
import { MapRequest } from './RequestsSidebar/RequestItem';

function RequestsList(): JSX.Element {
  const { farmId, areaId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [area, setArea] = useState<Area>();
  const [selectedRequest, setSelectedRequest] = useState<MapRequest | null>(
    null,
  );

  const [tabsPagination, setTabsPagination] = useState({
    desiccation: {
      currPage: 1,
      lastPage: false,
      filter: '',
      isLoading: false,
    },
    soil_sampling: {
      currPage: 1,
      lastPage: false,
      filter: '',
      isLoading: false,
    },
    selective_spraying: {
      currPage: 1,
      lastPage: false,
      filter: '',
      isLoading: false,
    },
  });

  const [requests, setRequests] = useState<{
    desiccation: MapRequest[];
    soil_sampling: MapRequest[];
    selective_spraying: MapRequest[];
  }>({
    desiccation: [],
    soil_sampling: [],
    selective_spraying: [],
  });

  const [markerMessage, setMarkerMessage] = useState<string>('Spray Solutions');
  const [showMarker, setShowMarker] = useState<boolean>(true);
  const [mapZoom, setMapZoom] = useState<number>(16);
  const [mapCenter, setMapCenter] = useState<[number, number]>([
    -29.711025188644363, -53.71634781360627,
  ]);
  const [mapGeoJsonLayer, setMapGeoJsonLayer] = useState<GeoJsonObject | null>(
    null,
  );

  const handleLoadArea = async (): Promise<void> => {
    try {
      const { data } = await api.get(
        `customer/farms/${farmId}/areas/${areaId}`,
      );
      setArea(data);

      if (data) {
        const coordinates = JSON.parse(data.center_coordinates);
        setMapCenter([coordinates[1], coordinates[0]]);
        setMapGeoJsonLayer(JSON.parse(data.geojson));
        setMarkerMessage(
          `${data.name}: ${(data.total_area_m2 / 10000).toFixed(2)} ha`,
        );
        setShowMarker(true);
        setMapZoom(15);
      }
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao carregar a área. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
  };

  const loadRequests = async (
    page: number,
    type: 'desiccation' | 'soil_sampling' | 'selective_spraying',
  ): Promise<void> => {
    const perPage = 15;
    const { filter } = tabsPagination[type];

    setTabsPagination(prev => ({
      ...prev,
      [type]: { ...prev[type], isLoading: true },
    }));
    try {
      const { data } = await api.get(
        `customer/farms/${farmId}/areas/${areaId}/map-requests?type=${type}&page=${page}&perPage=${perPage}&requestName=${filter}`,
      );

      const { requests: resquestsData, lastPage } = data;
      setTabsPagination(prev => ({
        ...prev,
        [type]: { ...prev[type], lastPage },
      }));

      setRequests(prev => ({
        ...prev,
        [type]: page === 1 ? resquestsData : [...prev[type], ...resquestsData],
      }));
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao carregar as solicitações. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
    setTabsPagination(prev => ({
      ...prev,
      [type]: { ...prev[type], isLoading: false },
    }));
  };

  useEffect(() => {
    handleLoadArea();
  }, []);

  useEffect(() => {
    const { currPage, lastPage } = tabsPagination.desiccation;
    if (!lastPage) loadRequests(currPage, 'desiccation');
  }, [tabsPagination.desiccation.currPage, tabsPagination.desiccation.filter]);

  useEffect(() => {
    const { currPage, lastPage } = tabsPagination.soil_sampling;
    if (!lastPage) loadRequests(currPage, 'soil_sampling');
  }, [
    tabsPagination.soil_sampling.currPage,
    tabsPagination.soil_sampling.filter,
  ]);

  useEffect(() => {
    const { currPage, lastPage } = tabsPagination.selective_spraying;
    if (!lastPage) loadRequests(currPage, 'selective_spraying');
  }, [
    tabsPagination.selective_spraying.currPage,
    tabsPagination.selective_spraying.filter,
  ]);

  const handlePagination = (
    type: 'desiccation' | 'soil_sampling' | 'selective_spraying',
  ): void => {
    const { lastPage } = tabsPagination[type];
    if (!lastPage) {
      setTabsPagination(prev => ({
        ...prev,
        [type]: {
          ...prev[type],
          currPage: prev[type].currPage + 1,
        },
      }));
    }
  };

  const handleDownloadMapFile = async (id: number): Promise<void> => {
    if (!id) return;

    dispatch(setLoader(true));
    try {
      const { data }: { data: APIFile } = await api.get(
        `customer/farms/${farmId}/areas/${areaId}/map-requests/${id}/map-file`,
      );
      if (!data.url) return;

      const link = document.createElement('a');
      link.href = data.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao baixar o arquivo. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleDeleteMapRequest = async (
    requestId: number,
    requestType: 'desiccation' | 'soil_sampling' | 'selective_spraying',
    callback: () => void,
  ): Promise<void> => {
    dispatch(setLoader(true));
    try {
      await api.delete(
        `customer/farms/${farmId}/areas/${areaId}/map-requests/${requestId}`,
      );

      setRequests(prev => ({
        ...prev,
        [requestType]: prev[requestType].filter(r => r.id !== requestId),
      }));
      if (selectedRequest?.id === requestId) {
        setSelectedRequest(null);
        setMapGeoJsonLayer(JSON.parse(area?.geojson || '') || null);
      }
      callback();
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao excluir a solicitação. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleShowRequestMap = (
    requestId: number,
    requestType: 'desiccation' | 'soil_sampling' | 'selective_spraying',
  ): void => {
    const request = requests[requestType].find(r => r.id === requestId);
    if (request?.status === 'processed') setSelectedRequest(request || null);
    if (request && request.status === 'processed') {
      setMapGeoJsonLayer(JSON.parse(request.geojson));
      setMapZoom(15);
      setMarkerMessage('');
      setShowMarker(false);
    }
  };

  const getHeaderSubtitle = (): string => {
    return `${area?.name || 'Carregando...'}, ${(
      (area?.total_area_m2 || 0) / 10000
    ).toFixed(2)} ha`;
  };

  const getTabsProps = (): { isLoading: boolean; lastPage: boolean }[] => {
    const keys = Object.keys(tabsPagination) as Array<
      keyof typeof tabsPagination
    >;

    return keys.map(key => {
      return {
        isLoading: tabsPagination[key].isLoading,
        lastPage: tabsPagination[key].lastPage,
      };
    });
  };

  return (
    <div className="d-flex container-fluid h-100 p-0 flex-column flex-lg-row">
      <div className="d-none d-lg-block request-list-sidebar">
        <div className="sidebar-header">
          <div
            className="icon-button"
            tabIndex={0}
            role="button"
            onKeyUp={() => {}}
            onClick={() => {
              navigate(`/customer/farms/${farmId}/areas`);
            }}
          >
            <i className="fas fa-arrow-left" /> Áreas
          </div>
          <div className="d-flex flex-column text-center">
            <span className="title">Mapas Solicitados</span>
            <span className="subtitle">{getHeaderSubtitle()}</span>
          </div>
        </div>
        <RequestsSidebar
          type="desktop"
          farmId={Number(farmId)}
          areaId={Number(areaId)}
          requests={requests}
          tabsProps={getTabsProps()}
          onSelectRequest={handleShowRequestMap}
          onDeleteRequest={handleDeleteMapRequest}
          onPaginate={handlePagination}
          onChangeTab={() => {
            setSelectedRequest(null);
            setMapGeoJsonLayer(JSON.parse(area?.geojson || '') || null);
          }}
        />
      </div>
      <div className="request-list-map-container">
        <div className="d-lg-none request-list-map-container__header">
          <div className="d-flex text-center justify-content-between">
            <div
              className="icon-button my-auto pe-2"
              tabIndex={0}
              role="button"
              onKeyUp={() => {}}
              onClick={() => navigate(`/customer/farms/${farmId}/areas`)}
            >
              <i className="fas fa-arrow-left" style={{ marginRight: '5px' }} />{' '}
              Áreas
            </div>

            <span className="text-truncate text-muted">
              {getHeaderSubtitle()}
            </span>
          </div>
        </div>
        {selectedRequest?.status === 'processed' && (
          <>
            <FloatingDialog
              className="d-none d-lg-flex"
              position="left-bottom"
              size="sm"
              key={`floating_dialog_${selectedRequest.id}`}
              closable={false}
            >
              <div className="row">
                <div className="col-12">
                  <h5 className="text-center">
                    <i
                      className="fas fa-download"
                      style={{ marginRight: '5px' }}
                    />{' '}
                    Download do mapa atual
                  </h5>
                </div>
                <div className="col-12 text-center">
                  <BaseButton
                    size="lg"
                    onClick={() => {
                      if (selectedRequest) {
                        handleDownloadMapFile(selectedRequest.id);
                      }
                    }}
                  >
                    Fazer Download
                  </BaseButton>
                </div>
              </div>
            </FloatingDialog>
            <div
              className="d-lg-none position-absolute bottom-0 start-0 mb-4"
              style={{ zIndex: 100 }}
            >
              <BaseButton
                size="md"
                onClick={() => {
                  if (selectedRequest) {
                    handleDownloadMapFile(selectedRequest.id);
                  }
                }}
              >
                Fazer Download
              </BaseButton>
            </div>
          </>
        )}
        <InteractiveMap
          center={mapCenter}
          markerPosition={mapCenter}
          popupMessage={markerMessage}
          zoom={mapZoom}
          showMarker={showMarker}
          geoJson={mapGeoJsonLayer}
        />
        <div className="d-lg-none">
          <RequestsSidebar
            className="d-flex d-lg-none"
            type="mobile"
            farmId={Number(farmId)}
            areaId={Number(areaId)}
            requests={requests}
            tabsProps={getTabsProps()}
            onSelectRequest={handleShowRequestMap}
            onDeleteRequest={handleDeleteMapRequest}
            onPaginate={handlePagination}
            onChangeTab={() => {
              setSelectedRequest(null);
              setMapGeoJsonLayer(JSON.parse(area?.geojson || '') || null);
            }}
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default RequestsList;
