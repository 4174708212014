import React from 'react';
import { motion } from 'framer-motion';
import LoadingOverlay from 'react-loading-overlay-ts';

import { useSelector } from 'react-redux';
import { State } from 'store';

import NavBar from './NavBar';
import './GuestLayout.css';

type GuestLayoutProps = {
  children: React.ReactNode;
};

function GuestLayout({ children }: GuestLayoutProps): JSX.Element {
  const isLoading = useSelector((state: State) => state.isLoading);
  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text="Carregando..."
      styles={{
        overlay: base => ({
          ...base,
          zIndex: 99999,
        }),
      }}
    >
      <div style={{ height: '100vh' }}>
        <NavBar />
        <main style={{ height: '100%' }}>
          <motion.div
            className="page-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {children}
          </motion.div>
        </main>
      </div>
    </LoadingOverlay>
  );
}

export default GuestLayout;
