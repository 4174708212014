import React from 'react';
import './RequestItem.css';
import { Area } from 'views/Customer/Area/AreaList/AreaSidebar/AreaItem';
import { BaseButton } from 'components';

export type MapRequest = {
  id: number;
  name: string;
  area_id: number;
  file_id: number;
  geojson: string;
  status: 'pending' | 'processed' | 'canceled';
  created_at: string;
  type: 'desiccation' | 'soil_sampling' | 'selective_spraying';
  area: Area;
  desiccationRequest?: {
    desiccation_area_m2: number;
  };
};

interface RequestItemProps {
  requestData: MapRequest;
  index: number;
  onClick?: () => void;
  onDelete?: (id: number) => void;
}

function RequestItem({
  requestData,
  index,
  onClick,
  onDelete,
}: RequestItemProps): JSX.Element {
  const statusMap = {
    pending: 'Analisando',
    processed: 'Concluído',
    canceled: 'Cancelado',
  };

  const typeMap = {
    desiccation: 'Dessecação',
    soil_sampling: 'Amostragem de Solo',
    selective_spraying: 'Pulverização Seletiva',
  };

  const toHa = (areaM2: number): string => {
    if (!areaM2) return 'Em análise';
    return `${(areaM2 / 10000).toFixed(2)} ha`;
  };

  const savedAreaPercent = (areaM2: number): string => {
    if (!areaM2) return 'Em análise';
    return `${(100 - (areaM2 / requestData.area.total_area_m2) * 100).toFixed(0)}%`;
  };

  return (
    <div
      className="container-fluid request-item"
      onClick={onClick}
      tabIndex={0}
      role="button"
      onKeyUp={() => {}}
    >
      <div className="row h-100">
        <div className="col-2 item-icon text-center default-green">{index}</div>
        <div className="col-8 item-info">
          <div className="row h-100">
            <div className="d-flex col-12 flex-column ">
              <div className="d-flex flex-row justify-content-between">
                <h5>{requestData.name}</h5>
              </div>
              <span>
                Status:{' '}
                <span className={`${requestData.status}-status`}>
                  {statusMap[requestData.status]}
                </span>
              </span>
              <span>
                Tipo: <span>{typeMap[requestData.type]}</span>
              </span>
              {requestData.type === 'desiccation' && (
                <span>
                  Pulverizar:{' '}
                  <span>
                    {toHa(
                      requestData.desiccationRequest?.desiccation_area_m2 || 0,
                    )}
                  </span>
                </span>
              )}
              {requestData.type === 'desiccation' && (
                <span>
                  Economia:{' '}
                  <span>
                    {savedAreaPercent(
                      requestData.desiccationRequest?.desiccation_area_m2 || 0,
                    )}
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex col-2 flex-column justify-content-end align-items-end p-0">
          <span className="text-muted text-end" style={{ marginRight: '10px' }}>
            <small>
              {new Date(requestData.created_at).toLocaleDateString()}
            </small>
          </span>
          <div className="mt-auto">
            <BaseButton
              size="sm"
              iconOnly
              onClick={e => {
                if (onDelete) onDelete(requestData.id);
                e.stopPropagation();
              }}
              disabled={requestData.status !== 'processed'}
            >
              <i className="fas fa-trash text-black" />
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  );
}

RequestItem.defaultProps = {
  onClick: () => {},
  onDelete: () => {},
};

export default RequestItem;
