import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './AreaList.css';

// eslint-disable-next-line import/no-unresolved
import { GeoJsonObject } from 'geojson';
import { InteractiveMap } from 'components';

import api from 'services/api';
import { getCatchedErrors, ErrorObject } from 'helpers/apiHelper';
import { toast, ToastContainer } from 'react-toastify';

import { Farm } from 'views/Customer/Farm/FormSidebar';

import AreaSidebar from './AreaSidebar/AreaSidebar';
import { Area } from './AreaSidebar/AreaItem';

function AreaList(): JSX.Element {
  const navigate = useNavigate();
  const { farmId } = useParams();

  const [farm, setFarm] = useState<Farm>();
  const [areas, setAreas] = useState<Area[]>([]);

  const [currPage, setCurrPage] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [loadingAreas, setLoadingAreas] = useState(false);

  const [mapZoom, setMapZoom] = useState<number>(17);
  const [mapCenter, setMapCenter] = useState<[number, number]>([
    -29.711025188644363, -53.71634781360627,
  ]);
  const [mapGeoJsonLayer, setMapGeoJsonLayer] = useState<GeoJsonObject | null>(
    null,
  );

  const handleLoadFarm = async (): Promise<void> => {
    try {
      const { data } = await api.get(`customer/farms/${farmId}`);
      setFarm(data);
      if (data?.lat && data?.lng) setMapCenter([data.lat, data.lng]);
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao carregar a fazenda. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
  };

  const loadAreas = async (page: number): Promise<void> => {
    const perPage = 15;
    setLoadingAreas(true);
    try {
      const { data } = await api.get(
        `customer/farms/${farmId}/areas?page=${page}&perPage=${perPage}`,
      );

      setAreas(prev => (page === 1 ? data.areas : [...prev, ...data.areas]));
      setLastPage(data.lastPage);
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao carregar as áreas. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
    setLoadingAreas(false);
  };

  useEffect(() => {
    handleLoadFarm();
  }, []);

  useEffect(() => {
    if (!lastPage) loadAreas(currPage);
  }, [currPage]);

  const getHeaderSubtitle = (): string => {
    const totalArea =
      areas.reduce((acc, cur) => acc + cur.total_area_m2, 0) / 10000;

    return `${farm?.name || 'Carregando...'}, ${totalArea.toFixed(0)} ha`;
  };

  const handleOpenRequests = (areaId: number): void => {
    navigate(`/customer/farms/${farmId}/areas/${areaId}/requests`);
  };

  const handleCenterArea = (area: Area | undefined): void => {
    if (area) {
      const coordinates = JSON.parse(area.center_coordinates);
      const geojson = JSON.parse(area.geojson);

      setMapCenter([coordinates[1], coordinates[0]]);
      setMapGeoJsonLayer(geojson);
      setMapZoom(15);
    }
  };

  const handleCenterAreaById = (areaId: number): void => {
    const area = areas.find(_area => _area.id === areaId);
    handleCenterArea(area);
  };

  return (
    <div className="d-flex container-fluid h-100 p-0 flex-column flex-lg-row">
      <div className="d-none d-lg-block area-list-sidebar">
        <div className="sidebar-header">
          <div
            className="icon-button"
            tabIndex={0}
            role="button"
            onKeyUp={() => {}}
            onClick={() => navigate('/customer/farms')}
          >
            <i className="fas fa-arrow-left" /> Fazendas
          </div>
          <div className="d-flex flex-column text-center">
            <span className="title">Áreas</span>
            <span className="subtitle">{getHeaderSubtitle()}</span>
          </div>
        </div>
        <AreaSidebar
          type="desktop"
          areas={areas}
          setAreas={setAreas}
          farmId={Number(farmId)}
          loaderInfo={{ isLoading: loadingAreas, lastPage }}
          onSelectArea={handleOpenRequests}
          onCenterArea={handleCenterAreaById}
          onStoreArea={handleCenterArea}
          onPaginate={() => {
            if (!lastPage) setCurrPage(prev => prev + 1);
          }}
        />
      </div>
      <div className="area-list-map-container">
        <div className="d-lg-none request-list-map-container__header">
          <div className="d-flex text-center justify-content-between">
            <div
              className="icon-button my-auto pe-2"
              tabIndex={0}
              role="button"
              onKeyUp={() => {}}
              onClick={() => navigate('/customer/farms')}
            >
              <i className="fas fa-arrow-left" style={{ marginRight: '5px' }} />{' '}
              Fazendas
            </div>

            <span className="text-truncate text-muted">
              {getHeaderSubtitle()}
            </span>
          </div>
        </div>
        <InteractiveMap
          center={mapCenter}
          markerPosition={mapCenter}
          zoom={mapZoom}
          geoJson={mapGeoJsonLayer}
          showMarker
        />
      </div>
      <div className="d-lg-none">
        <AreaSidebar
          className="d-block d-lg-none"
          type="mobile"
          areas={areas}
          setAreas={setAreas}
          farmId={Number(farmId)}
          loaderInfo={{ isLoading: loadingAreas, lastPage }}
          onSelectArea={handleOpenRequests}
          onCenterArea={handleCenterAreaById}
          onStoreArea={handleCenterArea}
          onPaginate={() => {
            if (!lastPage) setCurrPage(prev => prev + 1);
          }}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default AreaList;
