import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './FormSidebar.css';

import { BaseInput, BaseButton, BottomSheet } from 'components';

export interface Farm {
  id: number;
  name: string;
  city: string;
  owner: string;
  lat: number;
  lng: number;
  customer_id: number;
}

interface FormSidebarProps {
  className?: string;
  type?: 'desktop' | 'mobile';
  formType: 'form' | 'edit';
  farmLocation?: [number, number];
  farm?: Farm;
  onStoreFarm?: (data: Farm) => void;
  onEditFarm?: (data: Farm) => void;
  onChangeLocation?: (city: string) => void;
}

function FormSidebar({
  className,
  type,
  formType,
  farmLocation,
  farm,
  onStoreFarm,
  onEditFarm,
  onChangeLocation,
}: FormSidebarProps): JSX.Element {
  const navigate = useNavigate();

  const [bsOpen, setBsOpen] = useState(true);
  const [formData, setFormData] = useState<Farm>({
    id: 0,
    name: '',
    city: '',
    owner: '',
    lat: 0,
    lng: 0,
    customer_id: 0,
  } as Farm);

  useEffect(() => {
    if (formType === 'edit' && farm?.id) {
      setFormData({ ...farm });
    }
  }, [farm, formType]);

  useEffect(() => {
    if (farmLocation && formType === 'form') {
      setFormData(data => ({
        ...data,
        lat: farmLocation[0],
        lng: farmLocation[1],
      }));
    }
  }, [farmLocation, formType]);

  const handleSaveFarm = (): void => {
    if (formType === 'form') {
      if (onStoreFarm) onStoreFarm(formData);
    } else if (formType === 'edit') {
      if (onEditFarm) onEditFarm(formData);
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  return (
    <div className="container-fluid h-100 p-0">
      {type === 'desktop' ? (
        <div className="form-sidebar-container">
          <div className="sidebar-header">
            <div
              className="icon-button"
              tabIndex={0}
              role="button"
              onKeyUp={() => {}}
              onClick={() => {
                navigate('/customer/farms');
              }}
            >
              <i className="fas fa-arrow-left" /> Voltar
            </div>
            <div className="d-flex flex-column text-center">
              <span className="title">
                {formType === 'form' ? 'Nova Fazenda' : 'Editar Fazenda'}
              </span>
              <span className="subtitle">
                {formType === 'form'
                  ? 'Informe os dados da fazenda'
                  : 'Edite os dados da fazenda'}
              </span>
            </div>
          </div>
          <div className="sidebar-body">
            <div className="row w-100 mt-2">
              <div className="col-12">
                <BaseInput
                  name="name"
                  label="Nome da Fazenda"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-12 mt-4">
                <BaseInput
                  name="owner"
                  label="Proprietário"
                  value={formData.owner}
                  onChange={handleInputChange}
                />
              </div>
              {formType === 'form' && (
                <div className="d-flex col-12 mt-4 flex-row align-items-center">
                  <BaseInput
                    name="city"
                    label="Cidade"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                  <span
                    style={{
                      marginLeft: '20px',
                      position: 'relative',
                    }}
                  >
                    <BaseButton
                      id="centring-map-button"
                      size="sm"
                      tooltip="Centralizar no Mapa"
                      onClick={() => {
                        if (onChangeLocation) onChangeLocation(formData.city);
                      }}
                      tooltipPosition="top"
                    >
                      <span className="px-4">
                        <i className="fas fa-map-marker-alt" />
                      </span>
                    </BaseButton>
                  </span>
                </div>
              )}
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <BaseButton
                  id="save-farm-button"
                  size="lg"
                  tooltip="Salvar Fazenda"
                  onClick={handleSaveFarm}
                >
                  <i className="fas fa-save" style={{ marginRight: '5px' }} />{' '}
                  {formType === 'form' ? 'Salvar Fazenda' : 'Salvar Alterações'}
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <BottomSheet
          title={formType === 'form' ? 'Nova Fazenda' : 'Editar Fazenda'}
          className={className}
          open={bsOpen}
          onChange={open => setBsOpen(open)}
          initialState="middle-opened"
        >
          <div className="bottom-sheet-content">
            <div className="row w-100 mt-2">
              <div className="col-12">
                <BaseInput
                  name="name"
                  label="Nome da Fazenda"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-12 mt-4">
                <BaseInput
                  name="owner"
                  label="Proprietário"
                  value={formData.owner}
                  onChange={handleInputChange}
                />
              </div>
              {formType === 'form' && (
                <div className="d-flex col-12 mt-4 flex-row align-items-center">
                  <BaseInput
                    name="city"
                    label="Cidade"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                  <span
                    style={{
                      marginLeft: '20px',
                      position: 'relative',
                    }}
                  >
                    <BaseButton
                      id="centring-map-button"
                      size="xs"
                      tooltip="Centralizar no Mapa"
                      onClick={() => {
                        if (onChangeLocation) onChangeLocation(formData.city);
                        setBsOpen(false);
                      }}
                      tooltipPosition="top"
                    >
                      <span className="px-4">
                        <i className="fas fa-map-marker-alt" />
                      </span>
                    </BaseButton>
                  </span>
                </div>
              )}
            </div>
            <div className="row w-100 mt-5">
              <div className="d-flex col-12 justify-content-center">
                <BaseButton
                  id="save-farm-button"
                  size="md"
                  tooltip="Salvar Fazenda"
                  onClick={handleSaveFarm}
                >
                  <i className="fas fa-save" style={{ marginRight: '5px' }} />{' '}
                  {formType === 'form' ? 'Salvar Fazenda' : 'Salvar Alterações'}
                </BaseButton>
              </div>
            </div>
          </div>
        </BottomSheet>
      )}
    </div>
  );
}

FormSidebar.defaultProps = {
  className: '',
  type: 'desktop',
  farmLocation: null,
  onStoreFarm: null,
  onEditFarm: null,
  onChangeLocation: null,
  farm: null,
};

export default FormSidebar;
