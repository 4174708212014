import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';

import { Box } from '@mui/material';
import {
  TabPanel,
  Tab,
  Tabs,
  TextField,
  BaseButton,
  BaseInput,
} from 'components';

import api from 'services/api';
import { getCatchedErrors, ErrorObject } from 'helpers/apiHelper';
import { ToastContainer, toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

function ForgotPassword(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<number>(0);

  const [requestId, setRequestId] = useState<number>(0);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const [counter, setCounter] = useState(60);
  const [counterRuning, setCounterRuning] = useState(true);

  useEffect(() => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/;

    if (newPassword === '' || passwordRegex.test(newPassword)) {
      setError('');
    } else {
      setError(
        'A nova senha deve conter no mínimo 8 caracteres, 1 letra maiúscula, 1 número e 1 caractere especial (@$!%*?&-)',
      );
    }
  }, [newPassword]);

  const startCounter = (): void => {
    setCounterRuning(true);
    const interval = setInterval(() => {
      setCounter(prevCounter => prevCounter - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
      setCounterRuning(false);
      setCounter(60);
    }, 60000);
  };

  const cancelPhoneVerification = async (): Promise<void> => {
    if (requestId !== 1) return;
    try {
      await api.post('/reset-password/cancel', { id: requestId, email });
      toast.error(
        'Você cancelou a alteração de telefone. Você poderá tentar novamente em 30s',
        {
          position: 'bottom-center',
        },
      );
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao cancelar o código. Por favor tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
  };

  const resendPhoneVerification = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      await api.post('/reset-password/cancel', { id: requestId, email });
      const { data } = await api.post('/reset-password/phone/send', { email });

      setRequestId(data.id);
      startCounter();
      toast.success('Código reenviado com sucesso!', {
        position: 'bottom-center',
      });
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message || 'Ocorreu um erro ao reenviar o código.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleFinishStep1 = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { data } = await api.post('/reset-password/phone/send', { email });
      setRequestId(data.id);
      setCurrentTab(1);
      startCounter();
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao verificar o email. Por favor tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleFinishStep2 = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      await api.post('/reset-password/verify', {
        id: requestId,
        token,
      });

      setCurrentTab(2);
      toast.success('Código verificado com sucesso!', {
        position: 'bottom-center',
      });
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao verificar o código. Por favor tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleResetPassword = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      await api.post('/reset-password/reset', {
        id: requestId,
        token,
        password: newPassword,
        confirm_password: confirmPassword,
      });

      toast.success('Senha alterada com sucesso!', {
        position: 'bottom-center',
      });

      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao alterar a senha. Por favor tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  return (
    <div className="d-flex container-fluid justify-content-center">
      <div className="forgot-password-card card p-4 mt-4">
        <div
          className="d-flex custom-header card-header"
          style={{ flexDirection: 'column' }}
        >
          <div className="d-flex justify-content-center align-items-center gap-2">
            <a href="/">
              <img
                className="aufarm-logo"
                alt="Logo da aufarm"
                src="/aufarm_logo_dark.png"
              />
            </a>
            <div className="sub-info-container">
              <p className="m-0 mt-1">Recuperação de Senha</p>
            </div>
          </div>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
            <Tabs
              value={currentTab}
              onChange={(event, newValue) => setCurrentTab(newValue)}
              variant="fullWidth"
            >
              <Tab
                label="Passo 1"
                id="simple-tab-1"
                disabled={currentTab !== 0}
              />
              <Tab
                label="Passo 2"
                id="simple-tab-2"
                disabled={currentTab !== 1}
              />
              <Tab
                label="Passo 3"
                id="simple-tab-3"
                disabled={currentTab !== 2}
              />
            </Tabs>
          </Box>
        </div>
        <div className="card-body pt-0 scrollable-y">
          <TabPanel value={currentTab} index={0}>
            <div className="row">
              <div className="col-12">
                <p className="fs-6">
                  Digite seu e-mail para recuperar a senha:
                </p>
              </div>
              <div className="col-12">
                <TextField
                  name="email"
                  label="Email."
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="col-12 mt-4 text-center">
                <BaseButton onClick={handleFinishStep1}>Continuar</BaseButton>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <div className="d-flex row justify-content-center">
              <div
                className="card px-0"
                style={{ borderRadius: 0, maxWidth: '700px' }}
              >
                <div className="card-header text-center p-3">
                  <h5>
                    <i
                      className="fas fa-phone default-green"
                      style={{ marginRight: '10px' }}
                    />
                    <span>Verificação de telefone</span>
                  </h5>
                  <p>
                    Caso haja uma conta vinculada ao e-mail informado, você
                    receberá um código de verificação no telefone cadastrado.
                  </p>
                  <p>
                    Caso tenha certeza que o e-mail está correto, mas não
                    recebeu o código, clique em reenviar código.
                  </p>
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="custom-counter">
                      00:{String(counter).padStart(2, '0')}
                    </span>

                    <BaseButton
                      size="sm"
                      onClick={resendPhoneVerification}
                      disabled={counterRuning}
                    >
                      Reenviar Código
                    </BaseButton>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="d-flex col-12 justify-content-center">
                      <div style={{ maxWidth: '200px' }}>
                        <BaseInput
                          name="code"
                          value={token}
                          label="Código"
                          placeholder="AABBCC"
                          onChange={e => setToken(e.target.value.toUpperCase())}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex card-footer justify-content-center">
                    <BaseButton size="lg" onClick={handleFinishStep2}>
                      Verificar código
                    </BaseButton>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <div className="row">
              <div className="col-12 mt-4">
                <TextField
                  name="password"
                  type="password"
                  value={newPassword}
                  label="Senha"
                  onChange={e => setNewPassword(e.target.value)}
                  helperText={error}
                  error={!!error}
                  showPasswordToggle
                  required
                />
              </div>
              <div className="col-12 mt-4">
                <TextField
                  type="password"
                  name="confirm_password"
                  value={confirmPassword}
                  label="Confirme sua senha"
                  onChange={e => setConfirmPassword(e.target.value)}
                  helperText={
                    newPassword !== confirmPassword
                      ? 'As senhas não coincidem'
                      : ''
                  }
                  error={newPassword !== confirmPassword}
                  showPasswordToggle
                  required
                />
              </div>
              <div className="col-12 mt-4 text-center">
                <BaseButton onClick={handleResetPassword}>
                  Alterar senha
                </BaseButton>
              </div>
            </div>
          </TabPanel>
        </div>
        <div
          className="d-flex card-footer"
          style={{ backgroundColor: 'transparent' }}
        >
          <span
            className="cancel-button"
            tabIndex={0}
            role="button"
            onKeyUp={() => {}}
            onClick={() => {
              cancelPhoneVerification();
              navigate('/login');
            }}
          >
            Cancelar
          </span>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ForgotPassword;
