import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './FormSidebar.css';

import {
  BaseInput,
  BaseButton,
  SelectInput,
  RadioInput,
  InfoPin,
  BottomSheet,
} from 'components';
import { Option } from 'components/SelectInput/SelectInput';

type MapRequestType =
  | 'desiccation'
  | 'soil_sampling'
  | 'selective_spraying'
  | '';
export type MapRequest = {
  id: number;
  name: string;
  area_id: number;
  file_id: number;
  type: MapRequestType;
  created_at: string;
};

export type SoilSamplingRequest = {
  id: number;
  map_request_id: number;
  sampling_procedure: 'grid' | 'productivity_map' | 'satellite_image';
  hec_per_sample: number | '';
  subsample_quantity: number | '';
  sampling_deth_intervals: [number, number][];
  deth_intervals_quantity: number;
};

interface FormSidebarProps {
  className?: string;
  formType: 'form' | 'edit';
  type?: 'desktop' | 'mobile';
  request?: SoilSamplingRequest;
  areaId: number;
  farmId: number;
  onSubmit?: (
    generalData: MapRequest,
    specializedData: SoilSamplingRequest,
    areasIds: number,
  ) => void;
  onSimulateGridSampling?: (hecPerSample: number | '') => void;
}

function FormSidebar({
  className,
  formType,
  request,
  areaId,
  farmId,
  type,
  onSubmit,
  onSimulateGridSampling,
}: FormSidebarProps): JSX.Element {
  const navigate = useNavigate();

  const [bsOpen, setBsOpen] = useState(true);
  const [generalForm, setGeneralForm] = useState<MapRequest>({
    id: 0,
    name: '',
    area_id: 0,
    file_id: 0,
    type: '',
    created_at: '',
  });
  const [soilSamplingForm, setSoilSamplingForm] = useState<SoilSamplingRequest>(
    {
      id: 0,
      map_request_id: 0,
      sampling_procedure: 'grid',
      hec_per_sample: 0,
      subsample_quantity: 0,
      sampling_deth_intervals: [
        [0, 100],
        [0, 0],
      ],
      deth_intervals_quantity: 1,
    },
  );

  const samplingOptions = [
    {
      value: 'grid',
      label: 'Amostragem em grade',
      isDisabled: false,
      isFixed: false,
    },
    {
      value: 'productivity_map',
      label: 'Amostragem por mapa de produtividade (em breve)',
      isDisabled: true,
      isFixed: false,
    },
    {
      value: 'satellite_image',
      label: 'Amostragem por imagem de satélite (em breve)',
      isDisabled: true,
      isFixed: false,
    },
  ];
  const [selectedSamplingOption, setSelectedSamplingOption] =
    useState<Option | null>(null);

  useEffect(() => {
    if (formType === 'edit' && request?.id) {
      setSoilSamplingForm({ ...request });
    }
  }, [request, formType]);

  useEffect(() => {
    const intervals = soilSamplingForm.sampling_deth_intervals;
    const interval0End = intervals[0][1] < 100 ? intervals[0][1] : 99;

    if (soilSamplingForm.deth_intervals_quantity === 1) {
      intervals[1][0] = 0;
      intervals[1][1] = 1;
    } else {
      intervals[0][1] = interval0End;
      intervals[1][0] = interval0End;
      intervals[1][1] = 100;
    }

    setSoilSamplingForm(prev => ({
      ...prev,
      sampling_deth_intervals: intervals,
    }));
  }, [
    soilSamplingForm.deth_intervals_quantity,
    soilSamplingForm.sampling_deth_intervals,
  ]);

  const handleStoreRequest = (): void => {
    if (onSubmit) {
      onSubmit(generalForm, soilSamplingForm, areaId);
    }
  };

  const handleChangeSamplingDethIntervals = (
    value: number,
    index: [number, number],
  ): void => {
    const intervals = soilSamplingForm.sampling_deth_intervals;
    const intervalsQnt = soilSamplingForm.deth_intervals_quantity;
    intervals[index[0]][index[1]] = value;

    if (
      intervalsQnt === 2 &&
      ((index[0] === 0 && index[1] === 1) || (index[0] === 1 && index[1] === 0))
    ) {
      intervals[index[1]][index[0]] = value;
    }

    setSoilSamplingForm({
      ...soilSamplingForm,
      sampling_deth_intervals: intervals,
    });
  };

  const capValue = (value: number, min: number, max: number): number => {
    return Math.max(min, Math.min(max, value));
  };

  const handleAdjustDethIntervals = (): void => {
    const intervalsQnt = soilSamplingForm.deth_intervals_quantity;
    const intervals = soilSamplingForm.sampling_deth_intervals;

    if (intervalsQnt === 1) {
      intervals[0][1] = capValue(intervals[0][1], 1, 100);
      intervals[0][0] = capValue(intervals[0][0], 0, intervals[0][1] - 1);
    } else if (intervalsQnt === 2) {
      intervals[0][1] = capValue(intervals[0][1], 1, 99);
      intervals[1][0] = capValue(intervals[1][0], 1, 99);
      intervals[0][0] = capValue(intervals[0][0], 0, intervals[0][1] - 1);
      intervals[1][1] = capValue(intervals[1][1], intervals[0][1] + 1, 100);
    }

    setSoilSamplingForm({
      ...soilSamplingForm,
      sampling_deth_intervals: intervals,
    });
  };

  const handleEnterKey = (e: { key: string }, callback: () => void): void => {
    if (e.key === 'Enter' && callback) {
      callback();
    }
  };

  const getSidebarContent = (): React.ReactNode => {
    return (
      <>
        <div className="row w-100 mt-2 mb-4 mb-lg-0">
          <div className="col-12">
            <h4 className="text-muted">Agricultura preditiva</h4>
          </div>
          <div className="col-12 mt-4">
            <BaseInput
              name="name"
              label="Nome da solicitação*"
              value={generalForm.name}
              autoComplete="off"
              onChange={e => {
                setGeneralForm({ ...generalForm, name: e.target.value });
              }}
            />
          </div>
        </div>
        <hr />

        <div className="row w-100">
          <div className="col-12 mb-4">
            <span>Procedimento de Coleta*</span>
            <SelectInput
              options={samplingOptions}
              placeholder="Selecione um procedimento"
              value={selectedSamplingOption}
              onChange={sampling => {
                setSelectedSamplingOption(sampling as Option);
                setSoilSamplingForm({
                  ...soilSamplingForm,
                  sampling_procedure: (sampling as Option).value as
                    | 'grid'
                    | 'productivity_map'
                    | 'satellite_image',
                });
              }}
            />
          </div>
          <div className="col-6" />
          <div className="d-flex col-6 justify-content-end">
            <InfoPin
              size="medium"
              tooltip="Recomenda-se um número de subamostras entre 8 e 12."
              tooltipPosition="top"
            />
          </div>
          <div className="col-6 mb-4">
            <BaseInput
              name="hec_per_sample"
              label="Hectares por Ponto*"
              type="number"
              value={soilSamplingForm.hec_per_sample}
              onChange={e => {
                setSoilSamplingForm({
                  ...soilSamplingForm,
                  hec_per_sample:
                    e.target.value && Number(e.target.value) >= 0
                      ? Number(e.target.value)
                      : '',
                });
              }}
              onBlur={() => {
                if (onSimulateGridSampling) {
                  onSimulateGridSampling(soilSamplingForm.hec_per_sample);
                  setBsOpen(false);
                }
              }}
              onKeyUp={e => {
                handleEnterKey(e, () => {
                  if (onSimulateGridSampling) {
                    onSimulateGridSampling(soilSamplingForm.hec_per_sample);
                    setBsOpen(false);
                  }
                });
              }}
            />
          </div>
          <div className="col-6 mb-4">
            <BaseInput
              name="subsample_quantity"
              label="Subamostras*"
              type="number"
              value={soilSamplingForm.subsample_quantity}
              onChange={e => {
                setSoilSamplingForm({
                  ...soilSamplingForm,
                  subsample_quantity: e.target.value
                    ? Number(e.target.value)
                    : '',
                });
              }}
            />
          </div>
          <div className="d-flex col-12 flex-column">
            <div style={{ marginBottom: '-10px' }}>
              <span>Profundidade da Amostra:</span>
            </div>
            <div className="mt-2">
              <RadioInput
                name="type"
                size="small"
                value={soilSamplingForm.deth_intervals_quantity}
                onChange={e => {
                  setSoilSamplingForm({
                    ...soilSamplingForm,
                    deth_intervals_quantity: Number(e.target.value),
                  });
                }}
                row
              >
                <option value={1}>1 intervalo</option>
                <option value={2}>2 intervalos</option>
              </RadioInput>
            </div>
            <div className="row">
              <div className="col-12">
                <span>
                  <strong className="text-muted">Intervalo 1:</strong>
                </span>
              </div>
              <div className="col-6">
                <BaseInput
                  name="sampling_deth_intervals"
                  label="De (cm)"
                  type="number"
                  value={soilSamplingForm.sampling_deth_intervals[0][0]}
                  onBlur={handleAdjustDethIntervals}
                  onKeyUp={e => {
                    handleEnterKey(e, handleAdjustDethIntervals);
                  }}
                  onChange={e => {
                    handleChangeSamplingDethIntervals(
                      Number(e.target.value),
                      [0, 0],
                    );
                  }}
                />
              </div>
              <div className="col-6">
                <BaseInput
                  name="sampling_deth_intervals"
                  label="Até (cm)"
                  type="number"
                  value={soilSamplingForm.sampling_deth_intervals[0][1]}
                  onBlur={handleAdjustDethIntervals}
                  onKeyUp={e => {
                    handleEnterKey(e, handleAdjustDethIntervals);
                  }}
                  onChange={e => {
                    handleChangeSamplingDethIntervals(
                      Number(e.target.value),
                      [0, 1],
                    );
                  }}
                />
              </div>
            </div>
            {soilSamplingForm.deth_intervals_quantity === 2 && (
              <div className="row mt-4 flex-row">
                <div className="col-12">
                  <span>
                    <strong className="text-muted">Intervalo 2:</strong>
                  </span>
                </div>
                <div className="col-6">
                  <BaseInput
                    name="sampling_deth_intervals"
                    label="De (cm)"
                    type="number"
                    value={soilSamplingForm.sampling_deth_intervals[1][0]}
                    onBlur={handleAdjustDethIntervals}
                    onKeyUp={e => {
                      handleEnterKey(e, handleAdjustDethIntervals);
                    }}
                    onChange={e => {
                      handleChangeSamplingDethIntervals(
                        Number(e.target.value),
                        [1, 0],
                      );
                    }}
                  />
                </div>
                <div className="col-6">
                  <BaseInput
                    name="sampling_deth_intervals"
                    label="Até (cm)"
                    type="number"
                    value={soilSamplingForm.sampling_deth_intervals[1][1]}
                    onBlur={handleAdjustDethIntervals}
                    onKeyUp={e => {
                      handleEnterKey(e, handleAdjustDethIntervals);
                    }}
                    onChange={e => {
                      handleChangeSamplingDethIntervals(
                        Number(e.target.value),
                        [1, 1],
                      );
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row w-100 mt-5">
          <div className="d-flex col-12 justify-content-center">
            <BaseButton
              id="save-farm-button"
              size="lg"
              tooltip="Solicitar Mapa"
              onClick={() => {
                handleStoreRequest();
                setBsOpen(false);
              }}
            >
              <i className="fas fa-save" style={{ marginRight: '5px' }} />{' '}
              {formType === 'form'
                ? 'Solicitar novo mapa'
                : 'Salvar Alterações'}
            </BaseButton>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="container-fluid h-100 p-0">
      {type === 'desktop' ? (
        <div className="form-sidebar-container">
          <div className="sidebar-header">
            <div
              className="icon-button"
              tabIndex={0}
              role="button"
              onKeyUp={() => {}}
              onClick={() => {
                navigate(`/customer/farms/${farmId}/areas/${areaId}/requests`);
              }}
            >
              <i className="fas fa-arrow-left" /> Voltar
            </div>
            <div className="d-flex flex-column text-center">
              <span className="title">
                {formType === 'form'
                  ? 'Solicitar Novo Mapa'
                  : 'Editar Solicitação'}
              </span>
              <span className="subtitle">
                {formType === 'form'
                  ? 'Preencha os dados da solicitação'
                  : 'Edite os dados da solicitação'}
              </span>
            </div>
          </div>
          <div className="sidebar-body">{getSidebarContent()}</div>
        </div>
      ) : (
        <BottomSheet
          title={formType === 'form' ? 'Nova AP' : 'Editar AP'}
          className={className}
          open={bsOpen}
          onChange={open => setBsOpen(open)}
          initialState="middle-opened"
        >
          <div className="bottom-sheet-content">{getSidebarContent()}</div>
        </BottomSheet>
      )}
    </div>
  );
}

FormSidebar.defaultProps = {
  className: '',
  type: 'desktop',
  request: {},
  onSubmit: () => {},
  onSimulateGridSampling: () => {},
};

export default FormSidebar;
