import React from 'react';
import './BalanceSummary.css';
import { useNavigate } from 'react-router-dom';

interface BalanceSummaryProps {
  balance: number;
  purchasePath?: string;
  responsiveOnXSmall?: boolean;
}

function BalanceSummary({
  balance,
  purchasePath,
  responsiveOnXSmall,
}: BalanceSummaryProps): JSX.Element {
  const navigate = useNavigate();
  return (
    <div className="container">
      <div
        className={`balance-summary ${responsiveOnXSmall ? 'xsm-responsive' : ''}`}
      >
        <div className="d-flex balance-wrapper">
          <span className="d-none d-sm-block">Saldo</span>
          <span className="d-sm-none">
            <i className="fas fa-dollar-sign" />
          </span>
          <span className="balance-value default-green">
            {balance.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
            ha
          </span>
        </div>
        <button
          aria-label="Adicionar saldo"
          type="button"
          className="purchase-button"
          onClick={() => {
            if (purchasePath) navigate(purchasePath);
          }}
        >
          <span className="plus-icon">
            <i className="fas fa-plus" />
          </span>
        </button>
      </div>
    </div>
  );
}

BalanceSummary.defaultProps = {
  purchasePath: '/customer/purchase',
  responsiveOnXSmall: false,
};

export default BalanceSummary;
