import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NavBar.css';

import { signOut } from 'services/auth';
import { BaseMenu } from 'components';

function NavBar(): JSX.Element {
  const navigate = useNavigate();
  return (
    <div className="navbar">
      <a href="/admin/dashboard">
        <img className="logo" alt="Logo da Aufarm" src="/aufarm_logo.png" />
      </a>
      <div className="d-flex" style={{ marginRight: '5px' }}>
        <BaseMenu label="Admin">
          <option
            onClick={() => {
              navigate('/admin/balances');
            }}
          >
            Extrato
          </option>
          <option
            onClick={() => {
              navigate('/admin/packages');
            }}
          >
            Pacotes
          </option>
          <option
            onClick={() => {
              navigate('/admin/bonifications');
            }}
          >
            Bonificações
          </option>
          <option
            onClick={() => {
              navigate('/admin/dashboard');
            }}
          >
            Solicitações de Mapa
          </option>
          <option onClick={signOut}>Sair</option>
        </BaseMenu>
      </div>
    </div>
  );
}

export default NavBar;
