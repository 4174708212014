import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './BonificationsView.css';

import api from 'services/api';
import { getCatchedErrors } from 'helpers/apiHelper';
import { ToastContainer, toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

import {
  StatsCard,
  BaseTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  ScrollTrigger,
} from 'components';

type Bonification = {
  id: number;
  ha_amount: number;
  customer: {
    id: number;
    name: string;
  };
  created_at: Date;
};

function BonificationsView(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tBodyRef = useRef<HTMLTableSectionElement>(null);
  const [currPage, setCurrPage] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(false);

  const [bonifications, setBonifications] = useState<Bonification[]>([]);
  const [statistics, setStatistics] = useState<{ total_bonus: number }>({
    total_bonus: 0,
  });

  const loadStatistics = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { data } = await api.get('admin/bonus-transactions/statistics');
      setStatistics(data);
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao carregar as estatísticas. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const loadTransactions = async (page: number): Promise<void> => {
    setLoadingTransactions(true);
    try {
      const { data } = await api.get(`admin/bonus-transactions?page=${page}`);
      setBonifications(prev =>
        page === 1 ? data.transactions : [...prev, ...data.transactions],
      );
      setLastPage(data.lastPage);
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao carregar as transações. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    setLoadingTransactions(false);
  };

  useEffect(() => {
    loadStatistics();
  }, []);

  useEffect(() => {
    if (!lastPage) loadTransactions(currPage);
  }, [currPage]);

  return (
    <div className="container-fluid p-4 overflow-auto">
      <div className="row mb-4">
        <div className="col-12 col-lg-4">
          <StatsCard
            title="Total Bonificado"
            titleType="largeMuted"
            value={`${statistics.total_bonus.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ha`}
            description="Esse é o total de ha bonificados"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="d-flex card-header justify-content-between">
              <h5 className="card-title mt-1">Histórico</h5>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <BaseTable
                  className="table m-0"
                  style={{
                    height: '100px',
                  }}
                >
                  <TableHead className="thead-light">
                    <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell>Cliente</TableCell>
                      <TableCell>Hectares (ha)</TableCell>
                    </TableRow>
                  </TableHead>
                  <ScrollTrigger
                    forwardedRef={tBodyRef}
                    onScrollEnd={() => {
                      if (!lastPage) setCurrPage(prev => prev + 1);
                    }}
                  >
                    <TableBody
                      ref={tBodyRef}
                      style={{
                        maxHeight: '50vh',
                        overflowY: 'auto',
                      }}
                    >
                      {bonifications.map((bonification: Bonification) => (
                        <TableRow key={bonification.id}>
                          <TableCell>
                            {new Date(bonification.created_at).toLocaleString()}
                          </TableCell>
                          <TableCell>
                            <button
                              aria-label="Ver detalhes do cliente"
                              type="button"
                              className="btn btn-link"
                              onClick={() =>
                                navigate(
                                  `/admin/customers/${bonification.customer.id}`,
                                )
                              }
                            >
                              {bonification.customer.name}
                            </button>
                          </TableCell>
                          <TableCell>
                            {bonification.ha_amount.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                        </TableRow>
                      ))}

                      {(loadingTransactions || !lastPage) && (
                        <TableRow>
                          <TableCell>
                            {loadingTransactions && (
                              <div className="d-flex justify-content-center">
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            )}
                            {!loadingTransactions && !lastPage && (
                              <div className="d-flex justify-content-center">
                                <button
                                  aria-label="Carregar mais transações"
                                  type="button"
                                  className="btn btn-link"
                                  onClick={() => setCurrPage(prev => prev + 1)}
                                >
                                  Carregar mais...
                                </button>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </ScrollTrigger>
                </BaseTable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default BonificationsView;
