import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { InteractiveMap, FloatingDialog } from 'components';
import { getCityLocation } from 'helpers/locationHelper';

import api from 'services/api';
import { getCatchedErrors } from 'helpers/apiHelper';
import { ToastContainer, toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

import FormSidebar, { Farm } from '../FormSidebar';

import './FarmForm.css';

function FarmForm(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showMarker, setShowMarker] = useState<boolean>(false);
  const [markerPosition, setMarkerPosition] = useState<[number, number]>([
    0, 0,
  ]);
  const [mapCenter, setMapCenter] = useState<[number, number]>([
    -29.711025188644363, -53.71634781360627,
  ]);
  const [mapZoom, setMapZoom] = useState<number>(14);

  const handleSetMapCenter = async (city: string): Promise<void> => {
    dispatch(setLoader(true));
    const location = await getCityLocation(city);
    dispatch(setLoader(false));

    if (!location[0] || !location[1]) return;
    setMapCenter(location);
  };

  const handleStoreFarm = async (data: Farm): Promise<void> => {
    if (!data.lat || !data.lng) {
      toast.error('Selecione a posição da fazenda no mapa', {
        position: 'bottom-center',
      });
      return;
    }

    dispatch(setLoader(true));
    try {
      const { data: storedFarm } = await api.post('customer/farms', data);

      toast.success('Fazenda cadastrada com sucesso!', {
        position: 'bottom-center',
      });

      navigate('/customer/farms', { state: { onFocus: storedFarm.id } });
    } catch (e) {
      const errors = getCatchedErrors(e);
      toast.error(errors[0].message || 'Erro ao cadastrar fazenda', {
        position: 'bottom-center',
      });
    }
    dispatch(setLoader(false));
  };

  return (
    <div className="d-flex container-fluid h-100 p-0 flex-column flex-lg-row">
      <div className="d-none d-lg-block farm-form-sidebar">
        <FormSidebar
          type="desktop"
          formType="form"
          farmLocation={markerPosition}
          onStoreFarm={handleStoreFarm}
          onChangeLocation={handleSetMapCenter}
        />
      </div>
      <div className="farm-form-map-container">
        <div className="d-lg-none farm-form-map-container__header">
          <div className="d-flex text-center justify-content-between">
            <div
              className="icon-button my-auto"
              tabIndex={0}
              role="button"
              onKeyUp={() => {}}
              onClick={() => navigate(`/customer/farms`)}
            >
              <i className="fas fa-arrow-left" style={{ marginRight: '5px' }} />{' '}
              Voltar
            </div>
          </div>
        </div>
        <FloatingDialog position="center-top" size="md">
          <div className="row">
            <div className="col-12">
              <h5 className="text-center">
                <i
                  className="fas fa-location-dot"
                  style={{ marginRight: '5px' }}
                />{' '}
                Selecione a posição da Fazenda
              </h5>
            </div>
            <div className="col-12">
              <p className="text-center">
                Ao{' '}
                <strong>
                  clicar <i className="fas fa-arrow-pointer" />
                </strong>{' '}
                no mapa, a posição de sua fazenda será marcada. Caso queira
                alterar a posição, basta clicar novamente em outro local.
              </p>
            </div>
          </div>
        </FloatingDialog>
        <InteractiveMap
          center={mapCenter}
          showMarker={showMarker}
          markerPosition={markerPosition}
          zoom={mapZoom}
          onClick={e => {
            setShowMarker(true);
            setMarkerPosition([e.latlng.lat, e.latlng.lng]);
            setMapCenter([e.latlng.lat, e.latlng.lng]);
            setMapZoom(18);
          }}
        />
      </div>
      <div className="d-lg-none">
        <FormSidebar
          className="d-block d-lg-none"
          type="mobile"
          formType="form"
          farmLocation={markerPosition}
          onStoreFarm={handleStoreFarm}
          onChangeLocation={handleSetMapCenter}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default FarmForm;
