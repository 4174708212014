import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import GuestLayout from 'layout/GuestLayout';

import Login from 'views/Login';
import ForgotPassword from 'views/ForgotPassword';
// import SignUp from 'views/SignUp';
import Maintenance from 'views/Maintenance';

const PublicRoutes = (
  <Route path="">
    <Route path="/" element={<Navigate to="/login" />} />
    <Route
      path="/login"
      element={
        <GuestLayout>
          <Login />
        </GuestLayout>
      }
    />
    <Route
      path="/forgot-password"
      element={
        <GuestLayout>
          <ForgotPassword />
        </GuestLayout>
      }
    />
    {/* <Route path="/signup">
      <Route
        path="step-one"
        element={
          <GuestLayout>
            <SignUp />
          </GuestLayout>
        }
      />
    </Route> */}
    <Route path="/signup">
      <Route
        path="step-one"
        element={
          <GuestLayout>
            <Maintenance maintenanceObj="Cadastro" />
          </GuestLayout>
        }
      />
    </Route>
  </Route>
);

export default PublicRoutes;
