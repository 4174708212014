import React, { useState } from 'react';
import './FloatingDialog.css';

interface FloatingDialogProps {
  className?: string;
  position?:
    | 'center-top'
    | 'center-bottom'
    | 'left-top'
    | 'left-bottom'
    | 'right-top'
    | 'right-bottom';
  size?: 'sm' | 'md' | 'lg';
  children?: React.ReactNode;
  dialogKey?: React.Key;
  closable?: boolean;
}

function FloatingDialog({
  className,
  position,
  size,
  children,
  dialogKey,
  closable,
}: FloatingDialogProps): JSX.Element {
  const [hidden, setHidden] = useState<boolean>(false);
  return (
    <div
      key={dialogKey}
      className={`${className} floating-dialog-container position-${position} ${
        hidden ? 'hidden' : ''
      }`}
    >
      <div className={`floating-dialog ${size} py-2 px-3`}>
        <div className="row">
          <div className="col-12 text-end pr-2" style={{ minHeight: '10px' }}>
            {closable && (
              <button
                aria-label="Fechar"
                className="btn btn-md"
                type="button"
                onClick={() => {
                  setHidden(true);
                }}
              >
                <i className="fas fa-times" />
              </button>
            )}
          </div>
        </div>
        <div className="floating-dialog-body">{children}</div>
      </div>
    </div>
  );
}

FloatingDialog.defaultProps = {
  className: '',
  position: 'center-top',
  size: 'md',
  children: null,
  dialogKey: null,
  closable: true,
};

export default FloatingDialog;
