import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './FormSidebar.css';

import { BaseInput, BaseButton, SelectInput, BottomSheet } from 'components';
import { Option } from 'components/SelectInput/SelectInput';

type MapRequestType =
  | 'desiccation'
  | 'soil_sampling'
  | 'selective_spraying'
  | '';
export type MapRequest = {
  id: number;
  name: string;
  area_id: number;
  file_id: number;
  type: MapRequestType;
  created_at: string;
};

export type DesiccationRequest = {
  id: number;
  map_request_id: number;
  monitor: string;
  culture: string;
  spray_volume: number | '';
};

const CULTURES = ['Soja', 'Trigo', 'Aveia', 'Canola'];
const MONITORS = [
  'GS3',
  'GS4',
  'Topcon',
  'C3000',
  'Omni 700',
  'Topper 4500',
  'Topper 5500',
  'Hexagon Ti5',
  'GFX-750',
  'DJI',
];

interface FormSidebarProps {
  className?: string;
  formType: 'form' | 'edit';
  request?: DesiccationRequest;
  areas: Option[];
  areaId: number;
  farmId: number;
  type?: 'desktop' | 'mobile';
  onSubmit?: (
    generalData: MapRequest,
    specializedData: DesiccationRequest,
    areasIds: number[],
  ) => void;
}

function FormSidebar({
  className,
  formType,
  request,
  areas,
  areaId,
  farmId,
  type,
  onSubmit,
}: FormSidebarProps): JSX.Element {
  const navigate = useNavigate();

  const [bsOpen, setBsOpen] = useState(true);
  const [generalForm, setGeneralForm] = useState<MapRequest>({
    id: 0,
    name: '',
    area_id: 0,
    file_id: 0,
    type: '',
    created_at: '',
  });
  const [desiccationForm, setDesiccationForm] = useState<DesiccationRequest>({
    id: 0,
    map_request_id: 0,
    monitor: '',
    culture: '',
    spray_volume: 0,
  });
  const [selectedAreas, setSelectedAreas] = useState<Option[]>([]);
  const [selectedMonitor, setSelectedMonitor] = useState<Option | null>(null);
  const [selectedCulture, setSelectedCulture] = useState<Option | null>(null);

  useEffect(() => {
    if (formType === 'edit' && request?.id) {
      setDesiccationForm({ ...request });
    }
  }, [request, formType]);

  useEffect(() => {
    const selectedArea = areas.find(
      (area: Option) => area.value === areaId,
    ) as Option;
    if (!selectedArea) return;

    selectedArea.isFixed = true;
    setSelectedAreas([selectedArea]);
  }, [areas, areaId]);

  const handleChangeSelect = (value: Option | Option[] | null): void => {
    setSelectedAreas(value as Option[]);
  };

  const handleStoreRequest = (): void => {
    const areasIds = selectedAreas.map(area => Number(area.value));
    if (onSubmit) {
      onSubmit(generalForm, desiccationForm, areasIds);
    }
  };

  const getCultureOptions = (): Option[] => {
    return CULTURES.map(culture => ({
      label: culture,
      value: culture,
      isDisabled: false,
      isFixed: false,
    }));
  };

  const getMonitorOptions = (): Option[] => {
    return MONITORS.map(monitor => ({
      label: monitor,
      value: monitor,
      isDisabled: false,
      isFixed: false,
    }));
  };

  const getSidebarContent = (): React.ReactNode => {
    return (
      <>
        <div className="row w-100 mt-2 mb-4 mb-lg-0">
          <div className="col-12">
            <h4 className="text-muted">Dessecação pré-colheita</h4>
          </div>
          <div className="col-12 mt-2">
            <BaseInput
              name="name"
              label="Nome da solicitação*"
              value={generalForm.name}
              autoComplete="off"
              onChange={e => {
                setGeneralForm({ ...generalForm, name: e.target.value });
              }}
            />
          </div>
        </div>
        <hr />

        <div className="row w-100">
          <div className="col-12 mb-4">
            <div className="mb-2">
              <span>Selecione uma ou mais áreas</span>
            </div>
            <SelectInput
              options={areas}
              placeholder="Selecione uma ou mais áreas"
              value={selectedAreas}
              onChange={handleChangeSelect}
              isMulti
            />
          </div>
          <div className="col-12 mb-4">
            <span>Monitor*</span>
            <SelectInput
              options={getMonitorOptions()}
              placeholder="Selecione um monitor"
              value={selectedMonitor}
              onChange={monitor => {
                setSelectedMonitor(monitor as Option);
                setDesiccationForm({
                  ...desiccationForm,
                  monitor: (monitor as Option).value as string,
                });
              }}
            />
          </div>
          <div className="col-12 mb-4">
            <span>Cultura*</span>
            <SelectInput
              options={getCultureOptions()}
              placeholder="Selecione uma cultura"
              value={selectedCulture}
              onChange={culture => {
                setSelectedCulture(culture as Option);
                setDesiccationForm({
                  ...desiccationForm,
                  culture: (culture as Option).value as string,
                });
              }}
            />
          </div>
          <div className="col-12">
            <BaseInput
              name="spray_volume"
              label="Volume de Calda (L/ha)*"
              type="number"
              value={desiccationForm.spray_volume}
              onChange={e => {
                setDesiccationForm({
                  ...desiccationForm,
                  spray_volume: e.target.value ? Number(e.target.value) : '',
                });
              }}
            />
          </div>
        </div>
        <div className="row w-100 mt-5">
          <div className="d-flex col-12 justify-content-center">
            <BaseButton
              id="save-farm-button"
              size="lg"
              tooltip="Solicitar Mapa"
              onClick={() => {
                handleStoreRequest();
                setBsOpen(false);
              }}
            >
              <i className="fas fa-save" style={{ marginRight: '5px' }} />{' '}
              {formType === 'form'
                ? 'Solicitar novo mapa'
                : 'Salvar Alterações'}
            </BaseButton>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="container-fluid h-100 p-0">
      {type === 'desktop' ? (
        <div className="form-sidebar-container">
          <div className="sidebar-header">
            <div
              className="icon-button"
              tabIndex={0}
              role="button"
              onKeyUp={() => {}}
              onClick={() => {
                navigate(`/customer/farms/${farmId}/areas/${areaId}/requests`);
              }}
            >
              <i className="fas fa-arrow-left" /> Voltar
            </div>
            <div className="d-flex flex-column text-center">
              <span className="title">
                {formType === 'form'
                  ? 'Solicitar Novo Mapa'
                  : 'Editar Solicitação'}
              </span>
              <span className="subtitle">
                {formType === 'form'
                  ? 'Preencha os dados da solicitação'
                  : 'Edite os dados da solicitação'}
              </span>
            </div>
          </div>
          <div className="sidebar-body">{getSidebarContent()}</div>
        </div>
      ) : (
        <BottomSheet
          title={formType === 'form' ? 'Nova DP' : 'Editar DP'}
          className={className}
          open={bsOpen}
          onChange={open => setBsOpen(open)}
          initialState="middle-opened"
        >
          <div className="bottom-sheet-content">{getSidebarContent()}</div>
        </BottomSheet>
      )}
    </div>
  );
}

FormSidebar.defaultProps = {
  className: '',
  type: 'desktop',
  request: {},
  onSubmit: () => {},
};

export default FormSidebar;
