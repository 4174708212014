import React from 'react';
import './CounterInput.css';

interface CounterInputProps {
  value: number;
  minValue?: number;
  maxValue?: number;
  step?: number;
  onChange?: (value: number) => void;
}

function CounterInput({
  value,
  minValue,
  maxValue,
  step,
  onChange,
}: CounterInputProps): JSX.Element {
  const inInterval = (
    number: number,
    min: number | undefined,
    max: number | undefined,
  ): boolean => {
    if (min !== undefined && number < min) return false;
    if (max !== undefined && number > max) return false;
    return true;
  };

  const castNaN = (number: number, min: number): number => {
    if (Number.isNaN(number)) return min;
    return number;
  };

  return (
    <div className="counter-input">
      <button
        aria-label={`Diminuir ${step || 1}`}
        type="button"
        className="counter-minus-btn"
        onClick={() => {
          const newValue = castNaN(value, minValue || 0) - (step || 1);
          if (inInterval(newValue, minValue, maxValue)) {
            if (onChange) onChange(newValue);
          }
        }}
      >
        -
      </button>
      <input
        type="text"
        className="counter-field"
        value={Number.isNaN(value) ? '' : value}
        onChange={e => {
          const newValue = parseInt(e.target.value, 10);
          if (inInterval(newValue, minValue, maxValue)) {
            if (onChange) onChange(newValue);
          }
        }}
      />
      <button
        aria-label={`Aumentar ${step || 1}`}
        type="button"
        className="counter-plus-btn"
        onClick={() => {
          const newValue = castNaN(value, minValue || 0) + (step || 1);
          if (inInterval(newValue, minValue, maxValue)) {
            if (onChange) onChange(newValue);
          }
        }}
      >
        +
      </button>
    </div>
  );
}

CounterInput.defaultProps = {
  minValue: undefined,
  maxValue: undefined,
  step: 1,
  onChange: () => {},
};

export default CounterInput;
