import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { InteractiveMap } from 'components';

import api from 'services/api';
import { getCatchedErrors } from 'helpers/apiHelper';
import { ToastContainer, toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

import FormSidebar, { Farm } from '../FormSidebar';

import './FarmEdit.css';

function FarmEdit(): JSX.Element {
  const { farmId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [farm, setFarm] = useState<Farm>({} as Farm);
  const [showMarker] = useState<boolean>(true);
  const [mapCenter, setMapCenter] = useState<[number, number]>([
    -29.711025188644363, -53.71634781360627,
  ]);
  const [markerPosition, setMarkerPosition] = useState<[number, number]>([
    0, 0,
  ]);

  useEffect(() => {
    const getFarm = async (): Promise<void> => {
      dispatch(setLoader(true));
      try {
        const { data } = await api.get(`customer/farms/${farmId}`);

        if (data) {
          const { lat, lng } = data;
          setMarkerPosition([lat, lng]);
          setMapCenter([lat, lng]);
        }

        setFarm(data);
      } catch (e) {
        const errors = getCatchedErrors(e);
        toast.error(errors[0].message || 'Erro ao buscar fazenda', {
          position: 'bottom-center',
        });
      }
      dispatch(setLoader(false));
    };

    getFarm();
  }, [farmId]);

  const handleUpdateFarm = async (data: Farm): Promise<void> => {
    if (!data.lat || !data.lng) {
      toast.error('Selecione a posição da fazenda no mapa', {
        position: 'bottom-center',
      });
      return;
    }

    dispatch(setLoader(true));
    try {
      await api.put(`customer/farms/${data.id}`, data);

      toast.success('Fazenda atualizada com sucesso!', {
        position: 'bottom-center',
      });

      navigate('/customer/farms');
    } catch (e) {
      const errors = getCatchedErrors(e);
      toast.error(errors[0].message || 'Erro ao atualizar fazenda', {
        position: 'bottom-center',
      });
    }
    dispatch(setLoader(false));
  };
  return (
    <div className="d-flex container-fluid h-100 p-0  flex-column flex-lg-row">
      <div className="d-none d-lg-block farm-edit-sidebar">
        <FormSidebar
          type="desktop"
          formType="edit"
          farm={farm}
          farmLocation={markerPosition}
          onEditFarm={handleUpdateFarm}
        />
      </div>
      <div className="farm-edit-map-container">
        <InteractiveMap
          center={mapCenter}
          showMarker={showMarker}
          markerPosition={markerPosition}
          zoom={20}
        />
      </div>
      <div className="d-lg-none">
        <FormSidebar
          type="mobile"
          formType="edit"
          farm={farm}
          farmLocation={markerPosition}
          onEditFarm={handleUpdateFarm}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default FarmEdit;
