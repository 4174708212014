import React from 'react';
import { Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import PublicRoutes from './routes/PublicRoutes';
import CustomerRoutes from './routes/CustomerRoutes';
import AdminRoutes from './routes/AdminRoutes';

function Router(): JSX.Element {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        {/* Public Routes */}
        {PublicRoutes}

        {/* Customer Routes */}
        {CustomerRoutes}

        {/* Admin Routes */}
        {AdminRoutes}
      </Routes>
    </AnimatePresence>
  );
}

export default Router;
