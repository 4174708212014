import React, { useState } from 'react';
import './BaseMenu.css';

import { Button, Menu, MenuItem } from '@mui/material';

interface OptionElement extends HTMLSelectElement {
  tagName: 'option';
  onClick: () => void;
}
type SelectOption = React.ReactElement<OptionElement>;

interface BaseMenuProps {
  children: SelectOption | SelectOption[];
  label: string;
}

function BaseMenu({ children, label }: BaseMenuProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const handleClickItem = (child: SelectOption) => (): void => {
    if (child.props.onClick) {
      child.props.onClick();
    }
    handleCloseMenu();
  };

  return (
    <div>
      <Button
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
        style={{
          color: '#000',
          textTransform: 'none',
          fontFamily: 'Julius Sans One',
        }}
      >
        {label}
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {React.Children.map(children, child => (
          <MenuItem
            sx={{
              borderBottom: child.props.disabled ? '1px solid #ccc' : 'none',
            }}
            onClick={handleClickItem(child)}
            disabled={child.props.disabled}
          >
            {child}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default BaseMenu;
