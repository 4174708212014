import { configureStore, Action } from '@reduxjs/toolkit';

const LOADER = 'LOADER';
const BALANCE = 'BALANCE';
const VERIFY_EMAIL_MODAL = 'VERIFY_EMAIL_MODAL';

export type State = {
  isLoading: boolean;
  balance: number;
  verifyEmailModal: boolean;
};

type Loader = { type: string; loading: boolean };
type Balance = { type: string; balance: number };
type VerifyEmailModal = { type: string; verifyEmailModal: boolean };

interface CustomAction extends Action {
  loading: boolean;
  balance: number;
  verifyEmailModal: boolean;
}

// Actions
export const setLoader = (loading: boolean): Loader => {
  return {
    type: LOADER,
    loading,
  };
};

export const setBalance = (value: number): Balance => {
  return {
    type: BALANCE,
    balance: value,
  };
};

export const setVerifyEmailModal = (value: boolean): VerifyEmailModal => {
  return {
    type: VERIFY_EMAIL_MODAL,
    verifyEmailModal: value,
  };
};

// Reducer
const initialState = {
  isLoading: false,
  balance: 0,
  verifyEmailModal: false,
};

const actionReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: CustomAction,
): State => {
  switch (action.type) {
    case LOADER:
      return {
        ...state,
        isLoading: action.loading,
      };
    case BALANCE:
      return {
        ...state,
        balance: action.balance,
      };
    case VERIFY_EMAIL_MODAL:
      return {
        ...state,
        verifyEmailModal: action.verifyEmailModal,
      };
    default:
      return state;
  }
};

// Store
export const store = configureStore({
  reducer: actionReducer,
});
