import React from 'react';
import Select, {
  MultiValue,
  SingleValue,
  StylesConfig,
  ActionMeta,
} from 'react-select';

import './SelectInput.css';

export type Option = {
  label: string;
  value: number | string;
  isDisabled: boolean;
  isFixed?: boolean;
};

const styles: StylesConfig<Option, boolean> = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

const orderOptions = (values: readonly Option[]): Option[] => {
  return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
};

interface SelectInputProps {
  id?: string;
  name?: string;
  options: Option[];
  placeholder: string;
  value: Option | Option[] | null;
  isMulti?: boolean;
  onChange?: (value: Option | Option[] | null) => void;
}

function SelectInput({
  id,
  name,
  options,
  placeholder,
  value,
  isMulti,
  onChange,
}: SelectInputProps): JSX.Element {
  const handleInputChange = (
    newValue: SingleValue<Option> | MultiValue<Option> | null,
    actionMeta: ActionMeta<Option>,
  ): void => {
    let currentValue = newValue as Option | Option[] | null;

    if (isMulti) {
      switch (actionMeta.action) {
        case 'remove-value':
        case 'pop-value':
          if (actionMeta.removedValue.isFixed) {
            return;
          }
          break;
        case 'clear':
          currentValue = options.filter(v => v.isFixed);
          break;
        default:
          break;
      }

      currentValue = orderOptions(currentValue as Option[]);
    }

    if (onChange) {
      onChange(currentValue);
    }
  };

  return (
    <div className="select-input-container">
      <Select
        id={id}
        name={name}
        styles={styles}
        options={options}
        placeholder={placeholder}
        value={value}
        isMulti={isMulti}
        onChange={handleInputChange}
        isOptionDisabled={(option): boolean => option.isDisabled}
      />
    </div>
  );
}

SelectInput.defaultProps = {
  id: '',
  name: '',
  isMulti: false,
  onChange: undefined,
};

export default SelectInput;
