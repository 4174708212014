import React, { ReactNode } from 'react';
import './BaseDropdown.css';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
    },
  },
};

interface OptionElement extends HTMLSelectElement {
  tagName: 'option';
  onClick: () => void;
}
type SelectOption = React.ReactElement<OptionElement>;

interface BaseDropdownProps {
  id?: string;
  name: string;
  children: SelectOption | SelectOption[];
  label: string;
  value: string;
  variant?: 'standard' | 'filled' | 'outlined';
  onChange?: (event: SelectChangeEvent<string>, child: ReactNode) => void;
}

function BaseDropdown({
  id,
  name,
  children,
  label,
  value,
  variant,
  onChange,
}: BaseDropdownProps): JSX.Element {
  const inputId = `dropdown-${label.toLowerCase()}`;
  return (
    <div className="input-container">
      <FormControl variant={variant}>
        <InputLabel id={`label_${inputId}`}>{label}</InputLabel>
        <Select
          name={name}
          labelId={`label_${inputId}`}
          id={id || inputId}
          value={value}
          onChange={onChange}
          label={label}
          MenuProps={MenuProps}
        >
          {React.Children.map(children, child => (
            <MenuItem value={child.props.value} disabled={child.props.disabled}>
              {child}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

BaseDropdown.defaultProps = {
  onChange: () => {},
  variant: 'standard',
  id: '',
};

export default BaseDropdown;
