import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './CustomerView.css';

import api from 'services/api';
import { getCatchedErrors } from 'helpers/apiHelper';
import { ToastContainer, toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

import {
  TextField,
  BaseTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  BaseButton,
  BaseModal,
  BaseInput,
  ProfilePicture,
  ScrollTrigger,
} from 'components';

type Customer = {
  id: number;
  user_id: number;
  name: string;
  identification_number: string;
  type: 'natural' | 'legal';
  phone: string;
  cep: string;
  street: string;
  number: string;
  district: string;
  city: string;
  state: string;
  complement: string;
  country: string;
  profilePicture?: {
    url: string;
    name: string;
  };
  user: {
    email: string;
  };
};

type Transaction = {
  id: number;
  type: 'bonus_transaction' | 'usage_transaction' | 'purchase_transaction';
  ha_amount: number;
  usageTransaction?: {
    id: number;
    mapRequest: {
      id: number;
      name: string;
      type: 'desiccation' | 'soil_sampling';
      area: {
        id: number;
        name: string;
      };
    };
  };
  purchaseTransaction?: {
    id: number;
    status:
      | 'PENDING'
      | 'RECEIVED'
      | 'CONFIRMED'
      | 'OVERDUE'
      | 'REFUNDED'
      | 'REFUND_IN_PROGRESS';
  };
  customer: {
    id: number;
    name: string;
  };
  created_at: Date;
};

function CustomerView(): JSX.Element {
  const { customerId } = useParams();
  const dispatch = useDispatch();

  const tBodyRef = useRef<HTMLTableSectionElement>(null);
  const [currPage, setCurrPage] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(false);

  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [customer, setCustomer] = useState<Customer>();
  const [bonusModal, setBonusModal] = useState({
    open: false,
    customer_id: 0,
    customer_name: '',
    bonus_amount: '',
  });

  const trxTypeMap: { [key: string]: string } = {
    usage_transaction: 'Solicitação de Mapa',
    bonus_transaction: 'Bonificação',
    purchase_transaction: 'Compra de Crédito',
  };

  const mapRequestTypeMap: { [key: string]: string } = {
    desiccation: 'Dessecação Localizada',
    soil_sampling: 'Amostragem de Solo',
  };

  const loadCustomer = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { data } = await api.get(`admin/customers/${customerId}`);
      setCustomer(data);
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao carregar os dados do cliente. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const loadTransactions = async (page: number): Promise<void> => {
    setLoadingTransactions(true);
    try {
      const { data } = await api.get(
        `admin/transactions/customers/${customerId}?page=${page}`,
      );
      setTransactions(prev =>
        page === 1 ? data.transactions : [...prev, ...data.transactions],
      );
      setLastPage(data.lastPage);
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao carregar as transações. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    setLoadingTransactions(false);
  };

  useEffect(() => {
    loadCustomer();
  }, []);

  useEffect(() => {
    if (!lastPage) loadTransactions(currPage);
  }, [currPage]);

  const getTransactionType = (transaction: Transaction): string => {
    if (
      transaction.type === 'usage_transaction' &&
      transaction.usageTransaction
    ) {
      return mapRequestTypeMap[transaction.usageTransaction.mapRequest.type];
    }

    return trxTypeMap[transaction.type];
  };

  const getValueClass = (transaction: Transaction | undefined): string => {
    if (transaction?.type === 'usage_transaction') {
      return 'text-danger';
    }

    if (transaction?.type === 'purchase_transaction') {
      switch (transaction.purchaseTransaction?.status) {
        case 'PENDING':
          return 'text-info';
        case 'OVERDUE':
          return 'text-secondary';
        default:
      }
    }

    return 'text-success';
  };

  const handleStoreBonusTransaction = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      await api.post(
        `admin/bonus-transactions/customer/${bonusModal.customer_id}`,
        {
          bonus_amount: Number(bonusModal.bonus_amount),
        },
      );
      toast.success('Bonificação realizada com sucesso.', {
        position: 'bottom-center',
      });
      setBonusModal({
        customer_id: 0,
        customer_name: '',
        open: false,
        bonus_amount: '',
      });
      setCurrPage(1);
      await loadTransactions(1);
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao realizar a bonificação. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  return (
    <div className="container-fluid p-4 overflow-auto">
      <div className="row mb-2">
        <div className="d-flex col-12 align-items-center">
          <ProfilePicture src={customer?.profilePicture?.url} size="large" />
          <h3 className="text-muted">
            <span className="user-name">{customer?.name || 'Usuário'}</span>
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card mb-4">
            <div className="d-flex card-header justify-content-between">
              <h4 className="card-title text-muted">Dados pessoais</h4>
              <div className="d-flex">
                <BaseButton
                  onClick={() => {
                    setBonusModal({
                      open: true,
                      customer_id: customer?.id || 0,
                      customer_name: customer?.name || '',
                      bonus_amount: '',
                    });
                  }}
                >
                  <i
                    className="fas fa-money-bill"
                    style={{ marginRight: '10px' }}
                  />
                  Conceder Crédito
                </BaseButton>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <TextField
                    label="Nome"
                    value={customer?.name || ''}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <TextField
                    label="Email"
                    value={customer?.user.email || ''}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <TextField
                    name="identification_number"
                    label={customer?.type === 'natural' ? 'CPF' : 'CNPJ'}
                    value={customer?.identification_number || ''}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <TextField
                    name="phone"
                    label="Telefone"
                    value={customer?.phone || ''}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="d-flex card-header justify-content-between">
              <h5 className="card-title mt-1">Histórico</h5>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <BaseTable
                  className="table m-0"
                  style={{
                    height: '100px',
                  }}
                >
                  <TableHead className="thead-light">
                    <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell>Tipo de Operação</TableCell>
                      <TableCell>Área</TableCell>
                      <TableCell>Hectares (ha)</TableCell>
                    </TableRow>
                  </TableHead>
                  <ScrollTrigger
                    forwardedRef={tBodyRef}
                    onScrollEnd={() => {
                      if (!lastPage) setCurrPage(prev => prev + 1);
                    }}
                  >
                    <TableBody
                      ref={tBodyRef}
                      style={{
                        minHeight: '200px',
                        maxHeight: '30vh',
                        overflowY: 'auto',
                      }}
                    >
                      {transactions.map((transaction: Transaction) => (
                        <TableRow key={transaction.id}>
                          <TableCell>
                            {new Date(transaction.created_at).toLocaleString()}
                          </TableCell>
                          <TableCell>
                            {getTransactionType(transaction)}
                          </TableCell>
                          <TableCell>
                            {transaction.usageTransaction
                              ? transaction.usageTransaction.mapRequest.area
                                  .name
                              : '-'}
                          </TableCell>
                          <TableCell>
                            <span className={getValueClass(transaction)}>
                              {`${transaction.ha_amount.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              )}`}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}

                      {(loadingTransactions || !lastPage) && (
                        <TableRow>
                          <TableCell>
                            {loadingTransactions && (
                              <div className="d-flex justify-content-center">
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            )}
                            {!loadingTransactions && !lastPage && (
                              <div className="d-flex justify-content-center">
                                <button
                                  aria-label="Carregar mais transações"
                                  type="button"
                                  className="btn btn-link"
                                  onClick={() => setCurrPage(prev => prev + 1)}
                                >
                                  Carregar mais...
                                </button>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </ScrollTrigger>
                </BaseTable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BaseModal
        size="sm"
        open={bonusModal.open}
        onClose={() => {
          setBonusModal({
            customer_id: 0,
            customer_name: '',
            open: false,
            bonus_amount: '',
          });
        }}
      >
        <div className="modal-header">
          <span className="modal-title">
            <i className="fas fa-money-bill" /> Nova Bonificação
          </span>
        </div>
        <div className="modal-body p-3">
          <div className="row">
            <div className="col-12 text-center">
              <span className="font-weight-bolder text-muted fs-4">
                {bonusModal.customer_name}
              </span>
            </div>
            <div className="col-12 mb-4 text-center">
              <span className="text-muted" style={{ marginRight: '5px' }}>
                <i className="fas fa-exclamation-triangle" /> <b>Atenção!</b>
              </span>
              <span className="text-muted">
                Selecione o valor cuidadosamente. Por enquanto esta ação não é
                facilmente reversível.
              </span>
            </div>

            <div className="col-12 mb-4">
              <BaseInput
                name="value"
                label="Valor a ser bonificado (ha)"
                type="number"
                value={bonusModal.bonus_amount}
                onChange={e => {
                  if (Number(e.target.value) < 0) return;
                  setBonusModal(prev => ({
                    ...prev,
                    bonus_amount: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="row">
            <div className="d-flex col-12 justify-content-end align-items-center">
              <button
                aria-label="Fechar modal"
                className="exit-modal-btn"
                type="button"
                onClick={() => {
                  setBonusModal({
                    customer_id: 0,
                    customer_name: '',
                    open: false,
                    bonus_amount: '',
                  });
                }}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </button>
              <BaseButton onClick={handleStoreBonusTransaction}>
                Confirmar
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>
      <ToastContainer />
    </div>
  );
}

export default CustomerView;
