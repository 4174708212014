import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './EmailVerification.css';

import { useDispatch } from 'react-redux';
import { setBalance } from 'store';

import api from 'services/api';
import { getCatchedErrors } from 'helpers/apiHelper';
import { ToastContainer, toast } from 'react-toastify';

import { getUserInfo, setUserInfo } from 'services/auth';

import { BaseButton, BaseInput, BaseBadge } from 'components';

function EmailVerification(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = getUserInfo();

  const [token, setToken] = useState('');
  const [requestId, setRequestId] = useState('');
  const firstRender = useRef(true);

  useEffect(() => {
    const sendEmailToken = async (): Promise<void> => {
      if (userInfo?.isEmailVerified) {
        navigate(-1);
        return;
      }

      try {
        const { data } = await api.post('customer/email-verifications');
        setRequestId(data.id);
      } catch (e) {
        const errors = getCatchedErrors(e);
        toast.error(
          errors[0].message ||
            'Erro ao enviar email de verificação. Tente novamente mais tarde.',
          {
            position: 'bottom-center',
          },
        );
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    };

    if (firstRender.current) {
      sendEmailToken();
      firstRender.current = false;
    }
  }, []);

  const loadCustomerBalance = async (): Promise<void> => {
    try {
      const { data } = await api.get('/customer/hectare-balances/self');
      dispatch(setBalance(data.value));
    } catch (e) {
      const errors = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao carregar seu saldo atual. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
  };

  const verifyEmail = async (): Promise<void> => {
    try {
      await api.post(`customer/email-verifications/verify`, {
        id: requestId,
        token,
      });

      toast.success('Email verificado com sucesso!', {
        position: 'bottom-center',
      });

      await loadCustomerBalance();
      if (userInfo) setUserInfo({ ...userInfo, isEmailVerified: true });

      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (e) {
      const errors = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Erro ao verificar email. Verifique o código e tente novamente.',
        {
          position: 'bottom-center',
        },
      );
    }
  };
  return (
    <div className="container-fluid p-4 overflow-auto">
      <div className="row">
        <div className="d-flex col-12 align-items-center mb-4">
          <div
            className="icon-button"
            tabIndex={0}
            role="button"
            onKeyUp={() => {}}
            onClick={() => navigate(-1)}
          >
            <i className="fas fa-arrow-left" /> Voltar
          </div>
        </div>
        <div className="col-6">
          <div className="card mb-4">
            <div className="card-body">
              <div className="row">
                <div className="d-flex col-12 align-items-center justify-content-between mb-4">
                  <div className="d-flex">
                    <i
                      className="fas fa-envelope fa-2x default-green"
                      style={{ marginRight: '10px' }}
                    />
                    <h3 className="text-muted mb-0">Verificação de email</h3>
                  </div>
                  <BaseBadge
                    type="success"
                    tooltip="Finalize a verificação e ganhe 30ha"
                  >
                    <i
                      className="fas fa-plus-circle"
                      style={{ marginRight: '5px' }}
                    />
                    30ha
                  </BaseBadge>
                </div>
                <div className="col-12 mb-5">
                  <h5 className="card-title">Verifique seu email</h5>
                  <p
                    className="card-text"
                    style={{
                      fontSize: '1.1rem',
                      color: '#6c757d',
                    }}
                  >
                    Enviamos um email com um código de verificação. Por favor,
                    verifique sua caixa de entrada.
                  </p>
                </div>
                <div className="d-flex col-12 justify-content-center mb-5">
                  <div
                    style={{
                      width: '250px',
                    }}
                  >
                    <BaseInput
                      label="Código de verificação"
                      placeholder="AABBCC"
                      value={token}
                      onChange={e => setToken(e.target.value.toUpperCase())}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex card-footer justify-content-end">
              <BaseButton onClick={verifyEmail}>Verificar email</BaseButton>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default EmailVerification;
