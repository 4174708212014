import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

import { TextField, BaseButton } from 'components';
import { ToastContainer, toast } from 'react-toastify';

import api from 'services/api';
import { signIn, storeUserInfo } from 'services/auth';
import { getCatchedErrors } from 'helpers/apiHelper';

import { useDispatch } from 'react-redux';
import { setLoader, setVerifyEmailModal } from 'store';

function Login(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValidModel, setIsValidModel] = useState(false);

  useEffect(() => {
    setIsValidModel(!!email && !!password);
  }, [email, password]);

  const login = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { data } = await api.post('/login', {
        email,
        password,
      });
      signIn(data.token, [data.role_id], data.user_id);

      const { data: user } = await api.get(`/self`);
      storeUserInfo(
        user.id,
        user.name,
        user.email,
        user.role.name,
        user.is_email_verified,
      );

      toast.success('Login realizado com sucesso', {
        position: 'bottom-center',
      });

      if (!user.is_email_verified) {
        dispatch(setVerifyEmailModal(true));
      }
      if (user.role.name === 'admin') {
        navigate(`/admin/dashboard`);
      } else if (user.role.name === 'customer') {
        navigate(`/customer/farms`);
      }
    } catch (e) {
      const errors = getCatchedErrors(e);
      toast.error(errors[0].message || 'Email e/ou senha incorretos', {
        position: 'bottom-center',
      });
    }
    dispatch(setLoader(false));
  };

  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLButtonElement>,
  ): void => {
    if (e.key === 'Enter' && isValidModel) {
      login();
    }
  };
  return (
    <div className="d-flex custom container-fluid">
      <div className="custom-card card p-4 mt-4">
        <div className="d-flex custom-header card-header justify-content-center">
          <a href="/">
            <img
              className="aufarm-logo-login"
              alt="Logo da Aufarm"
              src="/aufarm_logo_dark.png"
            />
          </a>
        </div>
        <div className="card-body">
          <div className="d-flex row justify-content-center">
            <div className="col-12">
              <TextField
                label="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="col-12 mt-4">
              <TextField
                label="Senha"
                value={password}
                type="password"
                onChange={e => setPassword(e.target.value)}
                onKeyUp={handleKeyUp}
                showPasswordToggle
              />
            </div>
            <div className="col-12 mt-2">
              <p className="text-end">
                <span
                  tabIndex={0}
                  role="button"
                  onKeyUp={handleKeyUp}
                  className="link-hover"
                  onClick={() => navigate('/forgot-password')}
                >
                  <strong>Esqueci minha senha </strong>
                </span>
              </p>
            </div>
          </div>
          <div className="d-flex row justify-content-center mt-4">
            <div className="col-12 text-center">
              <BaseButton
                size="lg"
                onClick={login}
                disabled={!isValidModel}
                onKeyUp={handleKeyUp}
              >
                Entrar
              </BaseButton>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <p className="text-center">
                Não possui uma conta?{' '}
                <span
                  tabIndex={0}
                  role="button"
                  onKeyUp={handleKeyUp}
                  className="link-ancor"
                  onClick={() => {
                    navigate('/signup/step-one');
                  }}
                >
                  <strong>
                    Cadastre-se{' '}
                    <i className="fas fa-arrow-up-right-from-square" />
                  </strong>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
