import React from 'react';
import { motion } from 'framer-motion';
import LoadingOverlay from 'react-loading-overlay-ts';

import { useSelector } from 'react-redux';
import { State } from 'store';

import Navbar from './Navbar';
import './CustomerLayout.css';

type CustomerLayoutProps = {
  children: React.ReactNode;
};

function CustomerLayout({ children }: CustomerLayoutProps): JSX.Element {
  const isLoading = useSelector((state: State) => state.isLoading);

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text="Carregando..."
      styles={{
        overlay: base => ({
          ...base,
          zIndex: 99999,
        }),
      }}
    >
      <div style={{ height: '100vh' }}>
        <Navbar />
        <main style={{ height: 'calc(100vh - 3em)' }}>
          <motion.div
            className="page-content"
            style={{ padding: '0' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {children}
          </motion.div>
        </main>
      </div>
    </LoadingOverlay>
  );
}

export default CustomerLayout;
