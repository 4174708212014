import React, { useEffect, useState, useRef } from 'react';
import './RequestsTable.css';

import {
  BaseTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  BaseButton,
  TextField,
  ScrollTrigger,
} from 'components';

import api from 'services/api';
import { toast, ToastContainer } from 'react-toastify';
import { getCatchedErrors, ErrorObject } from 'helpers/apiHelper';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

interface Request {
  name: string;
  id: number;
  status: 'pending' | 'processed' | 'canceled';
  file_id: number;
  type: 'desiccation' | 'soil_sampling';
  area: {
    id: number;
    name: string;
    total_area_m2: number;
    farm: {
      id: number;
      name: string;
    };
  };
  updated_at: string;
}

function RequestsTable(): JSX.Element {
  const dispatch = useDispatch();

  const [tablesPagination, setTablesPagination] = useState({
    processed: {
      currPage: 1,
      lastPage: false,
      filter: '',
      isLoading: false,
      tBodyRef: useRef<HTMLTableSectionElement>(null),
    },
    pending: {
      currPage: 1,
      lastPage: false,
      filter: '',
      isLoading: false,
      tBodyRef: useRef<HTMLTableSectionElement>(null),
    },
  });

  const [processedRequests, setProcessedRequests] = useState<Request[]>([]);
  const [pendingRequests, setPendingRequests] = useState<Request[]>([]);

  const typeMap = {
    desiccation: 'Dessecação',
    soil_sampling: 'Amostragem de Solo',
  };

  const loadRequests = async (
    page: number,
    status: 'processed' | 'pending',
  ): Promise<void> => {
    const perPage = 15;
    const { filter } = tablesPagination[status];
    setTablesPagination(prev => ({
      ...prev,
      [status]: { ...prev[status], isLoading: true },
    }));

    try {
      const { data } = await api.get(
        `customer/map-requests?status=${status}&page=${page}&perPage=${perPage}&areaName=${filter}`,
      );

      const { requests, lastPage } = data;
      setTablesPagination(prev => ({
        ...prev,
        [status]: { ...prev[status], lastPage },
      }));

      if (status === 'processed') {
        setProcessedRequests(prev =>
          page === 1 ? requests : [...prev, ...requests],
        );
      } else {
        setPendingRequests(prev =>
          page === 1 ? requests : [...prev, ...requests],
        );
      }
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao carregar as solicitações. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
    setTablesPagination(prev => ({
      ...prev,
      [status]: { ...prev[status], isLoading: false },
    }));
  };

  useEffect(() => {
    const { currPage, lastPage } = tablesPagination.processed;
    if (!lastPage) loadRequests(currPage, 'processed');
  }, [tablesPagination.processed.currPage, tablesPagination.processed.filter]);

  useEffect(() => {
    const { currPage, lastPage } = tablesPagination.pending;
    if (!lastPage) loadRequests(currPage, 'pending');
  }, [tablesPagination.pending.currPage, tablesPagination.pending.filter]);

  const handlePagination = (status: 'processed' | 'pending'): void => {
    const { lastPage } = tablesPagination[status];
    if (!lastPage) {
      setTablesPagination(prev => ({
        ...prev,
        [status]: {
          ...prev[status],
          currPage: prev[status].currPage + 1,
        },
      }));
    }
  };

  const handleDownloadMapFile = async (request: Request): Promise<void> => {
    const { id, area } = request;

    dispatch(setLoader(true));
    try {
      const { data }: { data: APIFile } = await api.get(
        `customer/farms/${area.farm.id}/areas/${area.id}/map-requests/${id}/map-file`,
      );
      if (!data.url) return;

      const link = document.createElement('a');
      link.href = data.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao baixar o arquivo. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  return (
    <div className="container-fluid p-4 overflow-auto">
      <div className="row mb-2">
        <div className="col-12">
          <h3 className="text-muted">Solicitações de Mapa</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-4">
          <div className="card">
            <div className="d-flex card-header justify-content-between">
              <h5 className="card-title mt-1">
                Solicitações{' '}
                <span className="processed-status">Concluídas</span>
              </h5>
              <TextField
                icon="fas fa-search"
                placeholder="Filtrar por área..."
                size="small"
                onChange={e => {
                  setTablesPagination(prev => ({
                    ...prev,
                    processed: {
                      ...prev.processed,
                      filter: e.target.value,
                      currPage: 1,
                      lastPage: false,
                    },
                  }));
                }}
                autoComplete="off"
              />
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <BaseTable className="table table-responsive m-0">
                  <TableHead className="thead-light">
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Tipo</TableCell>
                      <TableCell>Fazenda</TableCell>
                      <TableCell>Nome da Área</TableCell>
                      <TableCell>Área (ha)</TableCell>
                      <TableCell>Download</TableCell>
                    </TableRow>
                  </TableHead>
                  <ScrollTrigger
                    forwardedRef={tablesPagination.processed.tBodyRef}
                    onScrollEnd={() => {
                      handlePagination('processed');
                    }}
                  >
                    <TableBody
                      ref={tablesPagination.processed.tBodyRef}
                      style={{
                        maxHeight: '30vh',
                        minWidth: '900px',
                        overflowY: 'auto',
                        overflowX: 'auto',
                      }}
                    >
                      {processedRequests.map((request: Request, index) => (
                        <TableRow key={request.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {new Date(request.updated_at).toLocaleDateString()}
                          </TableCell>
                          <TableCell>{request.name}</TableCell>
                          <TableCell>{typeMap[request.type]}</TableCell>
                          <TableCell>{request.area.farm.name}</TableCell>
                          <TableCell>{request.area.name}</TableCell>
                          <TableCell>
                            {(request.area.total_area_m2 / 10000).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            <BaseButton
                              size="sm"
                              color="inherit"
                              disabled={request.status !== 'processed'}
                              onClick={(): void => {
                                handleDownloadMapFile(request);
                              }}
                              iconOnly
                            >
                              <i className="fas fa-download" />
                            </BaseButton>
                          </TableCell>
                        </TableRow>
                      ))}

                      {(tablesPagination.processed.isLoading ||
                        !tablesPagination.processed.lastPage) && (
                        <TableRow>
                          <TableCell>
                            {tablesPagination.processed.isLoading && (
                              <div className="d-flex justify-content-center">
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            )}
                            {!tablesPagination.processed.isLoading &&
                              !tablesPagination.processed.lastPage && (
                                <div className="d-flex justify-content-center">
                                  <button
                                    aria-label="Carregar mais transações"
                                    type="button"
                                    className="btn btn-link"
                                    onClick={() => {
                                      setTablesPagination(prev => ({
                                        ...prev,
                                        processed: {
                                          ...prev.processed,
                                          currPage: prev.processed.currPage + 1,
                                        },
                                      }));
                                    }}
                                  >
                                    Carregar mais...
                                  </button>
                                </div>
                              )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </ScrollTrigger>
                </BaseTable>
                {!processedRequests.length && (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: '50px' }}
                  >
                    <h4>
                      <small className="text-muted">
                        Nenhuma solicitação concluída
                      </small>
                    </h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="card">
            <div className="d-flex card-header justify-content-between">
              <h5 className="card-title mt-1">
                Solicitações <span className="pending-status">Em Análise</span>
              </h5>
              <TextField
                icon="fas fa-search"
                placeholder="Filtrar por área..."
                size="small"
                onChange={e => {
                  setTablesPagination(prev => ({
                    ...prev,
                    pending: {
                      ...prev.pending,
                      filter: e.target.value,
                      currPage: 1,
                      lastPage: false,
                    },
                  }));
                }}
                autoComplete="off"
              />
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <BaseTable className="table m-0">
                  <TableHead className="thead-light">
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Tipo</TableCell>
                      <TableCell>Fazenda</TableCell>
                      <TableCell>Nome da Área</TableCell>
                      <TableCell>Área (ha)</TableCell>
                    </TableRow>
                  </TableHead>
                  <ScrollTrigger
                    forwardedRef={tablesPagination.pending.tBodyRef}
                    onScrollEnd={() => {
                      handlePagination('pending');
                    }}
                  >
                    <TableBody
                      ref={tablesPagination.pending.tBodyRef}
                      style={{
                        maxHeight: '30vh',
                        minWidth: '900px',
                        overflowY: 'auto',
                        overflowX: 'auto',
                      }}
                    >
                      {pendingRequests.map((request: Request, index) => (
                        <TableRow key={request.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {new Date(request.updated_at).toLocaleDateString()}
                          </TableCell>
                          <TableCell>{request.name}</TableCell>
                          <TableCell>{typeMap[request.type]}</TableCell>
                          <TableCell>{request.area.farm.name}</TableCell>
                          <TableCell>{request.area.name}</TableCell>
                          <TableCell>
                            {(request.area.total_area_m2 / 10000).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}

                      {(tablesPagination.pending.isLoading ||
                        !tablesPagination.pending.lastPage) && (
                        <TableRow>
                          <TableCell>
                            {tablesPagination.pending.isLoading && (
                              <div className="d-flex justify-content-center">
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            )}
                            {!tablesPagination.pending.isLoading &&
                              !tablesPagination.pending.lastPage && (
                                <div className="d-flex justify-content-center">
                                  <button
                                    aria-label="Carregar mais transações"
                                    type="button"
                                    className="btn btn-link"
                                    onClick={() => {
                                      setTablesPagination(prev => ({
                                        ...prev,
                                        pending: {
                                          ...prev.pending,
                                          currPage: prev.pending.currPage + 1,
                                        },
                                      }));
                                    }}
                                  >
                                    Carregar mais...
                                  </button>
                                </div>
                              )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </ScrollTrigger>
                </BaseTable>
                {!pendingRequests.length && (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: '50px' }}
                  >
                    <h4>
                      <small className="text-muted">
                        Nenhuma solicitação em análise
                      </small>
                    </h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default RequestsTable;
