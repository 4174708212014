import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './SelectiveSprayingRequestForm.css';

// eslint-disable-next-line import/no-unresolved
import { GeoJsonObject } from 'geojson';
import { InteractiveMap, BaseModal, BaseButton } from 'components';

import api from 'services/api';
import { getCatchedErrors, ErrorObject } from 'helpers/apiHelper';
import { toast, ToastContainer } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

import FormSidebar, {
  MapRequest,
  SelectiveSprayingRequest,
} from '../FormSidebar';

function SelectiveSprayingRequestForm(): JSX.Element {
  const { farmId, areaId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [mapCenter, setMapCenter] = useState<[number, number]>([
    -29.711025188644363, -53.71634781360627,
  ]);
  const [mapGeoJsonLayer, setMapGeoJsonLayer] = useState<GeoJsonObject | null>(
    null,
  );

  const [totalAreaHa, setTotalAreaHa] = useState<number>(0);
  const [balanceTotalUsages, setBalanceTotalUsages] = useState<number>(0);

  const [confirmStoreRequestModal, setConfirmStoreRequestModal] = useState({
    open: false,
    generalData: {} as MapRequest,
    specializedData: {} as SelectiveSprayingRequest,
    areaId,
  });

  useEffect(() => {
    const handleLoadArea = async (): Promise<void> => {
      dispatch(setLoader(true));
      try {
        const { data } = await api.get(
          `customer/farms/${farmId}/areas/${areaId}`,
        );

        const coordinates = JSON.parse(data.center_coordinates);
        setMapCenter([coordinates[1], coordinates[0]]);
        setMapGeoJsonLayer(JSON.parse(data.geojson));
        setTotalAreaHa(data.total_area_m2 / 10000);
      } catch (e) {
        const errors: ErrorObject[] = getCatchedErrors(e);
        toast.error(
          errors[0].message ||
            'Ocorreu um erro ao carregar a área. Por favor tente novamente mais tarde',
          {
            position: 'bottom-center',
          },
        );
      }
      dispatch(setLoader(false));
    };

    handleLoadArea();
  }, [areaId, farmId]);

  const handleOpenConfirmStoreRequestModal = async (
    generalData: MapRequest,
    specializedData: SelectiveSprayingRequest,
  ): Promise<void> => {
    setBalanceTotalUsages(totalAreaHa);
    setConfirmStoreRequestModal({
      open: false,
      generalData,
      specializedData,
      areaId,
    });
  };

  const handleCloseConfirmStoreRequestModal = (): void => {
    setConfirmStoreRequestModal({
      open: false,
      generalData: {} as MapRequest,
      specializedData: {} as SelectiveSprayingRequest,
      areaId,
    });
  };

  /* const handleStoreRequest = async (): Promise<void> => {
    const { generalData, specializedData } = confirmStoreRequestModal;

    dispatch(setLoader(true));
    try {
      const endpoint = 'selective-spraying-requests';

      await api.post(
        `customer/farms/${farmId}/areas/${areaId}/${endpoint}?multiple_areas=false`,
        { ...generalData, ...specializedData },
      );

      toast.success('Solicitação enviada com sucesso!', {
        position: 'bottom-center',
      });

      navigate(`/customer/farms/${farmId}/areas/${areaId}/requests`);
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao finalizar a solicitação. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );

      handleCloseConfirmStoreRequestModal();
    }
    dispatch(setLoader(false));
  }; */

  return (
    <div className="d-flex container-fluid h-100 p-0 flex-column flex-lg-row">
      <div className="d-none d-lg-block request-form-sidebar">
        <FormSidebar
          type="desktop"
          formType="form"
          areaId={Number(areaId)}
          farmId={Number(farmId)}
          onSubmit={handleOpenConfirmStoreRequestModal}
        />
      </div>
      <div className="request-form-map-container">
        <div className="d-lg-none request-form-map-container__header">
          <div className="d-flex text-center justify-content-between">
            <div
              className="icon-button my-auto"
              tabIndex={0}
              role="button"
              onKeyUp={() => {}}
              onClick={() =>
                navigate(`/customer/farms/${farmId}/areas/${areaId}/requests`)
              }
            >
              <i className="fas fa-arrow-left" style={{ marginRight: '5px' }} />{' '}
              Voltar
            </div>
          </div>
        </div>
        <InteractiveMap
          center={mapCenter}
          markerPosition={mapCenter}
          geoJson={mapGeoJsonLayer}
          zoom={15}
        />
      </div>
      <div className="d-lg-none">
        <FormSidebar
          className="d-flex d-lg-none"
          type="mobile"
          formType="form"
          areaId={Number(areaId)}
          farmId={Number(farmId)}
          onSubmit={handleOpenConfirmStoreRequestModal}
        />
      </div>
      <BaseModal
        size="sm"
        open={confirmStoreRequestModal.open}
        onClose={handleCloseConfirmStoreRequestModal}
      >
        <div className="modal-body p-3">
          <div className="row">
            <div className="d-flex col-12 text-center flex-column">
              <span className="text-success">
                <i className="fas fa-save fa-4x" />
              </span>
              <b className="text-success mt-2 mb-4">Confirmar Solicitação</b>
            </div>
            <div className="col-12 text-center text-muted">
              <span className="text-muted">
                Você <strong className="text-success">confirma</strong> a
                solicitação de mapa de pulverização seletiva para{' '}
                <span>a área selecionada?</span>
              </span>{' '}
              Esta transação consumirá{' '}
              <strong className="text-success">
                {balanceTotalUsages.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{' '}
                ha
              </strong>{' '}
              do seu saldo.
            </div>
          </div>
        </div>
        <div className="modal-footer w-100">
          <div className="row">
            <div className="d-flex col-12 justify-content-end align-items-center">
              <span
                className="exit-modal-btn"
                tabIndex={0}
                role="button"
                onKeyUp={() => {}}
                onClick={handleCloseConfirmStoreRequestModal}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </span>
              <BaseButton onClick={() => {}}>
                Confirmar{' '}
                <span className="d-none d-sm-inline">Solicitação</span>
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>

      <ToastContainer />
    </div>
  );
}

export default SelectiveSprayingRequestForm;
