import React, { useState, useRef, useEffect } from 'react';
import Sheet, { SheetRef } from 'react-modal-sheet';

import './BottomSheet.css';

interface BottomSheetProps {
  className?: string;
  open?: boolean;
  children?: React.ReactNode;
  scrollRef?: React.RefObject<HTMLDivElement>;
  title?: string;
  controlBtn?: React.ReactElement;
  initialState?: 'closed' | 'middle-opened' | 'opened';
  onChange?: (open: boolean) => void;
}

function BottomSheet({
  className,
  children,
  open,
  scrollRef,
  title,
  controlBtn,
  initialState,
  onChange,
}: BottomSheetProps): JSX.Element {
  const ref = useRef<SheetRef>();
  const lastState = useRef(open);
  const [currentSnap, setCurrentSnap] = useState(0);

  const closeSheetPoint = 2;
  const initialStateMap = {
    closed: closeSheetPoint,
    'middle-opened': 1,
    opened: 0,
  };

  const snapTo = (i: number): void => {
    ref.current?.snapTo(i);
    setCurrentSnap(i);
  };

  useEffect(() => {
    if (open !== lastState.current) {
      snapTo(open ? 1 : closeSheetPoint);
      lastState.current = open;
    }
  }, [open]);

  const stateControl = (snapIndex: number): void => {
    setCurrentSnap(snapIndex);
    if (lastState.current === false && snapIndex !== closeSheetPoint) {
      lastState.current = true;
      if (onChange) onChange(true);
    } else if (lastState.current === true && snapIndex === closeSheetPoint) {
      lastState.current = false;
      if (onChange) onChange(false);
    }
  };
  return (
    <div className="container-fluid h-100">
      <Sheet
        className={className}
        ref={ref}
        onClose={() => snapTo(closeSheetPoint)}
        snapPoints={[800, 450, 40]}
        initialSnap={initialStateMap[initialState || 'closed']}
        onSnap={stateControl}
        isOpen
      >
        <Sheet.Container>
          <Sheet.Header>
            <div className="bottom-sheet-header">
              <span className="bottom-sheet-header-title text-muted">
                {title}
              </span>
              <i className="fas fa-minus draggable-icon text-muted" />
              {controlBtn && controlBtn}
            </div>
          </Sheet.Header>
          <Sheet.Content
            disableDrag={false}
            style={{ paddingBottom: ref.current?.y }}
          >
            <Sheet.Scroller ref={scrollRef} draggableAt="both">
              {children}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        {currentSnap !== closeSheetPoint ? (
          <Sheet.Backdrop onTap={() => snapTo(closeSheetPoint)} />
        ) : (
          <meta name="empty" />
        )}
      </Sheet>
    </div>
  );
}

BottomSheet.defaultProps = {
  className: '',
  title: '',
  initialState: 'closed',
  open: false,
  children: null,
  scrollRef: null,
  controlBtn: null,
  onChange: () => {},
};

export default BottomSheet;
