import React, { useEffect } from 'react';
import './Navbar.css';

import { useNavigate } from 'react-router-dom';

import api from 'services/api';
import { getCatchedErrors, ErrorObject } from 'helpers/apiHelper';
import { toast, ToastContainer } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import { State, setBalance } from 'store';

import { signOut, getUserInfo } from 'services/auth';
import { BaseMenu, BalanceSummary } from 'components';

function Navbar(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = getUserInfo();
  const balance = useSelector((state: State) => state.balance);

  const loadCustomerBalance = async (): Promise<void> => {
    try {
      const { data }: { data: { value: number } } = await api.get(
        '/customer/hectare-balances/self',
      );

      dispatch(setBalance(data.value));
    } catch (e) {
      const errors: ErrorObject[] = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Ocorreu um erro ao carregar seu saldo atual. Por favor tente novamente mais tarde',
        {
          position: 'bottom-center',
        },
      );
    }
  };

  useEffect(() => {
    loadCustomerBalance();
  }, []);

  const formatName = (
    name: string | null | undefined,
    mode: 'initials' | 'abbreviation',
  ): string => {
    if (!name) return 'Usuário';

    const names = name.split(' ');
    if (names.length === 1) return names[0];
    if (mode === 'initials') {
      return `${names[0][0]} ${names[names.length - 1][0]}`;
    }

    return `${names[0]} ${names[names.length - 1]}`;
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg p-0">
        <a href="/customer/farms">
          <img className="logo" alt="Logo da Aufarm" src="/aufarm_logo.png" />
        </a>
        <div className="d-flex flex-grow-1 align-items-center justify-content-end">
          {!user?.isEmailVerified && (
            <div className="d-none d-lg-block verify-email">
              <i className="fas fa-envelope" style={{ marginRight: '5px' }} />
              <button
                className="text-black"
                type="button"
                onClick={() => {
                  navigate('/customer/verify-email');
                }}
                style={{
                  border: 'none',
                  background: 'none',
                  padding: '0',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                <strong>Verifique seu email e ganhe 30ha</strong>
              </button>
            </div>
          )}
          <div className="balance-summary-wrapper">
            <BalanceSummary
              balance={balance}
              purchasePath="/customer/available-packages"
              responsiveOnXSmall
            />
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ marginRight: '10px' }}
          >
            <i className="fas fa-bars" />
          </button>
        </div>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav px-1">
            <button
              className="nav-item nav-link d-block d-lg-none"
              type="button"
              onClick={() => {
                navigate('/customer/profile');
              }}
            >
              Perfil
            </button>
            <button
              className="nav-item nav-link"
              type="button"
              onClick={() => {
                navigate('/customer/farms');
              }}
            >
              Dashboard
            </button>
            <button
              className="nav-item nav-link"
              type="button"
              onClick={() => {
                navigate('/customer/requests-table');
              }}
            >
              Solicitações de Mapas
            </button>
            <button
              className="nav-item nav-link d-block d-lg-none"
              type="button"
              onClick={() => {
                navigate('/customer/available-packages');
              }}
            >
              Comprar Crédito
            </button>
            <button
              className="nav-item nav-link d-block d-lg-none"
              type="button"
              onClick={() => {
                navigate('/customer/balance');
              }}
            >
              Extrato de Crédito
            </button>
            <button
              className="nav-item nav-link d-block d-lg-none active"
              type="button"
              onClick={signOut}
            >
              Sair
            </button>
            {!user?.isEmailVerified && (
              <div className="nav-item d-block d-lg-none verify-email mb-2 text-center">
                <button
                  className="text-black"
                  type="button"
                  onClick={() => {
                    navigate('/customer/verify-email');
                  }}
                  style={{
                    border: 'none',
                    background: 'none',
                    padding: '0',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  <strong>Verifique seu email e ganhe 30ha</strong>
                </button>
              </div>
            )}
          </div>
          <div className="d-none d-lg-block">
            <BaseMenu label={formatName(user?.name, 'initials')}>
              <option onClick={() => {}} disabled>
                {formatName(user?.name, 'abbreviation')}
              </option>
              <option
                onClick={() => {
                  navigate('/customer/profile');
                }}
              >
                Perfil
              </option>
              <option
                onClick={() => {
                  navigate('/customer/available-packages');
                }}
              >
                Comprar Crédito
              </option>
              <option
                onClick={() => {
                  navigate('/customer/balance');
                }}
              >
                Extrato de Crédito
              </option>
              <option
                onClick={() => {
                  navigate('/customer/requests-table');
                }}
              >
                Minhas Solicitações
              </option>
              <option onClick={signOut}>Sair</option>
            </BaseMenu>
          </div>
        </div>
      </nav>
      <ToastContainer />
    </>
  );
}

export default Navbar;
