import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './BalanceView.css';

import { State } from 'store';
import { getUserInfo } from 'services/auth';

import api from 'services/api';
import { getCatchedErrors } from 'helpers/apiHelper';
import { ToastContainer, toast } from 'react-toastify';

import {
  StatsCard,
  BaseTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  BaseButton,
  ScrollTrigger,
} from 'components';

type Transaction = {
  id: number;
  type: 'usage_transaction' | 'bonus_transaction' | 'purchase_transaction';
  ha_amount: number;
  created_at: Date;
  usageTransaction?: {
    id: number;
    mapRequest?: {
      id: number;
      type: 'desiccation' | 'soil_sampling';
    };
  };
  purchaseTransaction?: {
    id: number;
    status:
      | 'PENDING'
      | 'RECEIVED'
      | 'CONFIRMED'
      | 'OVERDUE'
      | 'REFUNDED'
      | 'REFUND_IN_PROGRESS';
  };
};

function BalanceView(): JSX.Element {
  const navigate = useNavigate();
  const balance = useSelector((state: State) => state.balance);
  const tBodyRef = useRef<HTMLTableSectionElement>(null);
  const user = getUserInfo();

  const [currPage, setCurrPage] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(false);

  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const trxTypeMap: { [key: string]: string } = {
    usage_transaction: 'Solicitação de Mapa',
    bonus_transaction: 'Bonificação',
    purchase_transaction: 'Compra de Crédito',
  };

  const mapRequestTypeMap: { [key: string]: string } = {
    desiccation: 'Dessecação Localizada',
    soil_sampling: 'Amostragem de Solo',
  };

  const loadCustomerTransactions = async (page: number): Promise<void> => {
    const perPage = 15;
    setLoadingTransactions(true);
    try {
      const { data } = await api.get(
        `customer/transactions?page=${page}&perPage=${perPage}`,
      );
      setTransactions(prev =>
        page === 1 ? data.transactions : [...prev, ...data.transactions],
      );
      setLastPage(data.lastPage);
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao carregar os dados. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    setLoadingTransactions(false);
  };

  useEffect(() => {
    if (!lastPage) loadCustomerTransactions(currPage);
  }, [currPage]);

  const getTransactionType = (transaction: Transaction): string => {
    if (
      transaction.type === 'usage_transaction' &&
      transaction.usageTransaction?.mapRequest?.type
    ) {
      return mapRequestTypeMap[transaction.usageTransaction.mapRequest.type];
    }

    return trxTypeMap[transaction.type];
  };

  const getValueClass = (transaction: Transaction): string => {
    if (transaction.type === 'usage_transaction') {
      return 'text-danger';
    }

    if (transaction.type === 'purchase_transaction') {
      switch (transaction.purchaseTransaction?.status) {
        case 'PENDING':
          return 'text-info';
        case 'OVERDUE':
          return 'text-secondary';
        default:
      }
    }

    return 'text-success';
  };

  return (
    <div className="container-fluid p-4 overflow-auto">
      <div className="row">
        <div className="col-12 col-md-6 col-xl-4 mb-4">
          <StatsCard
            title={`${user?.name || 'Usuário'}, este é seu saldo atual.`}
            titleType="largeMuted"
            value={`${balance.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ha`}
            description="Este é seu saldo para solicitações de mapas."
            spacing="sm"
            icon="fas fa-scale-balanced"
          />
        </div>
        <div className="col-12">
          <div className="card">
            <div className="d-flex card-header justify-content-between">
              <h5 className="card-title mt-1">
                Histórico{' '}
                <span className="d-none d-md-block">de Utilização</span>
              </h5>
              <BaseButton
                size="sm"
                onClick={() => navigate('/customer/available-packages')}
              >
                <span className="d-none d-md-block">
                  <i className="fas fa-plus" style={{ marginRight: '10px' }} />
                  Comprar Créditos
                </span>
                <span className="d-md-none" style={{ fontSize: '15px' }}>
                  <i className="fas fa-plus" style={{ marginRight: '10px' }} />
                  Créditos
                </span>
              </BaseButton>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <BaseTable
                  className="table m-0"
                  style={{
                    height: '100px',
                  }}
                >
                  <TableHead className="thead-light">
                    <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell>Solicitação</TableCell>
                      <TableCell>Hectares (ha)</TableCell>
                    </TableRow>
                  </TableHead>
                  <ScrollTrigger
                    forwardedRef={tBodyRef}
                    onScrollEnd={() => {
                      if (!lastPage) setCurrPage(prev => prev + 1);
                    }}
                  >
                    <TableBody
                      ref={tBodyRef}
                      style={{
                        maxHeight: '50vh',
                        minWidth: '400px',
                        overflowY: 'auto',
                        overflowX: 'auto',
                      }}
                    >
                      {transactions.map((transaction: Transaction) => (
                        <TableRow key={transaction.id}>
                          <TableCell>
                            {new Date(
                              transaction.created_at,
                            ).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            {getTransactionType(transaction)}
                          </TableCell>
                          <TableCell>
                            <span className={getValueClass(transaction)}>
                              {`${transaction.ha_amount.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              )}`}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}

                      {(loadingTransactions || !lastPage) && (
                        <TableRow>
                          <TableCell>
                            {loadingTransactions && (
                              <div className="d-flex justify-content-center">
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            )}
                            {!loadingTransactions && !lastPage && (
                              <div className="d-flex justify-content-center">
                                <button
                                  aria-label="Carregar mais transações"
                                  type="button"
                                  className="btn btn-link"
                                  onClick={() => setCurrPage(prev => prev + 1)}
                                >
                                  Carregar mais...
                                </button>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </ScrollTrigger>
                </BaseTable>
                {!transactions.length && (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: '50px' }}
                  >
                    <h4>
                      <small className="text-muted">
                        Nenhuma transação efetuada.
                      </small>
                    </h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default BalanceView;
