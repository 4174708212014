import React from 'react';
import { Route } from 'react-router-dom';

import AdminLayout from 'layout/Admin/AdminLayout';
import Dashboard from 'views/Admin/Dashboard/Dashboard';

import BalanceView from 'views/Admin/Balance/BalanceView/BalanceView';
import BonificationsView from 'views/Admin/Bonifications/BonificationsView/BonificationsView';

import PackagesView from 'views/Admin/Packages/PackagesView/PackagesView';

import CustomerView from 'views/Admin/Customer/CustomerView/CustomerView';

const AdminRoutes = (
  <Route path="/admin">
    <Route
      path="dashboard"
      element={
        <AdminLayout>
          <Dashboard />
        </AdminLayout>
      }
    />

    {/* Balance Routes */}
    <Route
      path="balances"
      element={
        <AdminLayout>
          <BalanceView />
        </AdminLayout>
      }
    />

    {/* Bonifications Routes */}
    <Route
      path="bonifications"
      element={
        <AdminLayout>
          <BonificationsView />
        </AdminLayout>
      }
    />

    {/* Packages Routes */}
    <Route
      path="packages"
      element={
        <AdminLayout>
          <PackagesView />
        </AdminLayout>
      }
    />

    {/* Customer Routes */}
    <Route
      path="customers/:customerId"
      element={
        <AdminLayout>
          <CustomerView />
        </AdminLayout>
      }
    />
  </Route>
);

export default AdminRoutes;
