import api from 'services/api';
import { toast } from 'react-toastify';
import { getCatchedErrors, ErrorObject } from 'helpers/apiHelper';

export const getCityLocation = async (
  city: string,
): Promise<[number, number]> => {
  try {
    const { data } = await api.get(
      `https://nominatim.openstreetmap.org/search?q=${city}&format=jsonv2`,
    );
    const [first] = data;
    return [first.lat, first.lon];
  } catch (e) {
    const errors: ErrorObject[] = getCatchedErrors(e);
    toast.error(
      errors[0].message ||
        'Ocorreu um erro ao carregar a localização desta cidade. Confira se o nome está correto e tente novamente.',
      {
        position: 'bottom-center',
      },
    );
    return [0, 0];
  }
};
