import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfileView.css';

import { Box } from '@mui/material';
import {
  TextField,
  BaseButton,
  BaseModal,
  Tab,
  Tabs,
  TabPanel,
  BaseInput,
  ProfilePicture,
} from 'components';

import api from 'services/api';
import { getUserInfo, setUserInfo } from 'services/auth';
import { getCatchedErrors } from 'helpers/apiHelper';
import { ToastContainer, toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setLoader } from 'store';

type Customer = {
  id: number;
  user_id: number;
  name: string;
  identification_number: string;
  type: 'natural' | 'legal';
  phone: string;
  cep: string;
  street: string;
  number: string;
  district: string;
  city: string;
  state: string;
  complement: string;
  country: string;
  profilePicture?: {
    url: string;
    name: string;
  };
};

function ProfileView(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userInfo = getUserInfo();
  const [customerInfo, setCustomerInfo] = useState<Customer>();

  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [changePhoneModal, setChangePhoneModal] = useState(false);
  const [changeEmailModal, setChangeEmailModal] = useState(false);

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  const [newPhoneNumber, setNewPhoneNumber] = useState<string>('');
  const [phoneToken, setPhoneToken] = useState<string>('');
  const [phoneVerificationId, setPhoneVerificationId] = useState<number>(0);
  const [currentPhoneTab, setCurrentPhoneTab] = useState(0);

  const [newEmail, setNewEmail] = useState<string>('');
  const [emailToken, setEmailToken] = useState<string>('');
  const [emailVerificationId, setEmailVerificationId] = useState<number>(0);
  const [currentEmailTab, setCurrentEmailTab] = useState(0);

  const [counter, setCounter] = useState(60);
  const [counterRuning, setCounterRuning] = useState(true);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const handleLoadCustomer = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { data } = await api.get('/customer/self');
      setCustomerInfo(data);
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao carregar os dados. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  useEffect(() => {
    handleLoadCustomer();
  }, []);

  useEffect(() => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/;

    if (newPassword === '' || passwordRegex.test(newPassword)) {
      setPasswordError('');
    } else {
      setPasswordError(
        'A nova senha deve conter no mínimo 8 caracteres, 1 letra maiúscula, 1 número e 1 caractere especial (@$!%*?&-)',
      );
    }
  }, [newPassword]);

  const startCounter = (): void => {
    setCounterRuning(true);

    intervalRef.current = setInterval(() => {
      setCounter(prevCounter => prevCounter - 1);
    }, 1000);

    timeoutRef.current = setTimeout(() => {
      clearInterval(intervalRef.current!);
      setCounterRuning(false);
      setCounter(60);
    }, 60000);
  };

  const resetCounter = (): void => {
    clearInterval(intervalRef.current!);
    clearTimeout(timeoutRef.current!);

    setCounterRuning(false);
    setCounter(60);
  };

  const handleCloseChangePasswordModal = (): void => {
    setChangePasswordModal(false);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };

  const handleCloseChangePhoneModal = (): void => {
    resetCounter();
    setChangePhoneModal(false);
    setCurrentPhoneTab(0);
    setNewPhoneNumber('');
  };

  const handleCloseChangeEmailModal = (): void => {
    setChangeEmailModal(false);
    setCurrentEmailTab(0);
    setNewEmail('');
    setEmailToken('');
    setEmailVerificationId(0);
  };

  const handleStoreNewPassword = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      await api.put('/customer/self/change-password', {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_new_password: confirmNewPassword,
      });

      toast.success('Senha alterada com sucesso!', {
        position: 'bottom-center',
      });

      handleCloseChangePasswordModal();
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao alterar a senha. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleSendPhoneVerification = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      const { data } = await api.post('/customer/self/change-phone/send-code', {
        phone: newPhoneNumber,
      });

      setPhoneVerificationId(data.id);
      setCurrentPhoneTab(1);
      startCounter();
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Código de verificação não enviado. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleResendPhoneVerification = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      await api.post('customer/self/change-phone/cancel-code', {
        id: phoneVerificationId,
      });
      const { data } = await api.post('/customer/self/change-phone/send-code', {
        phone: newPhoneNumber,
      });

      setPhoneVerificationId(data.id);
      startCounter();

      toast.success('Código reenviado com sucesso!', {
        position: 'bottom-center',
      });
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao reenviar o código de verificação. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleUpdatePhoneNumber = async (): Promise<void> => {
    dispatch(setLoader(true));
    try {
      await api.put('/customer/self/change-phone', {
        id: phoneVerificationId,
        token: phoneToken,
        phone: newPhoneNumber,
      });

      setCustomerInfo(prevCustomerInfo => ({
        ...prevCustomerInfo!,
        phone: newPhoneNumber,
      }));
      handleCloseChangePhoneModal();

      toast.success('Número de telefone alterado com sucesso!', {
        position: 'bottom-center',
      });
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao alterar o número de telefone. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleCancelPhoneVerification = async (): Promise<void> => {
    if (currentPhoneTab !== 1) {
      handleCloseChangePhoneModal();
      return;
    }

    dispatch(setLoader(true));
    try {
      await api.post('customer/self/change-phone/cancel-code', {
        id: phoneVerificationId,
      });

      handleCloseChangePhoneModal();
      toast.error(
        'Você cancelou a alteração de telefone. Você poderá tentar novamente em 30s',
        {
          position: 'bottom-center',
        },
      );
    } catch (error) {
      const errors = getCatchedErrors(error);
      toast.error(
        errors[0].message ||
          'Erro ao cancelar a verificação. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  const handleSendEmailVerification = async (): Promise<void> => {
    try {
      const { data } = await api.post('customer/self/change-email/send-code', {
        email: newEmail,
      });
      setEmailVerificationId(data.id);
      setCurrentEmailTab(1);
    } catch (e) {
      const errors = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Erro ao enviar email de verificação. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
      handleCloseChangeEmailModal();
    }
  };

  const handleUpdateEmail = async (): Promise<void> => {
    try {
      const { data } = await api.put(`customer/self/change-email`, {
        id: emailVerificationId,
        token: emailToken,
        email: newEmail,
      });

      toast.success('Email verificado com sucesso!', {
        position: 'bottom-center',
      });
      handleCloseChangeEmailModal();
      await handleLoadCustomer();
      setUserInfo({ ...userInfo!, email: data.email });
    } catch (e) {
      const errors = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Erro ao verificar email. Verifique o código e tente novamente.',
        {
          position: 'bottom-center',
        },
      );
    }
  };

  const handleUpdateProfilePicture = async (
    profilePicture: File | null,
    closeModal: () => void,
  ): Promise<void> => {
    if (!profilePicture) return;

    dispatch(setLoader(true));
    try {
      const formData = new FormData();
      formData.append('profile_picture', profilePicture);

      const { data } = await api.put(
        '/customer/self/profile-picture',
        formData,
      );

      setCustomerInfo(prevCustomerInfo => ({
        ...prevCustomerInfo!,
        profilePicture: data,
      }));

      toast.success('Foto de perfil atualizada com sucesso!', {
        position: 'bottom-center',
      });
      closeModal();
    } catch (e) {
      const errors = getCatchedErrors(e);
      toast.error(
        errors[0].message ||
          'Erro ao atualizar a foto de perfil. Tente novamente mais tarde.',
        {
          position: 'bottom-center',
        },
      );
    }
    dispatch(setLoader(false));
  };

  return (
    <div className="container-fluid p-4 overflow-auto">
      <div className="row mb-2">
        <div className="d-flex col-12 align-items-center">
          <ProfilePicture
            src={customerInfo?.profilePicture?.url}
            size="large"
            onUpdateProfilePic={handleUpdateProfilePicture}
            editable
          />
          <h3 className="text-muted">
            Este é seu perfil, <br />
            <span className="user-name">{userInfo?.name || 'Usuário'}</span>
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card mb-4">
            <div className="d-flex card-header justify-content-between">
              <h4 className="card-title text-muted">Dados pessoais</h4>
              <div className="d-none d-md-flex">
                <span
                  style={{
                    marginRight: '10px',
                  }}
                >
                  <BaseButton
                    size="sm"
                    onClick={() => {
                      setChangePhoneModal(true);
                    }}
                  >
                    Editar Telefone
                    <i
                      className="fas fa-phone custom-phone-icon"
                      style={{
                        marginLeft: '10px',
                      }}
                    />{' '}
                  </BaseButton>
                </span>
                <span
                  style={{
                    marginRight: '10px',
                  }}
                >
                  <BaseButton
                    size="sm"
                    onClick={() => {
                      setChangeEmailModal(true);
                    }}
                  >
                    Editar Email
                    <i
                      className="fas fa-envelope custom-envelope-icon"
                      style={{
                        marginLeft: '10px',
                      }}
                    />{' '}
                  </BaseButton>
                </span>
                <span>
                  <BaseButton
                    size="sm"
                    onClick={() => {
                      setChangePasswordModal(true);
                    }}
                  >
                    Editar senha
                    <i
                      className="fas fa-lock text-danger-light"
                      style={{
                        marginLeft: '10px',
                      }}
                    />{' '}
                  </BaseButton>
                </span>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <TextField
                    label="Nome"
                    value={userInfo?.name || ''}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <TextField
                    label="Email"
                    value={userInfo?.email || ''}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <TextField
                    name="identification_number"
                    label={customerInfo?.type === 'natural' ? 'CPF' : 'CNPJ'}
                    value={customerInfo?.identification_number || ''}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <TextField
                    name="phone"
                    label="Telefone"
                    value={customerInfo?.phone || ''}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mb-4">
          <div className="card">
            <div className="d-flex card-header justify-content-between">
              <h4 className="card-title text-muted">Endereço</h4>
              <BaseButton
                size="sm"
                onClick={() => {
                  navigate('/customer/profile/edit');
                }}
              >
                <span className="d-md-none px-2">
                  <i className="fas fa-edit text-info" />
                </span>
                <span className="d-none d-md-block px-2">
                  Editar Endereço
                  <i
                    className="fas fa-location-dot text-info"
                    style={{
                      marginLeft: '10px',
                    }}
                  />
                </span>
              </BaseButton>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <TextField
                    name="cep"
                    label="CEP"
                    value={customerInfo?.cep || ''}
                    disabled
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <TextField
                    name="country"
                    label="País"
                    value={customerInfo?.country || ''}
                    disabled
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <TextField
                    name="state"
                    label="Estado"
                    value={customerInfo?.state || ''}
                    disabled
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <TextField
                    name="city"
                    label="Cidade"
                    value={customerInfo?.city || ''}
                    disabled
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <TextField
                    name="district"
                    label="Bairro"
                    value={customerInfo?.district || ''}
                    disabled
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <TextField
                    name="street"
                    label="Rua"
                    value={customerInfo?.street || ''}
                    disabled
                  />
                </div>

                <div className="col-md-4 mb-4">
                  <TextField
                    name="number"
                    label="Número"
                    value={customerInfo?.number || ''}
                    disabled
                  />
                </div>
                <div className="col-md-8 mb-4">
                  <TextField
                    name="complement"
                    label="Complemento"
                    value={customerInfo?.complement || ''}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex d-md-none col-12 flex-column aling-items-center gap-3">
          <button
            className="text-muted"
            type="button"
            onClick={() => {
              setChangePasswordModal(true);
            }}
            style={{
              border: 'none',
              background: 'none',
              padding: '0',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            <i
              className="fas fa-lock text-danger-light"
              style={{
                marginRight: '10px',
              }}
            />
            <strong>Alterar senha</strong>
          </button>
          <button
            className="text-muted"
            type="button"
            onClick={() => {
              setChangeEmailModal(true);
            }}
            style={{
              border: 'none',
              background: 'none',
              padding: '0',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            <i
              className="fas fa-envelope custom-envelope-icon"
              style={{
                marginRight: '10px',
              }}
            />
            <strong>Alterar email</strong>
          </button>
          <button
            className="text-muted"
            type="button"
            onClick={() => {
              setChangePhoneModal(true);
            }}
            style={{
              border: 'none',
              background: 'none',
              padding: '0',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            <i
              className="fas fa-phone custom-phone-icon"
              style={{
                marginRight: '10px',
              }}
            />
            <strong>Alterar telefone</strong>
          </button>
        </div>
      </div>
      <BaseModal
        size="md"
        open={changePasswordModal}
        onClose={handleCloseChangePasswordModal}
      >
        <div className="modal-header">
          <span className="modal-title">
            <i className="fas fa-lock text-danger-light mr-2" /> Editar senha
          </span>
        </div>
        <div className="modal-body p-3">
          <div className="row">
            <div className="col-12 mb-4">
              <TextField
                label="Senha atual"
                type="password"
                value={currentPassword}
                onChange={e => setCurrentPassword(e.target.value)}
                showPasswordToggle
                required
              />
            </div>
            <div className="col-12 mb-4">
              <TextField
                label="Nova senha"
                type="password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                helperText={passwordError}
                error={!!passwordError}
                showPasswordToggle
                required
              />
            </div>
            <div className="col-12 mb-4">
              <TextField
                label="Confirmar nova senha"
                type="password"
                value={confirmNewPassword}
                onChange={e => setConfirmNewPassword(e.target.value)}
                helperText={
                  confirmNewPassword !== newPassword
                    ? 'As senhas não coincidem'
                    : ''
                }
                error={confirmNewPassword !== newPassword}
                showPasswordToggle
                required
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="row">
            <div className="d-flex col-12 justify-content-end align-items-center">
              <span
                className="exit-modal-btn"
                tabIndex={0}
                role="button"
                onKeyUp={() => {}}
                onClick={handleCloseChangePasswordModal}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </span>
              <BaseButton onClick={handleStoreNewPassword}>
                Salvar alterações
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>
      <BaseModal
        size="md"
        open={changePhoneModal}
        onClose={handleCancelPhoneVerification}
      >
        <div className="modal-header">
          <span className="modal-title">
            <i className="fas fa-phone text-success mr-2" /> Editar número de
            telefone
          </span>
        </div>
        <div className="modal-body p-3">
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
            <Tabs
              value={currentPhoneTab}
              onChange={(event, newValue) => setCurrentPhoneTab(newValue)}
              variant="fullWidth"
            >
              <Tab
                label="Passo 1"
                id="simple-tab-1"
                disabled={currentPhoneTab !== 0}
              />
              <Tab
                label="Passo 2"
                id="simple-tab-2"
                disabled={currentPhoneTab !== 1}
              />
            </Tabs>
          </Box>
          <TabPanel value={currentPhoneTab} index={0}>
            <div className="row">
              <div className="col-12">
                <p className="fs-6">Digite o novo número de telefone:</p>
              </div>
              <div className="col-12">
                <TextField
                  label="Novo número de Telefone"
                  value={newPhoneNumber}
                  mask="+99 (99) 99999-9999"
                  onChange={e => setNewPhoneNumber(e.target.value)}
                  required
                />
              </div>
              <div className="col-12 mt-4 text-center">
                <BaseButton onClick={handleSendPhoneVerification}>
                  Continuar
                </BaseButton>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={currentPhoneTab} index={1}>
            <div className="d-flex row justify-content-center">
              <div
                className="card px-0"
                style={{ borderRadius: 0, maxWidth: '700px' }}
              >
                <div className="card-header text-center p-3">
                  <h5>
                    <i
                      className="fas fa-phone default-green"
                      style={{ marginRight: '10px' }}
                    />
                    <span>Verificação de telefone</span>
                  </h5>
                  <p>
                    Enviamos um código de verificação para o número de telefone
                    especificado.
                  </p>
                  <p>
                    Após a correta verificação, o número de telefone da sua
                    conta será atualizado.
                  </p>
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="custom-counter">
                      00:{String(counter).padStart(2, '0')}
                    </span>

                    <BaseButton
                      size="sm"
                      onClick={handleResendPhoneVerification}
                      disabled={counterRuning}
                    >
                      Reenviar Código
                    </BaseButton>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="d-flex col-12 justify-content-center">
                      <div style={{ maxWidth: '200px' }}>
                        <BaseInput
                          name="code"
                          value={phoneToken}
                          label="Código"
                          placeholder="AABBCC"
                          onChange={e =>
                            setPhoneToken(e.target.value.toUpperCase())
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex card-footer justify-content-center">
                    <BaseButton size="lg" onClick={handleUpdatePhoneNumber}>
                      Verificar código
                    </BaseButton>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
        <div className="modal-footer">
          <div className="row">
            <div className="d-flex col-12 justify-content-center align-items-center">
              <span
                className="exit-modal-btn"
                tabIndex={0}
                role="button"
                onKeyUp={() => {}}
                onClick={handleCancelPhoneVerification}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </span>
            </div>
          </div>
        </div>
      </BaseModal>
      <BaseModal
        size="md"
        open={changeEmailModal}
        onClose={handleCloseChangeEmailModal}
      >
        <div className="modal-header">
          <span className="modal-title">
            <i className="fas fa-envelope text-success mr-2" /> Editar email
          </span>
        </div>
        <div className="modal-body p-3">
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
            <Tabs
              value={currentEmailTab}
              onChange={(event, newValue) => setCurrentEmailTab(newValue)}
              variant="fullWidth"
            >
              <Tab
                label="Passo 1"
                id="simple-tab-1"
                disabled={currentEmailTab !== 0}
              />
              <Tab
                label="Passo 2"
                id="simple-tab-2"
                disabled={currentEmailTab !== 1}
              />
            </Tabs>
          </Box>
          <TabPanel value={currentEmailTab} index={0}>
            <div className="row">
              <div className="col-12">
                <p className="fs-6">Digite o novo email:</p>
              </div>
              <div className="col-12">
                <TextField
                  label="Novo email"
                  value={newEmail}
                  onChange={e => setNewEmail(e.target.value)}
                  required
                />
              </div>
              <div className="col-12 mt-4 text-center">
                <BaseButton onClick={handleSendEmailVerification}>
                  Continuar
                </BaseButton>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={currentEmailTab} index={1}>
            <div className="d-flex row justify-content-center">
              <div
                className="card px-0"
                style={{ borderRadius: 0, maxWidth: '700px' }}
              >
                <div className="card-header text-center p-3">
                  <h5>
                    <i
                      className="fas fa-envelope default-green"
                      style={{ marginRight: '10px' }}
                    />
                    <span>Verificação de email</span>
                  </h5>
                  <p>
                    Enviamos um código de verificação para o email especificado.
                  </p>
                  <p>
                    Após a correta verificação, o email da sua conta será
                    atualizado.
                  </p>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="d-flex col-12 justify-content-center">
                      <div style={{ maxWidth: '200px' }}>
                        <BaseInput
                          name="code"
                          value={emailToken}
                          label="Código"
                          placeholder="AABBCC"
                          onChange={e =>
                            setEmailToken(e.target.value.toUpperCase())
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex card-footer justify-content-center">
                    <BaseButton size="lg" onClick={handleUpdateEmail}>
                      Verificar código
                    </BaseButton>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
        <div className="modal-footer">
          <div className="row">
            <div className="d-flex col-12 justify-content-center align-items-center">
              <span
                className="exit-modal-btn"
                tabIndex={0}
                role="button"
                onKeyUp={() => {}}
                onClick={handleCloseChangeEmailModal}
                style={{ marginRight: '15px' }}
              >
                Cancelar
              </span>
            </div>
          </div>
        </div>
      </BaseModal>
      <ToastContainer />
    </div>
  );
}

export default ProfileView;
