import React, { InputHTMLAttributes, useRef } from 'react';
import ReactInputMask from 'react-input-mask';
import { Input } from '@mui/material';

import './BaseInput.css';

interface BaseInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: boolean;
  mask?: string;
}

function BaseInput({
  name,
  placeholder,
  value,
  label,
  mask,
  type,
  error,
  disabled,
  autoComplete,
  onChange,
  onKeyUp,
  onBlur,
}: BaseInputProps): JSX.Element {
  const inputId = `input-${label.toLowerCase()}`;
  const inputMaskRef = useRef(null);
  return (
    <div className="input-container">
      <label htmlFor={inputId}>{label}</label>
      <ReactInputMask
        mask={mask || ''}
        value={value}
        disabled={disabled}
        ref={inputMaskRef}
        autoComplete={autoComplete}
        onChange={onChange}
        onBlur={onBlur}
      >
        <Input
          name={name}
          id={inputId}
          placeholder={placeholder}
          value={value}
          type={type}
          error={error}
          onChange={onChange}
          onKeyUp={onKeyUp}
        />
      </ReactInputMask>
    </div>
  );
}

BaseInput.defaultProps = {
  error: false,
  mask: '',
};

export default BaseInput;
