import React from 'react';
import { Route } from 'react-router-dom';
import CustomerLayout from 'layout/Customer/CustomerLayout';

import ProfileView from 'views/Customer/Profile/ProfileView/ProfileView';
import ProfileEdit from 'views/Customer/Profile/ProfileEdit/ProfileEdit';

import FarmList from 'views/Customer/Farm/FarmList/FarmList';
import FarmForm from 'views/Customer/Farm/FarmForm/FarmForm';
import FarmEdit from 'views/Customer/Farm/FarmEdit/FarmEdit';

import AreaList from 'views/Customer/Area/AreaList/AreaList';

import RequestsList from 'views/Customer/Requests/RequestsList/RequestsList';
import RequestsTable from 'views/Customer/Requests/RequestsTable/RequestsTable';

import DesiccationRequestForm from 'views/Customer/DesiccationRequest/DesiccationRequestForm/DesiccationRequestForm';
import SoilSamplingRequestForm from 'views/Customer/SoilSamplingRequest/SoilSamplingRequestForm/SoilSamplingRequestForm';
import SelectiveSprayingRequestForm from 'views/Customer/SelectiveSprayingRequest/SelectiveSprayingRequestForm/SelectiveSprayingRequestForm';

import BalanceView from 'views/Customer/Balance/BalanceView/BalanceView';
import AvailablePackagesView from 'views/Customer/AvailablePackages/AvailablePackagesView/AvailablePackagesView';
import PurchaseView from 'views/Customer/Purchase/PurchaseView/PurchaseView';

import EmailVerification from 'views/Customer/EmailVerification/EmailVerification';

import Teste from 'views/Customer/Teste/Teste';

const CustomerRoutes = (
  <Route path="/customer">
    {/* Profile Routes */}
    <Route
      path="profile"
      element={
        <CustomerLayout>
          <ProfileView />
        </CustomerLayout>
      }
    />
    <Route
      path="profile/edit"
      element={
        <CustomerLayout>
          <ProfileEdit />
        </CustomerLayout>
      }
    />

    {/* Farm Routes */}
    <Route
      path="farms"
      element={
        <CustomerLayout>
          <FarmList />
        </CustomerLayout>
      }
    />
    <Route
      path="farms/new"
      element={
        <CustomerLayout>
          <FarmForm />
        </CustomerLayout>
      }
    />
    <Route
      path="farms/:farmId/edit"
      element={
        <CustomerLayout>
          <FarmEdit />
        </CustomerLayout>
      }
    />

    {/* Area Routes */}
    <Route
      path="farms/:farmId/areas"
      element={
        <CustomerLayout>
          <AreaList />
        </CustomerLayout>
      }
    />

    {/* Request Routes */}
    <Route
      path="farms/:farmId/areas/:areaId/requests"
      element={
        <CustomerLayout>
          <RequestsList />
        </CustomerLayout>
      }
    />
    <Route
      path="requests-table"
      element={
        <CustomerLayout>
          <RequestsTable />
        </CustomerLayout>
      }
    />

    {/* Desiccation Request Routes */}
    <Route
      path="farms/:farmId/areas/:areaId/desiccation-requests/new"
      element={
        <CustomerLayout>
          <DesiccationRequestForm />
        </CustomerLayout>
      }
    />

    {/* Soil Sampling Request Routes */}
    <Route
      path="farms/:farmId/areas/:areaId/soil-sampling-requests/new"
      element={
        <CustomerLayout>
          <SoilSamplingRequestForm />
        </CustomerLayout>
      }
    />

    {/* Selective Spraying Request Routes */}
    <Route
      path="farms/:farmId/areas/:areaId/selective-spraying-requests/new"
      element={
        <CustomerLayout>
          <SelectiveSprayingRequestForm />
        </CustomerLayout>
      }
    />

    {/* Balance Routes */}
    <Route
      path="balance"
      element={
        <CustomerLayout>
          <BalanceView />
        </CustomerLayout>
      }
    />

    {/* Available Packages Routes */}
    <Route
      path="available-packages"
      element={
        <CustomerLayout>
          <AvailablePackagesView />
        </CustomerLayout>
      }
    />

    {/* Purchase Routes */}
    <Route
      path="purchase-package/:packageId"
      element={
        <CustomerLayout>
          <PurchaseView />
        </CustomerLayout>
      }
    />

    {/* Email Verification */}
    <Route
      path="verify-email"
      element={
        <CustomerLayout>
          <EmailVerification />
        </CustomerLayout>
      }
    />

    {/* Teste */}
    <Route
      path="teste"
      element={
        <CustomerLayout>
          <Teste />
        </CustomerLayout>
      }
    />
  </Route>
);

export default CustomerRoutes;
