import React, { HTMLAttributes, forwardRef } from 'react';
import './BaseTable.css';

interface TableCellProps extends HTMLAttributes<HTMLTableCellElement> {}
interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {}
interface TableHeadProps extends HTMLAttributes<HTMLTableSectionElement> {}
interface TableBodyProps extends HTMLAttributes<HTMLTableSectionElement> {}
interface TableFootProps extends HTMLAttributes<HTMLTableSectionElement> {}

export function TableCell({
  children,
  className,
}: TableCellProps): JSX.Element {
  return <td className={`${className}`}>{children}</td>;
}
export function TableRow({ children, className }: TableRowProps): JSX.Element {
  return <tr className={className}>{children}</tr>;
}

export function TableHead({
  children,
  className,
  style,
}: TableHeadProps): JSX.Element {
  return (
    <thead className={className} style={style}>
      {children}
    </thead>
  );
}

export const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(
  function TableBody({ className, style, children }, ref) {
    return (
      <tbody ref={ref} className={className} style={style}>
        {children}
      </tbody>
    );
  },
);

export function TableFoot({
  children,
  className,
}: TableFootProps): JSX.Element {
  return <tfoot className={className}>{children}</tfoot>;
}

interface BaseTableProps extends HTMLAttributes<HTMLTableElement> {
  textAlign?: 'left' | 'center' | 'right';
}
function BaseTable({
  children,
  className,
  textAlign,
}: BaseTableProps): JSX.Element {
  return (
    <table className={`table tablesorter ${className} text-${textAlign}`}>
      {children}
    </table>
  );
}

TableBody.defaultProps = {
  ref: null,
};

BaseTable.defaultProps = {
  textAlign: 'center',
};

export default BaseTable;
