export const handleEndScroll = async (
  ref: React.RefObject<HTMLElement>,
  callBack: () => void,
): Promise<void> => {
  if (!ref.current) return;
  const { scrollTop, scrollHeight, clientHeight } = ref.current;
  if (scrollTop + clientHeight >= scrollHeight) {
    callBack();
  }
};
