import React, { InputHTMLAttributes } from 'react';
import './RadioInput.css';
import { RadioGroup, Radio, FormControlLabel, FormLabel } from '@mui/material';

interface OptionElement extends HTMLSelectElement {
  tagName: 'option';
}
type SelectOption = React.ReactElement<OptionElement>;

interface RadioInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: 'small' | 'medium';
  children: SelectOption | SelectOption[];
  row?: boolean;
  label?: string;
}

function RadioInput({
  label,
  name,
  children,
  value,
  row,
  size,
  onChange,
}: RadioInputProps): JSX.Element {
  const inputId = `input-${label?.toLowerCase() || name}`;
  return (
    <div className="input-container">
      {label ? <FormLabel id={inputId}>{label}</FormLabel> : null}
      <RadioGroup row={row} name={name} value={value} onChange={onChange}>
        {React.Children.map(children, child => (
          <FormControlLabel
            value={child.props.value}
            control={<Radio size={size} />}
            label={child}
          />
        ))}
      </RadioGroup>
    </div>
  );
}

RadioInput.defaultProps = {
  row: false,
  label: '',
  size: 'medium',
};

export default RadioInput;
