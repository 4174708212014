import { AxiosError, isAxiosError } from 'axios';

export interface ErrorObject {
  [key: string]: string;
  field: string;
  message: string;
}

export const getCatchedErrors = (error: unknown): ErrorObject[] => {
  const errors = error as Error | AxiosError;
  if (isAxiosError(errors) && errors.response?.data.errors) {
    return errors.response?.data.errors;
  }
  const err = { field: 'unknown', message: '' } as ErrorObject;
  return [err];
};

export const base64ToBlob = (base64: string, type: string): Blob => {
  const binaryString = atob(base64);
  const binaryLen = binaryString.length;

  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i += 1) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return new Blob([bytes], { type });
};
